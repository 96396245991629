import { useCallback, useEffect } from 'react';

import { companyApi } from '@/store/company';
import { datastoreApi } from '@/store/datastore';
import { portfolioApi } from '@/store/portfolio';
import { useAppDispatch } from '@/store/hooks';

import { getCookie, removeCookies, setCookie } from '@/shared/tools';
import { VIEW_ALL_COMPANIES_ID } from '@/shared/constants';


const prev = {
    currentAccountUuid: null,
    currentCompanyUuid: null,
    currentUserUuid: null,
};

export const useCurrentIDsGuard = (
      loggedIn: boolean,
      currentAccountUuid: string,
      currentCompanyUuid: string,
      currentUserUuid: string
) =>
{
    const dispatch = useAppDispatch();
    const isInitialLoad = sessionStorage.getItem( 'hasLoadedOnce' ) !== 'true'; // true if not yet marked as loaded

    const invalidateCurrentCompanyData = useCallback( () =>
    {
        dispatch( companyApi.util.invalidateTags( [
            'ConvertibleLoanNote', 'ConvertibleLoanNotes', 'FilingCompanyRegisteredAddress',
            'FilingsCompanyRegisteredAddress', 'FilingCompanyRegisteredName', 'FilingsCompanyRegisteredName',
            'Filing', 'Filings', 'Fundamentals', 'OptionIssue', 'OptionIssues', 'OptionRound',
            'OptionRounds', 'ShareClass', 'ShareClasses', 'ShareClassShareHistory', 'ShareClassesShareHistory',
            'ShareClassRedesignation', 'ShareClassRedesignations', 'ShareDilution', 'ShareDilutions',
            'ShareIssue', 'ShareIssues', 'ShareRound', 'ShareRounds', 'ShareTransfer',
            'ShareTransfers', 'Shareholding', 'Shareholdings', 'ShareholdingsSummary',
            'Stakeholder', 'Stakeholders', 'Transaction', 'Transactions'
        ] ) );

        dispatch( datastoreApi.util.invalidateTags( [
            'DatastoreEntity', 'DatastoreEntities'
        ] ) );

        dispatch( portfolioApi.util.invalidateTags( [ 'PortfolioEntity' ] ) );
    }, [ dispatch ] );

    const invalidateAllCompaniesData = useCallback( () =>
    {
        dispatch( companyApi.util.invalidateTags( [ 'Companies', 'CompaniesMinimal' ] ) );
        dispatch( portfolioApi.util.invalidateTags( [ 'PortfolioEntity' ] ) );
    }, [ dispatch ] );

    const setOrRemoveCookie = useCallback( ( cookieName: string, newValue: string ) =>
    {
        const cookieValue = getCookie( { cName: cookieName } );
        if ( !loggedIn && cookieValue )
        {
            removeCookies( cookieName );
            prev[ cookieName ] = null;
        } else if ( newValue && newValue !== prev[ cookieName ] )
        {
            setCookie( { cName: cookieName, cValue: newValue } );
            prev[ cookieName ] = newValue;

            // Only invalidate after initial load
            if ( !isInitialLoad )
            {
                if ( cookieName === 'orCurrentCompanyUuid' && newValue !== VIEW_ALL_COMPANIES_ID )
                {
                    invalidateCurrentCompanyData();
                }
                if ( cookieName === 'orCurrentAccountUuid' || cookieName === 'orCurrentUserUuid' )
                {
                    invalidateAllCompaniesData();
                }
            }
        }
    }, [ loggedIn, invalidateCurrentCompanyData, invalidateAllCompaniesData, isInitialLoad ] );

    useEffect( () =>
    {
        setOrRemoveCookie( 'orCurrentAccountUuid', currentAccountUuid );
        setOrRemoveCookie( 'orCurrentCompanyUuid', currentCompanyUuid );
        setOrRemoveCookie( 'orCurrentUserUuid', currentUserUuid );

        // Mark initial load as complete in sessionStorage
        if ( isInitialLoad )
        {
            sessionStorage.setItem( 'hasLoadedOnce', 'true' );
        }

        return () =>
        {
            sessionStorage.removeItem( 'hasLoadedOnce' );
        };
    }, [ currentAccountUuid, currentCompanyUuid, currentUserUuid, setOrRemoveCookie, isInitialLoad ] );
};
