import moment from 'moment';
import 'moment/locale/en-gb';
import { configResponsive } from 'ahooks/lib/useResponsive';
import { Loader, Spin } from '@/components/structural';
import type { ORJSON } from '@/shared/models';
import twConfig from '@/shared/tailwindConfig';
moment.locale('en-gb');
export default function configureLibs() {
  const responsiveMap: ORJSON<number> = {};
  Object.entries(twConfig.theme.screens).forEach(([key, value]: [string, string]) => {
    responsiveMap[key] = parseInt(value, 10);
  });
  configResponsive(responsiveMap);
  Spin.setDefaultIndicator(<Loader />);
}