import { type FC } from 'react';
interface IProgressBarsProps {
  totalSteps: number;
  currentStep: number;
  activeColour?: string;
  inactiveColour?: string;
  className?: string;
  barClassName?: string;
}
export const ProgressBars: FC<IProgressBarsProps> = ({
  totalSteps,
  currentStep,
  activeColour = 'bg-success dark:bg-success-dark',
  inactiveColour = 'bg-default dark:bg-default-dark',
  barClassName,
  className
}) => {
  return <div className={`flex items-center justify-center w-full space-x-2 ${className}`} data-sentry-component="ProgressBars" data-sentry-source-file="ProgressBars.tsx">
        {Array.from({
      length: totalSteps
    }, (_, stepNumber) => {
      const isActive = stepNumber <= currentStep;
      return <div key={stepNumber} className={`flex-1 h-4 ${isActive ? activeColour : inactiveColour} rounded-full ${barClassName}`} />;
    })}
    </div>;
};