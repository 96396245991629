import { memo, type ReactNode, useEffect, useRef, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { Button, type IORButtonProps } from '@/components/structural';
import { ConfirmAction } from '@/components/structural/ConfirmAction';
import { FieldContainerMultipleFields } from '@/components/structural/form/FieldContainerMultipleFields';
import { type FormAction, FormItem } from '@/components/structural/form';
import { FORM_FOOTER_ANIMATION_TIME } from '@/shared/constants';
export interface IFormFooterProps {
  isLoading?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  onCreate?: () => void;
  onNext?: () => void;
  onUpdate?: () => void;
  onCreateAndNext?: () => void;
  action?: FormAction;
  disableButtons?: boolean;
  skipDeleteConfirmation?: boolean;
  className?: string;
  createText?: ReactNode;
  createdText?: ReactNode;
  createNextText?: ReactNode;
  createdNextText?: ReactNode;
  nextText?: ReactNode;
  nextedText?: ReactNode;
  updateText?: ReactNode;
  updatedText?: ReactNode;
  cancelText?: ReactNode;
  closeText?: ReactNode;
  deleteText?: ReactNode;
  deletedText?: ReactNode;
  createProps?: IORButtonProps;
  createNextProps?: IORButtonProps;
  nextProps?: IORButtonProps;
  updateProps?: IORButtonProps;
  deleteProps?: IORButtonProps;
  cancelProps?: IORButtonProps;
  actionStatus?: QueryStatus[];
  onButtonAnimationComplete?: () => void;
  disableAnimation?: boolean;
}
export const FormFooter = memo(({
  isLoading = false,
  onCancel = null,
  onDelete = null,
  onUpdate = null,
  onCreate = null,
  onNext = null,
  onCreateAndNext = null,
  action = 'create',
  disableButtons = false,
  skipDeleteConfirmation = false,
  className = '',
  createText = 'SAVE',
  createdText = 'SAVED',
  createNextText = 'ADD NEXT',
  createdNextText = 'ADDED',
  nextText = 'NEXT',
  nextedText = 'NEXT',
  updateText = 'UPDATE',
  updatedText = 'UPDATED',
  cancelText = 'CANCEL',
  closeText = 'CLOSE',
  deleteText = 'DELETE',
  deletedText = 'DELETED',
  createProps = {},
  createNextProps = {},
  nextProps = {},
  updateProps = {},
  deleteProps = {},
  cancelProps = {},
  actionStatus = [],
  onButtonAnimationComplete = null,
  disableAnimation = false
}: IFormFooterProps) => {
  const [animationComplete, setAnimationComplete] = useState<boolean>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  useUpdateEffect(() => {
    if (actionStatus.includes(QueryStatus.fulfilled)) {
      setAnimationComplete(false);
      timeoutRef.current = setTimeout(() => {
        setAnimationComplete(true);
        timeoutRef.current = null;
      }, disableAnimation ? 0 : FORM_FOOTER_ANIMATION_TIME);
    } else if (actionStatus.includes(QueryStatus.rejected)) {
      setAnimationComplete(null);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    return () => clearTimeout(timeoutRef.current);
  }, [actionStatus]);
  useUpdateEffect(() => {
    if (animationComplete === true) {
      setAnimationComplete(null);
      timeoutRef.current = null;
      onButtonAnimationComplete && onButtonAnimationComplete();
    }
  }, [animationComplete]);
  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  }, []);
  return <FormItem wrapperCol={{
    span: 24
  }} className="form-footer">
        <FieldContainerMultipleFields className={className + ' bg-white dark:bg-transparent'} $cols={['view', 'readonly'].includes(action) ? 1 // cancel
    : action === 'create' ? onCreateAndNext ? 3 // cancel, next, create
    : 2 // cancel, create
    : action === 'delete' ? onDelete ? 2 // cancel, delete
    : 1 // cancel
    :
    // update action
    onDelete && onUpdate ? 3 // cancel, delete, update
    : 2 // cancel, delete
    }>
            {onCancel && <Button type="default" htmlType="button" className="w-full h-12" onClick={onCancel} loading={isLoading} {...cancelProps}>
                {['view', 'readonly'].includes(action) ? closeText : cancelText}
            </Button>}
            {action === 'create' && <>
                {onCreateAndNext && <Button type="info" htmlType="button" className="w-full h-12" disabled={disableButtons} onClick={timeoutRef.current === null ? onCreateAndNext : null} loading={isLoading} {...createNextProps}>
                    {animationComplete === false ? createdNextText : createNextText}
                </Button>}
                {onCreate && <Button type="success" htmlType="button" className="w-full h-12" disabled={disableButtons} onClick={timeoutRef.current === null ? onCreate : null} loading={isLoading} {...createProps}>
                    {animationComplete === false ? createdText : createText}
                </Button>}
                {onNext && <Button type="success" htmlType="button" className="w-full h-12" disabled={disableButtons} onClick={timeoutRef.current === null ? onNext : null} loading={isLoading} {...nextProps}>
                    {animationComplete === false ? nextedText : nextText}
                </Button>}
            </>}
            {['delete', 'update'].includes(action) && <>
                {onDelete && !skipDeleteConfirmation && <ConfirmAction onOk={timeoutRef.current === null ? onDelete : null}>
                    <Button type="danger" htmlType="button" className="w-full h-12" loading={isLoading} {...deleteProps}>
                        {animationComplete === false ? deletedText : deleteText}
                    </Button>
                </ConfirmAction>}
                {onDelete && skipDeleteConfirmation && <Button type="danger" htmlType="button" className="w-full h-12" loading={isLoading} onClick={timeoutRef.current === null ? onDelete : null} {...deleteProps}>
                          {animationComplete === false ? deletedText : deleteText}
                      </Button>}
            </>}
            {action === 'update' && <>
                {onUpdate && <Button type="success" onClick={timeoutRef.current === null ? onUpdate : null} className="w-full h-12" disabled={disableButtons} loading={isLoading} {...updateProps}>
                    {animationComplete === false ? updatedText : updateText}
                </Button>}
            </>}
        </FieldContainerMultipleFields>
    </FormItem>;
});
FormFooter.displayName = 'FormFooter';