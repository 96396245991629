import { type FC, memo } from 'react';
import { Image } from '@/components/structural/images';
export interface IUploadDropZoneProps {
  className?: string;
  textClassName?: string;
}
export const UploadDropZone: FC<IUploadDropZoneProps> = memo(({
  className,
  textClassName
}) => {
  return <>
        <div className={`absolute h-full w-full place-items-center z-10 bg-white dark:bg-black rounded-lg ${className}`}>
            <div className="grid place-items-center h-full border border-success dark:border-success-dark rounded-xl">
                <div className="flex flex-col place-items-center">
                    <Image src="/images/cloud-arrow-up-custom.png" height={50} width={75} alt="Cloud Upload Decorative Image" />
                    <div className={`text-default dark:text-default-dark text-sm ${textClassName}`}>DROP FILES HERE</div>
                </div>
            </div>
        </div>
    </>;
});
UploadDropZone.displayName = 'UploadDropZone';