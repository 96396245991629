import { type FC, memo } from 'react';
import type { IFormStepProps } from '@/components/structural/form';
export const StepEnd: FC<IFormStepProps> = memo(() => {
  return <>
        <div className="grid grid-cols-1 place-items-center w-full mb-4">
            <div className="mb-4">
                Your request to change your registered company name has now been Submitted to Companies House.
            </div>
            <div>
                A certificate of incorporation on change of name will be issued by the Registrar. The change of name has
                legal effect from the date of the Certificate and not the date of the resolution.
            </div>
        </div>
    </>;
});
StepEnd.displayName = 'CompanyChangeRegisteredNameStepEnd';