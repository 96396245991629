import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { processError } from '@/shared/processError';
import { providesIds, providesList } from '@/store/_rtk-query';

import { datastoreApiKey } from './service.key';
import { DATASTORE_API_URL } from './api-url';

import type {
    DatastoreItemType,
    IDatastoreCreateFileAttributes,
    IDatastoreCreateFolderAttributes,
    IDatastoreFile,
    IDatastorePatchEntityAttributes
} from '@/features/datastore/models/datastore.django.interface';
import { DATASTORE_SPECIAL_CONTENT_FILE } from '@/features/datastore/models/datastore.django.interface';
import { datastoreFileCreateSuccess, datastoreFileFetchSuccess, datastoreFolderCreateSuccess, datastoreFolderFetchSuccess } from '@/store/datastore/actions';
import { mapDatastoreResponse } from '@/features/datastore/tools/mapDatastoreResponse';

// import { DEFAULT_PER_PAGE } from '@/shared/constants';
import { getCookie } from '@/shared/tools';

import type { IORDatastoreSearchOptions, ORDatastoreOwner } from '@/features/datastore/models';


export interface IORDatastoreRequestOptions
{
    uuid?: string,
    path?: string,
    owner: ORDatastoreOwner,
    ownerId?: string,
}

export const datastoreApi = createApi( {
    reducerPath: datastoreApiKey,
    tagTypes: [
        'DatastoreEntity',
        'DatastoreEntities',
    ],
    baseQuery: baseQuery( {
        baseUrl: '/company', options: {}
    } ),
    refetchOnFocus: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_WINDOW_FOCUS ),
    refetchOnReconnect: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_RECONNECT ),
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getOneDatastoreEntity: builder.query<DatastoreItemType, IORDatastoreRequestOptions & { params?: IORDatastoreSearchOptions }>( {
            query: ( {
                owner = 'company',
                ownerId = getCookie( { cName: 'orCurrentCompanyUuid' } ),
                path = '/'
            } ) => ( {
                url: `${ DATASTORE_API_URL[ owner ]( ownerId ) }${ path }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'DatastoreEntity' )
            ),
            transformResponse: mapDatastoreResponse,
            onQueryStarted: async ( arg, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    if ( data.root_code_type === DATASTORE_SPECIAL_CONTENT_FILE )
                    {
                        api.dispatch( datastoreFileFetchSuccess(
                              data as IDatastoreFile
                        ) );
                    } else
                    {
                        api.dispatch( datastoreFolderFetchSuccess(
                              data
                        ) );
                    }
                } catch ( error )
                {
                    processError( 'datastoreApi Service', error );
                }
            }
        } ),
        patchDatastoreEntity: builder.mutation<DatastoreItemType, IORDatastoreRequestOptions & { data: IDatastorePatchEntityAttributes }>( {
            query: ( {
                owner = 'company',
                ownerId = getCookie( { cName: 'orCurrentCompanyUuid' } ),
                path = '/',
                data,
            } ) => ( {
                url: `${ DATASTORE_API_URL[ owner ]( ownerId ) }${ path }`,
                method: 'patch',
                data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesIds( [ uuid ], 'DatastoreEntity' )
            )
        } ),
        postDatastoreEntity: builder.mutation<DatastoreItemType, IORDatastoreRequestOptions & { data: Partial<IDatastoreCreateFileAttributes | IDatastoreCreateFolderAttributes> }>( {
            query: ( {
                owner = 'company',
                ownerId = getCookie( { cName: 'orCurrentCompanyUuid' } ),
                path = '/',
                data
            } ) =>
            {
                let contentType = 'application/json';
                let dataToSend: typeof data | FormData = data;

                if ( 'file_name' in data )
                {
                    contentType = 'multipart/form-data';
                    dataToSend = new FormData();
                    Object.entries( data ).forEach( ( [ key, value ] ) =>
                    {
                        ( dataToSend as FormData ).append( key, value );
                    } );
                }

                return ( {
                    url: `${ DATASTORE_API_URL[ owner ]( ownerId ) }${ path }`, method: 'post', data: dataToSend,
                    extraHeaders: {
                        'Content-Type': contentType
                    }
                } );
            },
            invalidatesTags: ( { uuid } ) => (
                  providesList( [ uuid ], 'DatastoreEntities', 'DatastoreEntity' )
            ),
            onQueryStarted: async ( arg, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    if ( data.root_code_type === DATASTORE_SPECIAL_CONTENT_FILE )
                    {
                        api.dispatch( datastoreFileCreateSuccess(
                              data as IDatastoreFile
                        ) );
                    } else
                    {
                        api.dispatch( datastoreFolderCreateSuccess(
                              data
                        ) );
                    }
                } catch ( error )
                {
                    processError( 'datastoreApi Service', error );
                }
            }
        } ),
        restoreDatastoreEntity: builder.mutation<DatastoreItemType, IORDatastoreRequestOptions>( {
            query: ( {
                owner = 'company',
                ownerId = getCookie( { cName: 'orCurrentCompanyUuid' } ),
                path = '/'
            } ) => ( {
                url: `${ DATASTORE_API_URL[ owner ]( ownerId ) }${ path }/restore`, method: 'post', data: {}
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesList( [ uuid ], 'DatastoreEntities', 'DatastoreEntity' )
            ),
        } ),
        deleteDatastoreEntity: builder.mutation<void, IORDatastoreRequestOptions>( {
            query: ( {
                owner = 'company',
                ownerId = getCookie( { cName: 'orCurrentCompanyUuid' } ),
                path = '/'
            } ) => ( {
                url: `${ DATASTORE_API_URL[ owner ]( ownerId ) }${ path }`, method: 'delete', data: {}
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesList( [ uuid ], 'DatastoreEntities', 'DatastoreEntity' )
            ),
        } ),
    } ),
} );

export const datastoreApiReducer = datastoreApi.reducer;
// export const datastoreApiReducer = configureReduxPersist<ReturnType<typeof datastoreApi.reducer>>( datastoreApi.reducerPath, datastoreApi.reducer );

export const {
    useGetOneDatastoreEntityQuery,
    useLazyGetOneDatastoreEntityQuery,
    usePatchDatastoreEntityMutation,
    usePostDatastoreEntityMutation,
    useDeleteDatastoreEntityMutation,
    useRestoreDatastoreEntityMutation,
    usePrefetch,
    endpoints: datastoreApiEndpoints,
} = datastoreApi;
