import { type FC, useState } from 'react';
import RateAntD, { RateProps as RatePropsAntd } from 'antd/lib/rate';
import { Button, Flex, type IORButtonProps, Typography } from '@/components/structural';
const getCustomIcon = (label: number, selectedValue: number) => {
  const buttonProps: Partial<IORButtonProps> = {
    className: `h-12 w-12 ${!selectedValue || label === selectedValue ? 'hover:!bg-success dark:hover:!bg-success-dark hover:!text-white dark:hover:!text-default-dark ' + 'hover:!border-success dark:hover:!border-success-dark' : ''}`,
    type: !selectedValue ? 'default' : label === selectedValue ? 'success' : 'default'
  };
  return <Button {...buttonProps} data-sentry-element="Button" data-sentry-component="getCustomIcon" data-sentry-source-file="Rate.tsx">{label}</Button>;
};
export type ORRateProps = RatePropsAntd & {
  leftSideMessage?: string;
  rightSideMessage?: string;
};
export const Rate: FC<ORRateProps> = props => {
  const {
    leftSideMessage = 'Extremely Dissatisfied',
    rightSideMessage = 'Extremely Satisfied',
    className = `${props.className} w-full flex justify-between`,
    ...rest
  } = props;
  const [valueSelected, setValueSelected] = useState<number>(null);
  return <>
        <RateAntD {...rest} className={className} character={({
      index = 0
    }) => getCustomIcon(index + 1, valueSelected)} onChange={newValue => !valueSelected && setValueSelected(newValue)} data-sentry-element="RateAntD" data-sentry-source-file="Rate.tsx" />
        <Flex justify="space-between" className="mt-2" data-sentry-element="Flex" data-sentry-source-file="Rate.tsx">
            <Typography.Paragraph className="text-sm" data-sentry-element="unknown" data-sentry-source-file="Rate.tsx">{leftSideMessage}</Typography.Paragraph>
            <Typography.Paragraph className="text-sm" data-sentry-element="unknown" data-sentry-source-file="Rate.tsx">{rightSideMessage}</Typography.Paragraph>
        </Flex>
    </>;
};
Rate.displayName = 'Rate';