import { type FC, memo } from 'react';
import { Image, SVGIcon } from '@/components/structural/images';
import downloadIcon from '@/icons/download.svg';
import uploadIcon from '@/icons/upload.svg';
export interface IEmptyTableProps {
  showUploadMessage?: boolean;
  hideActionText?: boolean;
  hideDescriptionText?: boolean;
}
export const EmptyTable: FC<IEmptyTableProps> = memo(props => {
  if (props.showUploadMessage) {
    return <>
            <div className="flex flex-col place-items-center">
                <Image src="/images/cloud-arrow-up-custom.png" height={50} width={75} alt="Cloud Upload Decorative Image" />
                {!props.hideActionText && <div className="text-default-light dark:text-default-light-dark text-lg">DRAG & DROP</div>}
                {!props.hideDescriptionText && <div className="text-default dark:text-default-dark text-2xs">
                          Download the template file using the&nbsp;
                          <SVGIcon src={downloadIcon.src} className="translate-y-[0.15rem]" />
                          &nbsp;button, then drag & drop your file here or use the&nbsp;
                          <SVGIcon src={uploadIcon.src} className="translate-y-[0.15rem]" />
                          &nbsp;button above
                      </div>}
            </div>
        </>;
  }
  return <>
        <div className="flex flex-col place-items-center">
            <div className="text-default-light dark:text-default-light-dark text-lg">No results</div>
        </div>
    </>;
});
EmptyTable.displayName = 'EmptyTable';