import useMount from 'ahooks/lib/useMount';
import { type FC, memo, useCallback, useState } from 'react';
import { Checkbox, CheckboxGroup, Form, FormItem, type IFormStepProps, useForm } from '@/components/structural/form';
import { Col, Row } from '@/components/structural';
import { CHSailAddressRecordsAndLocations, type CHSailAddressRecordsAndLocationsType } from '@/features/company/shared/models/ch/ch-sail-address-records-and-locations.type';
import type { ORJSON } from '@/shared/models';
export interface IStep2Data extends ORJSON<string[]> {
  records_and_locations: CHSailAddressRecordsAndLocationsType[];
}
export const Step2: FC<IFormStepProps<IStep2Data>> = memo(({
  initialData,
  setData,
  isStepValid,
  setIsValid
}) => {
  const [formStep] = useForm();
  const [defaults] = useState<IStep2Data>(initialData || {
    records_and_locations: []
  });
  useMount(() => {
    formStep.setFieldsValue(defaults);
    isStepValid(formStep).then(setIsValid);
  });
  const updateFormData = useCallback(() => {
    setData(formStep.getFieldsValue());
    isStepValid(formStep).then(setIsValid);
  }, [setData, formStep, setIsValid, isStepValid]);
  return <>
        <Form form={formStep} className="font-roboto" layout="vertical" requiredMark={false} onValuesChange={updateFormData}>
            <FormItem label="Records and Locations" name="records_and_locations" rules={[{
        required: true,
        message: <></>,
        type: 'array',
        enum: [...CHSailAddressRecordsAndLocations],
        min: 1
      }]}>
                <CheckboxGroup>
                    <div className="mb-4">
                        Please confirm which records will be kept at and available for inspection at the SAIL:
                    </div>
                    <Row>
                        {CHSailAddressRecordsAndLocations.map(item => <>
                            <Col span={24} key={item}>
                                <Checkbox value={item}>
                                    <div className="mt-1 font-roboto">{item}</div>
                                </Checkbox>
                            </Col>
                        </>)}
                    </Row>
                </CheckboxGroup>
            </FormItem>
        </Form>
    </>;
});
Step2.displayName = 'CompanyAddSAILAddressStep2';