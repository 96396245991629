import { type FC, memo, useEffect, useMemo, useState } from 'react';
import useResponsive from 'ahooks/lib/useResponsive';
import { useRouter } from 'next/router';
import tw from 'tailwind-styled-components';
import { selectAppViewState } from '@/store/internal/selectors';
import { useAppSelector } from '@/store/hooks';
import { useCurrentUser } from '@/store/user/hooks';
import { Drawer, Layout, Link, Menu, type MenuItemType as ItemType } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';
import { GlobalBellNotifications } from '@/features/notifications';
import { CompanySelectorDropdown, Hamburger, SearchBox } from './components';
import { useMenu } from '@/features/menu';
import logoImage from '@/images/logo-OR-no-text.svg';
export interface IAppHeaderAuthenticatedProps {
  hideTopViewToggle?: boolean;
  className?: string;
}
export const AppHeaderAuthenticated: FC<IAppHeaderAuthenticatedProps> = memo(props => {
  const router = useRouter();
  const {
    settings,
    userType
  } = useCurrentUser();
  const appViewState = useAppSelector(selectAppViewState);
  const breakpoints = useResponsive();
  const {
    portfolioMenu,
    portfolioDocumentsMenu,
    myCompanyMenu,
    shareCapitalMenu,
    documentsMenu,
    stakeholdersMenu,
    userMenu
  } = useMenu();
  const [showMenu, setShowMenu] = useState(false);
  const topLeft = <div className="flex items-center justify-start min-w-max">
        <ORLogoWrapper>
            <Link to="/my-company" className="leading-6">
                <ORLogo {...logoImage} alt="logo" $animate={false} />
            </Link>
        </ORLogoWrapper>
        {['entity-management'].includes(appViewState) && <CompanySelectorDropdown className="w-40 3xs:w-56 sm:w-72 md:w-56" />}
    </div>;
  const activeRoute = useMemo(() => {
    let pathname = router.pathname;
    const idRouteIndexInPathname = pathname.indexOf('/[id]');
    if (idRouteIndexInPathname > -1) pathname = pathname.slice(0, idRouteIndexInPathname);
    return pathname;
  }, [router.pathname]);
  useEffect(() => {
    setShowMenu(false);
  }, [activeRoute]);
  useEffect(() => {
    setShowMenu(isVisible => isVisible ? false : isVisible);
  }, [breakpoints.md]);
  const companyItems = [...myCompanyMenu, ...shareCapitalMenu, ...documentsMenu, ...stakeholdersMenu];
  const investorItems = [...portfolioMenu, ...portfolioDocumentsMenu];
  const combinedItems = [...companyItems, ...investorItems];
  let desktopMainMenuItems: ItemType[] = companyItems;
  if (userType === 'company') {
    desktopMainMenuItems = companyItems;
  }
  if (userType === 'investor') {
    desktopMainMenuItems = investorItems;
  }
  if (userType === 'investor-company') {
    desktopMainMenuItems = combinedItems;
    if (appViewState === 'entity-management') {
      desktopMainMenuItems = companyItems;
    }
    if (appViewState === 'portfolio-management') {
      desktopMainMenuItems = investorItems;
    }
  }
  const desktopUserMenuItems = [...userMenu];
  const mobileMenuItems = [...desktopMainMenuItems, ...userMenu];
  const antdMenuDropdownWidthHack = (openKeys: string[]) => {
    if (!openKeys.length) return;
    const bodyChildren = document.body.children;
    for (let i = 0; i < bodyChildren.length; i++) {
      const child = bodyChildren.item(i);
      if (child.tagName !== 'DIV') continue;
      const divChild = child as HTMLDivElement;
      if (divChild.style.width === '100%') divChild.style.width = 'initial';
    }
  };

  // if at least this width
  if (breakpoints.md) {
    return <>
            <CustomHeader className={`${userType === 'investor-company' && !props.hideTopViewToggle ? 'mt-10' : ''} ${props.className}`}>
                <LeftSideContainer>
                    {topLeft}
                    <Menu className="flex border-0 flex-grow ml-[0.715rem] font-normal text-md" mode="horizontal"
          // overflowedIndicator={false}
          disabledOverflow={true} selectedKeys={[activeRoute]} triggerSubMenuAction="click" theme={settings?.dark_mode ? 'dark' : 'light'} items={desktopMainMenuItems} />
                </LeftSideContainer>
                <RightSideContainer>
                    {appViewState === 'entity-management' && <SearchBox className="w-56" />}
                    <GlobalBellNotifications />
                    <Menu className="border-0 font-normal h-full flex text-md items-center mb-[1px]" mode="horizontal" selectedKeys={[activeRoute]} disabledOverflow triggerSubMenuAction="click" theme={settings?.dark_mode ? 'dark' : 'light'} items={desktopUserMenuItems} onOpenChange={antdMenuDropdownWidthHack} />
                    {/*<DarkModeToggle size="1rem" isDarkMode={ settings?.dark_mode }/>*/}
                </RightSideContainer>
            </CustomHeader>
        </>;
  }

  // mobile view
  return <>
        <CustomHeader className={userType === 'investor-company' ? 'mt-10' : ''}>
            <div className="flex justify-between flex-grow">
                {topLeft}
                <div className="flex place-items-center">
                    {appViewState === 'entity-management' && <div className="pr-4 hidden xs:inline-block w-56 sm:w-72">
                        <SearchBox />
                    </div>}
                    <div className="pr-4 h-10">
                        <GlobalBellNotifications />
                    </div>
                    <Hamburger onClick={() => setShowMenu(true)} />
                </div>
            </div>
            <Drawer styles={{
        body: {
          padding: 0
        }
      }} width="22rem" placement="right" closeIcon={false} onClose={() => setShowMenu(false)} open={showMenu}>
                <div className="m-4 flex gap-4 justify-end" key="unique3">
                    <ORLogoWrapper>
                        <Link to="/my-company" className="leading-6">
                            <ORLogo className={`${settings?.dark_mode ? 'text-primary-dark' : null}`} {...logoImage} alt="logo" $animate={false} />
                        </Link>
                    </ORLogoWrapper>
                    {appViewState === 'entity-management' && <SearchBox className="block xs:!hidden" />}
                    {/*<DarkModeToggle size="1.75rem" className="self-center" isDarkMode={ settings?.dark_mode }/>*/}
                </div>
                <Menu className="mt-4 font-normal" mode="inline" inlineCollapsed={false} selectedKeys={[activeRoute]} disabledOverflow theme={settings?.dark_mode ? 'dark' : 'light'} triggerSubMenuAction="click" items={mobileMenuItems} />
            </Drawer>
        </CustomHeader>
    </>;
});
AppHeaderAuthenticated.displayName = 'AppHeaderAuthenticated';
const {
  Header
} = Layout;
const CustomHeader = tw(Header)`
    fixed
    h-14
    z-20
    w-screen
    px-10
    overflow-hidden
    
    flex
    justify-between
    shadow-sm
    
    bg-white
`;
const LeftSideContainer = tw.div`
    flex
    flex-grow
    items-center
`;
const RightSideContainer = tw.div`
    justify-self-end
    flex
    place-items-center
    overflow-hidden
`;
const ORLogoWrapper = tw.div`
    grid
    items-center
    mr-4
`;
type ORLogoProps = {
  $animate: boolean;
};
const ORLogo = tw(SVGIcon)<ORLogoProps>`
    !h-10
    !w-10
    text-primary
        dark:text-primary-dark
    
    ${(props: ORLogoProps) => props.$animate && 'animate-spin'}
`;