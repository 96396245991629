import { type FC, memo, useCallback, useState } from 'react';
import useMount from 'ahooks/lib/useMount';
import { Checkbox, Form, FormItem, type IFormStepProps, useForm } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';
export interface IStep1Data extends ORJSON<boolean> {
  same_country: boolean;
}
export const Step1: FC<IFormStepProps<IStep1Data>> = memo(({
  initialData,
  isStepValid,
  setData,
  setIsValid
}) => {
  const [formStep] = useForm();
  const [defaults] = useState<IStep1Data>(initialData || {
    same_country: false
  });
  const updateFormData = useCallback(() => {
    setData(formStep.getFieldsValue());
    isStepValid(formStep).then(setIsValid);
  }, [setData, formStep, setIsValid, isStepValid]);
  useMount(() => {
    formStep.setFieldsValue(defaults);
    isStepValid(formStep).then(setIsValid);
  });
  return <>
        <Form form={formStep} className="font-roboto" layout="vertical" requiredMark={false} onValuesChange={updateFormData}>
            <div className="my-4">
                The new registered office address must be in the same part of the United Kingdom that the company is registered in.
            </div>
            <div className="mb-4">
                For England and Wales companies, the address provided can either be in England or Wales.
            </div>
            <div className="mb-4">
                For Welsh companies, the address provided must be in Wales.
            </div>
            <div className="mb-4">
                For companies registered in Scotland or Northern Ireland, the address provided must be in Scotland or Northern Ireland respectively.
            </div>

            <FormItem name="same_country" valuePropName="checked" rules={[{
        required: true,
        message: <></>,
        type: 'enum',
        enum: [true]
      }]}>
                <Checkbox>
                    <div className="ml-4 font-roboto">
                        I/we confirm that the registered office is in the location where the company was registered e.g. England and Wales, Wales, Scotland, Northern Ireland.
                    </div>
                </Checkbox>
            </FormItem>
        </Form>
    </>;
});
Step1.displayName = 'CompanyChangeRegisteredAddressStep1';