import { type FC, memo, useCallback } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useCurrentCompany } from '@/store/company/hooks';
import { Loader, Typography, WidgetSimpleWrapper } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';
import { downloadFromBackend } from '@/shared/tools';
import widgetIcon from '@/icons/journals.svg';
export interface IStatutoryRegistersWidgetProps {
  className?: string;
}
export const StatutoryRegistersWidget: FC<IStatutoryRegistersWidgetProps> = memo(props => {
  const dispatch = useAppDispatch();
  const {
    orCompany,
    isFetching
  } = useCurrentCompany();
  const doDownload = useCallback(async () => {
    await downloadFromBackend({
      url: `/company/${orCompany.uuid}/statbooks`,
      name: `${orCompany.name}_StatBooks.xlsx`,
      dispatch
    });
  }, [orCompany]);
  if (isFetching) return <Loader skeleton />;
  return <>
        <WidgetSimpleWrapper className={`bg-success text-white text-center ${props.className}`} onClick={doDownload} variant="success">
            <div className="flex items-center justify-center w-full gap-8">
                <Typography.Text className="text-inherit text-3xl">Statutory Registers</Typography.Text>
                <SVGIcon src={widgetIcon.src} alt="Statutory Registers" className="w-24 h-24" />
            </div>
        </WidgetSimpleWrapper>
    </>;
});
StatutoryRegistersWidget.displayName = 'StatutoryRegistersWidget';