import { type FC, memo, type PropsWithChildren, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';
import { type ISorterState, onSortChange } from '@/components/tables/sorting-tools';
import { SearchBox } from '@/components/tables/search-generators';
import { SortingBox } from '@/components/tables/sorting-generators';
interface IActionBarProps {
  isLoading: boolean;
  sortByBoxClassName: string;
  sortByColumn: ISorterState;
  sortByOptions: ReactNode[];
  sortByKeys: string[];
  onSortChange?: typeof onSortChange;
  setSearchStr: (val: string) => void;
  initialSearchValue: string;
  extraLeftContent?: ReactNode | ReactNode[];
  placeExtraLeftContent?: 'start' | 'end';
}
export const ActionBar: FC<PropsWithChildren<IActionBarProps>> = memo(props => {
  if (process.env.NODE_ENV !== 'production' && props.extraLeftContent && !props.placeExtraLeftContent) {
    throw new Error('ActionBar: the "placeExtraLeftContent" prop is required if "extraLeftContent" prop is used.');
  }
  return <>
        <ActionBarContainer>
            <div className="flex flex-col 2xs:flex-row flex-wrap mr-4 mb-4 sm:mb-0">
                {props.placeExtraLeftContent === 'start' && props.extraLeftContent}
                {props.sortByKeys && <SortingBox className={`${props.sortByBoxClassName} mr-4`} onSortChange={value => {
          onSortChange(value, props.sortByColumn, props.sortByKeys);
          props.onSortChange && props.onSortChange(value, props.sortByColumn, props.sortByKeys);
        }} value={`${props.sortByColumn.visual.key}+${props.sortByColumn.visual.direction}`}>
                          {props.sortByOptions}
                      </SortingBox>}
                {props.setSearchStr && <SearchBox className={`mr-4 ${props.sortByKeys ? 'mt-4 xs:mt-0' : ''}`} initialSearchValue={props.initialSearchValue} updateSearchFilterFn={props.setSearchStr} isLoading={props.isLoading} />}
                {props.placeExtraLeftContent === 'end' && props.extraLeftContent}
            </div>
            <div className="flex">
                {props.children}
            </div>
        </ActionBarContainer>
    </>;
});
ActionBar.displayName = 'ActionBar';
const ActionBarContainer = tw.div`
    flex
    flex-col 
        sm:flex-row
    justify-between
    mb-4
    p-4
    bg-background
        dark:bg-background-dark
    rounded-xl
`;