import { type FC, memo, type PropsWithChildren, type ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import Typography from 'antd/lib/typography';
import { Button, Card } from '@/components/structural';
import { DataFormat, type IDataFormatProps } from './';
import { useCurrentUser } from '@/store/user/hooks';
import twConfig from '@/shared/tailwindConfig';
interface IWidgetSimpleWithActionProps {
  value?: IDataFormatProps['raw'] | ReactNode;
  type?: IDataFormatProps['type'];
  variant?: 'updated' | 'regular' | 'draft';
  draftText?: ReactNode;
  maxDecimals?: IDataFormatProps['maxDecimals'];
  title: string;
  actionTitle?: string;
  actionClassName?: string;
  onClick?: () => void;
  className?: string;
  updatedText?: ReactNode;
  centreContent?: boolean;
  disableContentFlex?: boolean;
}
export const WidgetSimpleWithAction: FC<PropsWithChildren<IWidgetSimpleWithActionProps>> = memo(props => {
  const {
    settings
  } = useCurrentUser();
  const isDarkMode = settings?.dark_mode || false;
  const {
    centreContent = true
  } = props;
  return <>
        <CardWrapperStyled $variant={props.variant || 'regular'} styles={{
      header: {
        color: isDarkMode ? twConfig.theme.colors.primary.dark : twConfig.theme.colors.primary.DEFAULT
      },
      body: {
        padding: 0,
        height: '100%'
      }
    }} className={props.className}>
            <div className="flex flex-col p-8 gap-4 h-full">
                <div className={`text-2xl text-primary dark:text-primary-dark self-start font-medium flex w-full ${centreContent ? 'items-center' : ''}`}>
                    <span className="flex-grow flex">
                        <Typography.Text ellipsis={{
              tooltip: props.title
            }} className="mt-1.5 inline-block flex-grow w-16 !text-inherit leading-8">
                            {props.title}
                        </Typography.Text>
                        {props.variant === 'updated' && props.children && <UpdatedCircle />}
                    </span>
                    {props.variant === 'draft' && <Button type="primary" className="justify-self-end w-28 text-xs">
                        {props.draftText || 'DRAFT'}
                    </Button>}
                </div>
                <div className={`${centreContent ? 'self-center' : ''} ${props.disableContentFlex ? '' : `flex-grow flex${props.children ? ' flex-col' : ''}`}`}>
                    {props.variant === 'updated' && !props.children && <UpdatedCircle />}
                    {props.children ? props.children : <DataFormat className={`text-3xl font-medium text-primary dark:text-primary-dark leading-10 ${centreContent ? 'text-center' : ''}`} raw={props.value} type={props.type} maxDecimals={props.maxDecimals !== undefined ? props.maxDecimals : 2} inline={props.variant === 'updated'} />}
                </div>
                {props.onClick && props.variant !== 'updated' && <div className={`${centreContent ? 'self-center' : ''} flex-grow flex items-end`}>
                    <Button type="success" onClick={props.onClick} className={props.actionClassName}>
                        {props.variant === 'draft' ? 'VIEW DRAFT' : props.actionTitle}
                    </Button></div>}
                {['draft', 'updated'].includes(props.variant) && <div className={`self-center text-info flex-grow flex items-end`}>
                    {props.updatedText}
                </div>}
            </div>
        </CardWrapperStyled>
    </>;
});
WidgetSimpleWithAction.displayName = 'WidgetSimpleWithAction';
const CardWrapperStyled = styled(Card)<{
  $variant: IWidgetSimpleWithActionProps['variant'];
}>`
    & {
        background-color: ${p => p.$variant === 'updated' ? twConfig.theme.colors.info.updated : p.$variant === 'draft' ? twConfig.theme.colors.primary['bg-draft'] : '#fff'};

        .field-title {
            color: ${twConfig.theme.colors.primary.DEFAULT};
            text-align: left;
        }
    }

    .dark & {
        background-color: ${p => p.$variant === 'updated' ? twConfig.theme.colors.info.dark : p.$variant === 'draft' ? twConfig.theme.colors.default.DEFAULT : twConfig.theme.colors.background.dark};

        .field-title {
            color: ${twConfig.theme.colors.primary.dark};
        }
    }
`;
const UpdatedCircle = tw.div`
    after:content-["\\25CF"]
    inline
    text-info
    dark:text-info
    self-center
    mx-1
    text-3xl
    -mt-1
`;