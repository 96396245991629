import InlineSVG from 'react-inlinesvg';
import { forwardRef, memo, type MouseEvent } from 'react';
import tw from 'tailwind-styled-components';
export interface ISVGIconProps {
  src: string;
  alt?: string;
  link?: boolean;
  className?: string;
  wrapperClassName?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  asSpan?: boolean;
}
export const SVGIcon = memo(forwardRef<HTMLDivElement, ISVGIconProps>((props: ISVGIconProps, ref) => {
  const Icon = <IconWrapper src={props.src} description={props.alt || 'Icon'} fill="currentColor" height={undefined} width={undefined} className={props.className} preProcessor={(code: string) => code.replace(/fill=".*?"/g, 'fill="currentColor"')} cacheRequests uniquifyIDs />;
  if (props.onClick || props.link) {
    if (props.asSpan) {
      return <span ref={ref} className={'hover:cursor-pointer inline-block ' + props.wrapperClassName} onClick={props.onClick}>
                {Icon}
            </span>;
    }
    return <div ref={ref} className={'hover:cursor-pointer inline-block ' + props.wrapperClassName} onClick={props.onClick}>
            {Icon}
        </div>;
  }
  return Icon;
}));
SVGIcon.displayName = 'SVGIcon';
const IconWrapper = tw(InlineSVG)`
    inline-block
    w-4
    h-4
`;

/**
 * Replace all images with a class name with SVGs so that they can be styled with CSS
 * @param className
 * @param cb
 */
export const replaceImgWithSVG = (className: string, cb?: () => void) => {
  document.querySelectorAll(`img.${className}`).forEach(function (img: HTMLImageElement) {
    const imgID = img.id;
    const imgClass = img.className;
    const imgURL = img.src;
    fetch(imgURL).then(function (response) {
      return response.text();
    }).then(function (text) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, 'text/xml');

      // Get the SVG tag, ignore the rest
      const svg = xmlDoc.getElementsByTagName('svg')[0];

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        svg.setAttribute('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        svg.setAttribute('class', imgClass + ' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      svg.removeAttribute('xmlns:a');

      // Check if the viewport is set, if the viewport is not set the SVG won't scale.
      if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
        svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'));
      }
      cb && svg.addEventListener('click', cb);

      // Replace image with new SVG
      img.parentNode.replaceChild(svg, img);
    });
  });
};