import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { getCookie } from '@/shared/tools';

import type { IORCompanyAddress, IORCompanyFilingCompanyRegisteredAddressMainAttributes } from '@/features/company/shared/models';


export const filingCompanyRegisteredAddressApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneFilingCompanyRegisteredAddress: builder.query<{ address: IORCompanyAddress }, void>( {
            query: () => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/office-address/`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.address?.uuid ], 'FilingCompanyRegisteredAddress' )
            ),
        } ),
        putFilingCompanyRegisteredAddress: builder.mutation<{ ch_transaction: string }, { address: Partial<IORCompanyFilingCompanyRegisteredAddressMainAttributes> }>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/office-address/`, method: 'put', data
            } ),
            invalidatesTags: ( { ch_transaction } ) => ( [
                ...providesList( [ ch_transaction ], 'FilingsCompanyRegisteredAddress' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'CompanyMinimal' ),
            ] )
        } ),
    } ),
} );

export const {
    useGetOneFilingCompanyRegisteredAddressQuery,
    useLazyGetOneFilingCompanyRegisteredAddressQuery,
    usePutFilingCompanyRegisteredAddressMutation,
    endpoints: filingCompanyRegisteredAddressApiEndpoints,
} = filingCompanyRegisteredAddressApi;