import { type FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { changeBellAlertActionResult, clearBellAlerts, createBellAlert, makeAllBellAlertsRead } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useBellAlerts } from '@/store/notifications/hooks/useBellAlerts.hook';
import { useCurrentUser } from '@/store/user/hooks';
import { Button, DataFormat, Dropdown, Menu, TitleWithIcon, Typography } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';
import { stringToColour } from '@/shared/tools';
import type { IBellAlert } from '@/features/notifications/models';
import bellIcon from '@/icons/envelope.svg';
export const GlobalBellNotifications: FC = memo(() => {
  const dispatchAction = useAppDispatch();
  const {
    bellAlertsRead,
    bellAlertsUnread,
    bellAlertsPending
  } = useBellAlerts();
  const {
    settings
  } = useCurrentUser();
  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    dispatchAction(clearBellAlerts());
    dispatchAction(createBellAlert({
      created_at: moment().subtract(1, 'day').format(),
      entity_name: 'Matthew Cleevely',
      entity_email: 'matthew.cleevely@gmail.com',
      connector_text: 'is requesting',
      action_text: 'investor connection',
      onActionAccept: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Connected'
        }));
      },
      onActionReject: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Denied'
        }));
      },
      accept_button_text: 'CONNECT',
      reject_button_text: 'DENY'
    }));
    dispatchAction(createBellAlert({
      created_at: moment().subtract(2, 'day').format(),
      entity_name: 'Charlie Ramsay',
      entity_email: 'charlie.ramsay@gmail.com',
      connector_text: 'is requesting',
      action_text: 'investor connection',
      onActionAccept: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Connected'
        }));
      },
      onActionReject: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Denied'
        }));
      },
      accept_button_text: 'CONNECT',
      reject_button_text: 'DENY'
    }));
    dispatchAction(createBellAlert({
      created_at: moment().subtract(3, 'day').format(),
      entity_name: 'Nigel Paget',
      entity_email: 'nigel.paget@gmail.com',
      connector_text: 'is requesting',
      action_text: 'investor connection',
      onActionAccept: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Connected'
        }));
      },
      onActionReject: bellAlert => {
        dispatchAction(changeBellAlertActionResult({
          bellAlert,
          newActionResult: 'Denied'
        }));
      },
      accept_button_text: 'CONNECT',
      reject_button_text: 'DENY'
    }));
  }, []);
  const mapAlert = useCallback((alert: IBellAlert) => {
    const getClassName = (isRead: boolean) => (!isRead ? '!bg-default-light-gray' : '!bg-white') + ' hover:cursor-auto hover:!bg-bg-default-light-gray p-0';
    return {
      label: <BellAlert {...alert} />,
      key: alert.id,
      className: getClassName(alert.read)
    };
  }, []);
  const notificationsParsed = useMemo(() => {
    return <>
            <div className="grid grid-flow-col justify-between !bg-white dark:!bg-background-dark p-4">
                <Typography.Text className="text-lg text-primary dark:text-primary-dark font-medium">
                    Notifications
                </Typography.Text>
                {bellAlertsUnread.length > 0 && <Typography.Text className="hover:cursor-pointer hover:text-success dark:hover:text-success-hover-dark font-roboto" onClick={() => dispatchAction(makeAllBellAlertsRead())}>
                    Mark all as read
                </Typography.Text>}
            </div>
            <Menu className="!p-0 !border-0 !rounded-none" items={[...bellAlertsPending.map(mapAlert), ...bellAlertsUnread.map(mapAlert), ...bellAlertsRead.map(mapAlert)]} />
        </>;
  }, [bellAlertsPending, bellAlertsUnread, bellAlertsRead]);
  return <>
        <Dropdown dropdownRender={() => notificationsParsed} trigger={['click']} onOpenChange={setMenuVisible} open={menuVisible} className="ml-4" placement="bottom" overlayClassName={`border border-solid border-border rounded-xl shadow-lg overflow-hidden w-max ` + (settings?.user_type === 'investor-company' ? '!top-[6.1rem]' : '!top-16')}>
            <div className="relative flex place-items-center hover:cursor-pointer">
                <SVGIcon src={bellIcon.src} className={'!w-6 !h-6 my-auto inline-block'} />
                {bellAlertsUnread.length > 0 && <span className="absolute -bottom-1.5 -right-1.5 px-1.5 py-1 leading-none
                      text-white dark:text-default-dark transform bg-success dark:bg-success-dark rounded-full
                      text-3xs">
                    {bellAlertsUnread.length}
                </span>}
            </div>
        </Dropdown>
    </>;
});
const BellAlert: FC<IBellAlert> = props => {
  return <>
        <div className="flex py-4 px-4 border-0 border-t border-solid border-border">
            <TitleWithIcon title={props.entity_name} hideTitle className="self-baseline" iconWrapperClassName="h-10 w-10" iconClassName={'text-sm'} iconBgColour={stringToColour(props.entity_name + props.id)} data-sentry-element="TitleWithIcon" data-sentry-source-file="GlobalBellNotifications.tsx" />
            <div className="flex-grow">
                <div>
                    <Typography.Text className="text-primary dark:text-primary-dark inline" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        {props.entity_name}
                    </Typography.Text>
                    <Typography.Text className="inline mx-1" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        {props.connector_text}
                    </Typography.Text>
                    <Typography.Text className="text-primary dark:text-primary-dark inline" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        {props.action_text}
                    </Typography.Text>
                </div>
                <div className="text-default-form-field font-roboto">
                    <Typography.Text className="inline text-inherit" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        {moment(props.created_at).fromNow()}
                    </Typography.Text>
                    <Typography.Text className="inline mx-1 text-inherit" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        &#8226;
                    </Typography.Text>
                    <Typography.Text className="inline text-inherit" data-sentry-element="unknown" data-sentry-source-file="GlobalBellNotifications.tsx">
                        <DataFormat raw={props.entity_email} type="email" inline className="!text-inherit" data-sentry-element="DataFormat" data-sentry-source-file="GlobalBellNotifications.tsx" />
                    </Typography.Text>
                </div>
                {!props.action_result && <div className="grid grid-flow-col justify-center grid-cols-2 gap-4 mt-1">
                          <Button onClick={() => props.onActionReject({
            ...props
          })} className="text-primary dark:text-primary-dark border-primary dark:border-primary-dark font-medium">
                              {props.reject_button_text}
                          </Button>
                          <Button onClick={() => props.onActionAccept({
            ...props
          })} type="success">
                              {props.accept_button_text}
                          </Button>
                      </div>}
                {props.action_result && <Typography.Text>
                          {props.action_result} <Typography.Text className="text-default-form-field inline">
                          ({moment(props.action_date).fromNow()})
                      </Typography.Text>
                      </Typography.Text>}
            </div>
        </div>
    </>;
};
GlobalBellNotifications.displayName = 'GlobalBellNotifications';