import { memo } from 'react';
import { Col, Row } from '@/components/structural';

// import { createAlert } from '@/store/notifications/actions';
import { useAlerts } from '@/store/notifications/hooks';
// import { useAppDispatch } from '@/store/hooks';
import { Alerts } from '@/features/notifications';
import { FilingDeadlinesCalendarWidget } from '@/features/company/my-company/widgets/filing/FilingDeadlinesCalendarWidget';
import { FilingDeadlinesListWidget } from '@/features/company/my-company/widgets';
export const DashboardCompanyMultiple = memo(() => {
  // const dispatchAction = useAppDispatch();
  const {
    alerts
  } = useAlerts('company-dashboard-multiple');

  /*useEffect( () =>
  {
      if ( alerts.length > 0 )
      { return; }
       dispatchAction( createAlert( {
          type: 'success',
          title: 'Success',
          content: 'Your registered address change has been accepted',
          channel: 'company-dashboard-multiple'
      } ) );
      dispatchAction( createAlert( {
          type: 'warning',
          title: 'Draft',
          content: 'You have a transaction in draft',
          channel: 'company-dashboard-multiple',
      } ) );
      dispatchAction( createAlert( {
          type: 'error',
          title: 'Rejected',
          content: 'Your change company name request has been rejected',
          channel: 'company-dashboard-multiple',
      } ) );
      dispatchAction( createAlert( {
          type: 'info',
          title: 'Pending',
          content: 'Your changes to Director, Anthony Jenking are pending',
          channel: 'company-dashboard-multiple',
      } ) );
  }, [ dispatchAction ] );*/

  return <>
        <Row gutter={[16, 16]}>
            <Col span={24} xxl={12}>
                {alerts.length > 0 && <Row gutter={[16, 16]} className="mb-4">
                          <Col span={24}>
                              <Alerts channel="company-dashboard-multiple" />
                          </Col>
                      </Row>}
                <Row gutter={[16, 16]} className={alerts.length === 0 ? 'h-full' : ''}>
                    <Col span={24}>
                        <FilingDeadlinesCalendarWidget doubleHeight={alerts.length === 0} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} xxl={12}>
                <Row gutter={[16, 16]} className="h-full">
                    <Col span={24}>
                        <FilingDeadlinesListWidget />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;
});
DashboardCompanyMultiple.displayName = 'DashboardCompanyMultiple';