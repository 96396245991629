import tw from 'tailwind-styled-components';
import { SVGIcon } from '@/components/structural/images';
export const TabIcon = tw(SVGIcon)<{
  $tabKey: string;
  $activeTabKey: string;
}>`
    !h-6
    !w-6 
    !mr-2
    translate-y-[-0.1rem] 
    ${props => props.$activeTabKey === props.$tabKey ? `text-success dark:text-success-dark` : `text-default dark:text-default-dark`}
`;