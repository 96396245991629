import { type FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { companyApiKey } from '@/store/company/service.key';
import { useFilingCompanyRegisteredNames } from '@/store/company/hooks/useFilingCompanyRegisteredNames.hook';
import { useLastAction } from '@/store/_global/actions-store/actions.hooks';
import { usePostFilingCompanyRegisteredNameAttachmentMutation, usePostFilingCompanyRegisteredNameMutation } from '@/store/company';
import { FormWithSteps, type IFormWithStepsStep, useStepsForm } from '@/components/structural/form';
import { Modal } from '@/components/structural';
import type { IORCompanyFilingCompanyRegisteredName } from '@/features/company/shared/models';
import { addAttachmentFileToStore, type IORAttachmentMainAttributes } from '@/store/_global/attachments';
import { type IStep1Data, Step1 } from './Step1';
import { type IStep2Data, Step2 } from './Step2';
import { Step3 } from './Step3';
import { StepEnd } from './StepEnd';
export const CompanyChangeRegisteredNameForm: FC = memo(() => {
  const {
    request,
    isFetching: filingIsFetching
  } = useFilingCompanyRegisteredNames();
  const [/*postFiling*/, {
    isLoading: filingPostIsLoading
  }] = usePostFilingCompanyRegisteredNameMutation();
  const [postAttachment, {
    isLoading: attachmentIsLoading
  }] = usePostFilingCompanyRegisteredNameAttachmentMutation();
  const {
    lastAction
  } = useLastAction(la => la.isApi && la.storeSliceKey === companyApiKey && la.endpoint.startsWith('postFilingCompanyRegisteredName'));
  const {
    currentStep,
    currentStepStatus,
    goToStep,
    isStepValid,
    next,
    prev,
    isValid,
    setIsValid
  } = useStepsForm();
  const [step1Data, setStep1Data] = useState<IStep1Data>(null);
  const [step2Data, setStep2Data] = useState<IStep2Data>(null);
  const attachmentUploads = useRef(0);
  const doSave = useCallback(() => {
    // TODO: once available, this needs to be removed and the commented code made to work
    goToStep(3);

    /*const data: IORCompanyFilingCompanyRegisteredNameMainAttributes = {
        notice_of_change: step2Data.notice_of_change,
        proposed_name: step2Data.proposed_name,
        resolution_date: step2Data.resolution_date,
        service: step1Data.service
    };
     postFiling( data );*/
  }, [step1Data, step2Data]);
  const doSaveDraft = useCallback(() => {
    Modal.destroyAll();
  }, [step1Data, step2Data]);
  useEffect(() => {
    if (!request) return;
    setStep1Data({
      checked_name_availability: true,
      checked_name_eligibility: true,
      fee_disclaimer: true,
      service: request.service
    });
    setStep2Data({
      confirm_resolution_file_distributed: true,
      files: request.attachments.data as unknown as File[],
      notice_of_change: request.notice_of_change,
      proposed_name: request.proposed_name,
      resolution_date: request.resolution_date
    });
  }, [request]);
  useUpdateEffect(() => {
    if (!lastAction.isApi || !lastAction.endpoint.includes('FilingCompanyRegisteredName')) return;
    if (!lastAction.endpoint.endsWith('Attachment')) {
      if (!step2Data.files?.length || 'file_size' in step2Data.files[0]) {
        goToStep(3);
        return;
      }
      const payload = lastAction.payload as unknown as IORCompanyFilingCompanyRegisteredName;
      const entity_id = payload.uuid;
      attachmentUploads.current = 0;
      step2Data.files?.forEach(file => {
        const data: IORAttachmentMainAttributes = {
          description: 'Company Filing / Company Registered Name / Attachment',
          entity_id,
          file_size: file.size,
          name: file.name
        };
        addAttachmentFileToStore(data, file);
        postAttachment(data);
      });
    } else {
      attachmentUploads.current++;
      const allAttachmentsUploaded = attachmentUploads.current === step2Data.files.length;
      if (allAttachmentsUploaded) {
        goToStep(3);
      }
    }
  }, [lastAction]);
  const steps: IFormWithStepsStep[] = [{
    content: <Step1 isStepValid={isStepValid} setIsValid={setIsValid} setData={setStep1Data} initialData={step1Data} />
  }, {
    content: <Step2 isStepValid={isStepValid} setIsValid={setIsValid} setData={setStep2Data} initialData={step2Data} />
  }, {
    content: <Step3 goToStep={goToStep} initialData={{
      step1: step1Data,
      step2: step2Data
    }} />
  }, {
    content: <StepEnd />
  }];
  return <FormWithSteps title="Change Registered Name" currentStep={currentStep} currentStepStatus={currentStepStatus} steps={steps} next={next} prev={prev} finish={doSave} saveDraft={doSaveDraft} currentStepIsValid={isValid} isLoading={filingIsFetching || filingPostIsLoading || attachmentIsLoading} />;
});
CompanyChangeRegisteredNameForm.displayName = 'CompanyChangeRegisteredNameForm';