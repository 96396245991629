import { type FC, memo } from 'react';
import { useCurrentCompany } from '@/store/company/hooks';
import { Loader, WidgetSimpleValueOnly } from '@/components/structural';
export const CompanyIncorporationDateWidget: FC = memo(() => {
  const {
    chProfile,
    isFetching
  } = useCurrentCompany();
  if (isFetching) return <Loader skeleton />;
  return <>
        <WidgetSimpleValueOnly value={chProfile?.date_of_creation} type="date" description="Incorporation Date" variant="light" />
    </>;
});
CompanyIncorporationDateWidget.displayName = 'CompanyIncorporationDateWidget';