import { type ComponentProps } from 'react';
import type { Moment } from 'moment/moment';
import styled from 'styled-components';
import CalendarAntD from 'antd/lib/calendar';
import type { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import twConfig from '@/shared/tailwindConfig';
const MomentCalendarAntD = CalendarAntD.generateCalendar<Moment>(momentGenerateConfig);
export type CalendarCustomProps = ComponentProps<typeof MomentCalendarAntD>;
export const Calendar = styled(MomentCalendarAntD)`
    .ant-select-arrow {
        top: 35%;
    }

    .ant-select-selection-item {
        line-height: 1.7rem !important;
    }

    .cell-has-deadline, .cell-today {
        width: 1.6rem;
        line-height: 1.6rem;
        margin-left: auto;
        margin-right: auto;
    }

    .ant-picker-panel {
        border-top: none;
    }

    .ant-picker-body thead tr {
        th {
            background-color: ${twConfig.theme.colors.background.DEFAULT};
            padding: 0.75rem;
            color: ${twConfig.theme.colors.default.darker};
            font-weight: bold;
        }
    }
`;
export const CalendarCell = styled.div<{
  $colour: string;
  $colourDark: string;
}>`
    & {
        background-color: ${p => p.$colour};
    }

    .dark & {
        background-color: ${p => p.$colourDark};
    }
`;
export const calendarHeaderRenderMonthAndYear = ({
  value
}) => <div className="p-4 py-2 grid place-content-center font-bold text-primary dark:text-primary-dark" data-sentry-component="calendarHeaderRenderMonthAndYear" data-sentry-source-file="Calendar.tsx">
          {value.format('MMMM YYYY')}
      </div>;
export const calendarLocale: PickerLocale = {
  'lang': {
    'locale': 'en_GB',
    'placeholder': 'Select date',
    'rangePlaceholder': ['Start date', 'End date'],
    'today': 'Today',
    'now': 'Now',
    'backToToday': 'Back to today',
    'ok': 'OK',
    'clear': 'Clear',
    'week': 'Week',
    'month': 'Month',
    'year': 'Year',
    'timeSelect': 'Select time',
    'dateSelect': 'Select date',
    'monthSelect': 'Choose a month',
    'yearSelect': 'Choose a year',
    'decadeSelect': 'Choose a decade',
    'yearFormat': 'YYYY',
    'dateFormat': 'M/D/YYYY',
    'dayFormat': 'D',
    'dateTimeFormat': 'M/D/YYYY HH:mm:ss',
    'monthFormat': 'MMMM',
    'monthBeforeYear': true,
    'previousMonth': 'Previous month (PageUp)',
    'nextMonth': 'Next month (PageDown)',
    'previousYear': 'Last year (Control + left)',
    'nextYear': 'Next year (Control + right)',
    'previousDecade': 'Last decade',
    'nextDecade': 'Next decade',
    'previousCentury': 'Last century',
    'nextCentury': 'Next century'
  },
  'timePickerLocale': {
    'placeholder': 'Select time'
  },
  'dateFormat': 'YYYY-MM-DD',
  'dateTimeFormat': 'YYYY-MM-DD HH:mm:ss',
  'weekFormat': 'YYYY-wo',
  'monthFormat': 'YYYY-MM'
};