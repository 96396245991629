import { type FC, useEffect, useState } from 'react';
import useMount from 'ahooks/lib/useMount';
import { Select } from '@/components/structural/form';
interface ISearchBoxProps {
  initialSearchValue: string;
  updateSearchFilterFn: (str: string) => void;
  isLoading: boolean;
  className?: string;
}
export const SearchBox: FC<ISearchBoxProps> = ({
  updateSearchFilterFn,
  initialSearchValue,
  isLoading,
  className = ''
}) => {
  const [searchStr, setSearchStr] = useState<string>();
  const [searchBox, setSearchBox] = useState<HTMLInputElement>();
  useEffect(() => {
    initialSearchValue && setSearchValue(initialSearchValue);
  }, [initialSearchValue]);
  useMount(() => {
    setSearchBox(document.getElementsByClassName('or-table-search').item(0).getElementsByTagName('input').item(0));
  });
  const setSearchValue = (rawValue?: string) => {
    const value = typeof rawValue === 'string' && !rawValue ? undefined : rawValue;
    updateSearchFilterFn(value);
    setSearchStr(value);
  };
  return <>
        <Select size="middle" className={'w-full xs:w-48 or-table-search ' + className} showSearch onKeyUp={() => {
      setSearchValue(searchBox.value);
    }} onBlur={() => {
      setSearchValue(searchBox.value);
    }} allowClear onClear={() => {
      setSearchValue();
    }} value={searchStr} placeholder="Search..." suffixIcon={null} filterOption={false} notFoundContent={null} loading={isLoading} data-sentry-element="Select" data-sentry-source-file="SearchBox.tsx" />
    </>;
};