import { useMemo } from 'react';

import { useAccount } from '@/store/account/hooks';
import { useAuth } from '@/store/auth/hooks';
import { useCurrentIDsGuard } from '@/store/user/hooks/useCurrentIDsGuard.hook';

import { getCookie } from '@/shared/tools';
import { useUser } from '@/store/user/hooks/useUser.hook';
import { useUsers } from '@/store/user/hooks/useUsers.hook';


export const useCurrentUser = ( { fetchAccount = false, skip = false }: { fetchAccount?: boolean, skip?: boolean } = {} ) =>
{
    const { awsUser } = useAuth();

    const { users, isFetching: accountsIsFetching, isLoading: accountsIsLoading, error: accountsError, status: accountsStatus } = useUsers( skip || !awsUser );
    const orCurrentUserUuid = getCookie( { cName: 'orCurrentUserUuid' } ) || users?.[ 0 ]?.uuid;

    const { user: userRaw, isFetching, isLoading, error, status } = useUser( orCurrentUserUuid, skip || !awsUser || !orCurrentUserUuid );

    const currentAccountUuid = userRaw?.settings?.currentAccountUuid || userRaw?.default_account;
    const currentCompanyUuid = userRaw?.settings?.currentCompanyUuid;
    const currentUserUuid = userRaw?.uuid;
    const userType = userRaw?.settings?.user_type;

    const { account } = useAccount( currentAccountUuid, !currentAccountUuid || !fetchAccount );
    const user = awsUser && userRaw ? { ...userRaw } : undefined;

    if ( user && fetchAccount && account )
    {
        Object.assign( user, { account } );
    }

    useCurrentIDsGuard( !!awsUser, currentAccountUuid, currentCompanyUuid, currentUserUuid );

    return useMemo( () => ( {
        currentUser: user,
        awsUser,
        settings: user?.settings || {},
        currentAccountUuid,
        currentCompanyUuid,
        userType,
        isLoading,
        isFetching,
        error,
        status
    } ), [ userRaw, awsUser, isFetching, isLoading, error, status,
        users, accountsIsFetching, accountsIsLoading, accountsError, accountsStatus,
        currentUserUuid, currentAccountUuid, currentCompanyUuid, userType ] );
};