import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doForgotPassword, doForgotPasswordError, doForgotPasswordSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';


function* forgotPasswordScript( { payload }: ReturnType<typeof doForgotPassword> )
{
    try
    {
        yield call( authService.forgotPassword, payload );
        yield routerNavigate( '/auth/forgot-password-request-success' );
        yield put( doForgotPasswordSuccess( payload ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onForgotPassword saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doForgotPasswordError( error ) );
    }
}

export function* onForgotPasswordSaga()
{
    yield takeEvery( doForgotPassword, forgotPasswordScript );
}