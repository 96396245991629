import { type FC, memo, PropsWithChildren } from 'react';
import omit from 'lodash/omit';
import tw from 'tailwind-styled-components';
import AntDButton, { type ButtonProps, type LegacyButtonType } from 'antd/lib/button/button';
import { Space } from '@/components/structural/Space';
export type ORButtonType = LegacyButtonType | 'success' | 'warning' | 'info' | 'danger';
export interface IORButtonProps extends Omit<ButtonProps, 'type'> {
  type?: ORButtonType;
}
const successClasses = `
    text-white
        dark:text-default-dark
    bg-success
        dark:bg-success-dark
    border-success
        dark:border-success-dark
    hover:text-white
        dark:hover:text-default-dark
    hover:border-success-hover
        dark:hover:border-success-hover-dark
    hover:bg-success-hover
        dark:hover:bg-success-hover-dark
    disabled:!bg-default-hover
        dark:disabled:!bg-default-hover
        dark:disabled:!border-default-hover
    disabled:!border-default-hover
`;
const defaultClasses = `
    bg-white
        dark:bg-default
    hover:bg-default-dark-gray
        dark:bg-default-hover-dark
    disabled:!bg-default-hover
        dark:disabled:!bg-default-hover
        dark:disabled:!border-default-hover
    disabled:!border-default-hover
`;
const warningClasses = `
    text-white
        dark:text-default-dark
    bg-warning
        dark:bg-warning-dark
    border-warning
        dark:border-warning-dark
    hover:text-white
        dark:hover:text-default-dark
    hover:border-warning-hover
        dark:hover:border-warning-hover-dark
    hover:bg-warning-hover
        dark:hover:bg-warning-hover-dark
    disabled:!bg-default-hover
        dark:disabled:!bg-default-hover
        dark:disabled:!border-default-hover
    disabled:!border-default-hover
`;
const infoClasses = `
    !text-white
        dark:!text-default-dark
    !bg-info
        dark:!bg-info-dark
    !border-info
        dark:!border-info-dark
    hover:!text-white
        dark:hover:!text-default-hover-dark
    hover:!border-info-hover
        dark:hover:!border-info-hover-dark
    hover:!bg-info-hover
        dark:hover:!bg-info-hover-dark
    disabled:!bg-default-hover
        dark:disabled:!bg-default-hover
        dark:disabled:!border-default-hover
    disabled:!border-default-hover
`;
const dangerClasses = `
    !text-white
        dark:!text-default-dark
    !bg-danger
        dark:!bg-danger-dark
    !border-danger
        dark:!border-danger-dark
    hover:!text-white
        dark:hover:!text-default-hover-dark
    hover:!border-danger-hover
        dark:hover:!border-danger-hover-dark
    hover:!bg-danger-hover
        dark:hover:!bg-danger-hover-dark
    disabled:!bg-default-hover
        dark:disabled:!bg-default-hover
        dark:disabled:!border-default-hover
    disabled:!border-default-hover
`;
export const Button: FC<PropsWithChildren<IORButtonProps>> = memo(props => {
  const newProps = Object.assign({}, omit(props, ['type', 'blurHeight', 'blurWidth']), {
    $type: props.type as ORButtonType || 'default'
  });
  return <CustomButton {...newProps}>
        {props.children}
    </CustomButton>;
});
export const ButtonGroup = Space.Compact;
Button.displayName = 'Button';
const CustomButton = tw(AntDButton)<{
  $type: ORButtonType;
}>`
    font-roboto
    ${p => (p.$type === 'success' ? successClasses : null) + (p.$type === 'default' ? defaultClasses : null) + (p.$type === 'warning' ? warningClasses : null) + (p.$type === 'info' ? infoClasses : null) + (p.$type === 'danger' ? dangerClasses : null)}
`;
const baseButtonClasses = `font-roboto flex items-center justify-center rounded-lg hover:cursor-pointer`;
export const successButtonClasses = `${baseButtonClasses} ${successClasses}`;
export const defaultButtonClasses = `${baseButtonClasses} ${defaultClasses}`;
export const warningButtonClasses = `${baseButtonClasses} ${warningClasses}`;
export const infoButtonClasses = `${baseButtonClasses} ${infoClasses}`;
export const dangerButtonClasses = `${baseButtonClasses} ${dangerClasses}`;