import { createAction } from '@reduxjs/toolkit';

import type { ConfirmSignUpOptions } from '@aws-amplify/auth/dist/esm/providers/cognito/types';
import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doSignup = createAction<{ email: string, password: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignup` );
export const doSignupSuccess = createAction<{ email: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignupSuccess` );
export const doSignupError = createAction<IRequestError>( `${ authSliceKey }/doSignupError` );

export const doSignupInvited = createAction<{ email: string, password: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignupInvited` );
export const doSignupInvitedSuccess = createAction<{ email: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignupInvitedSuccess` );
export const doSignupInvitedError = createAction<IRequestError>( `${ authSliceKey }/doSignupInvitedError` );

export const doSignupWithoutConfirmationEmail = createAction<{ email: string, password: string }>( `${ authSliceKey }/doSignupWithoutConfirmationEmail` );
export const doSignupWithoutConfirmationEmailSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupWithoutConfirmationEmailSuccess` );
export const doSignupWithoutConfirmationEmailError = createAction<IRequestError>( `${ authSliceKey }/doSignupWithoutConfirmationEmailError` );

export const doSignupResendConfirmationEmail = createAction<{ email: string }>( `${ authSliceKey }/doSignupResendConfirmationEmail` );
export const doSignupResendConfirmationEmailSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupResendConfirmationEmailSuccess` );
export const doSignupResendConfirmationEmailError = createAction<IRequestError>( `${ authSliceKey }/doSignupResendConfirmationEmailError` );

export const doSignupConfirmAccountWithCode = createAction<{ email: string, code: string, options?: ConfirmSignUpOptions }>( `${ authSliceKey }/doSignupConfirmAccountWithCode` );
export const doSignupConfirmAccountWithCodeSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupConfirmAccountWithCodeSuccess` );
export const doSignupConfirmAccountWithCodeError = createAction<IRequestError>( `${ authSliceKey }/doSignupConfirmAccountWithCodeError` );