import { useMemo } from 'react';

import { useGetOneCompanyQuery } from '@/store/company';

import { useCurrentUser } from '@/store/user/hooks';


export const useCompany = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error, status } = useGetOneCompanyQuery( uuid, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        company: data,
        isLoading,
        isFetching,
        status,
        error
    } ), [ data, isFetching, isLoading, error, status ] );
};