import styled from 'styled-components';
import { Card } from '@/components/structural/Card';
import twConfig from '@/shared/tailwindConfig';
const borderWidth = '0.05rem';
const borderRadius = '0.5rem';
export const CardWithSpacedAndColouredRows = styled(Card)`

    & table {
        border-collapse: separate;
        border-spacing: 0 0.5rem;

        thead th {
            border-bottom: none;
            color: ${twConfig.theme.colors.default.DEFAULT} !important;
        }

        thead th.ant-table-column-has-sorters:hover {
            background-color: #fff !important;
        }
    }

    & tbody {
        tr:not(.ant-table-measure-row):not(.ant-table-placeholder) {
            td {
                border-top: ${borderWidth} ${twConfig.theme.colors.default.light} solid;
                border-bottom: ${borderWidth} ${twConfig.theme.colors.default.light} solid;
            }

            td:last-child {
                border-right: ${borderWidth} ${twConfig.theme.colors.default.light} solid;
                border-top-right-radius: ${borderRadius};
                border-bottom-right-radius: ${borderRadius};
            }

            td:first-child {
                border: none;
                padding: 0 !important;
                height: 1rem;

                > div {
                    height: 100%;
                    width: 100%;
                    padding-left: 0.5rem;
                    display: flex;
                    position: relative;
                    align-items: center;
                    border-left-width: 0.75rem;
                    border-left-style: solid;
                    border-top-left-radius: ${borderRadius};
                    border-bottom-left-radius: ${borderRadius};
                    border-top: ${borderWidth} ${twConfig.theme.colors.default.light} solid;
                    border-bottom: ${borderWidth} ${twConfig.theme.colors.default.light} solid;
                }

                > div.alert-success {
                    border-left-color: ${twConfig.theme.colors.success.DEFAULT};
                }

                > div.alert-info {
                    border-left-color: ${twConfig.theme.colors.info.DEFAULT};
                }

                > div.alert-warning {
                    border-left-color: ${twConfig.theme.colors.warning.DEFAULT};
                }

                > div.alert-danger {
                    border-left-color: ${twConfig.theme.colors.danger.DEFAULT};
                }
            }
        }
    }

    .dark & table {
        thead th.ant-table-column-has-sorters:hover {
            background-color: ${twConfig.theme.colors.background.dark};
        }
    }

    .dark & tbody tr:not(.ant-table-measure-row):not(.ant-table-placeholder) :first-child {
        > div.alert-success {
            border-left-color: ${twConfig.theme.colors.success.dark};
        }

        > div.alert-info {
            border-left-color: ${twConfig.theme.colors.info.dark};
        }

        > div.alert-warning {
            border-left-color: ${twConfig.theme.colors.warning.dark};
        }

        > div.alert-danger {
            border-left-color: ${twConfig.theme.colors.danger.dark};
        }
    }
`;