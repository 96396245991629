import { type FC, memo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { SVGIcon } from '@/components/structural/images';
import { Button } from '@/components/structural/Button';
import backIcon from '@/icons/arrow-left.svg';
export interface IButtonBackProps {
  className?: string;
  onNavigateBack?: () => void;
}
export const ButtonBack: FC<IButtonBackProps> = memo(({
  className,
  onNavigateBack
}) => {
  const router = useRouter();
  const navigateBack = useCallback(() => {
    onNavigateBack ? onNavigateBack() : router.back();
  }, [onNavigateBack]);
  return <div className={className}><Button type="info" onClick={navigateBack} className="grid grid-cols-2 place-items-center pl-4 pr-8">
        <div className="inline-block w-6 grid place-items-center -mx-4">
            <SVGIcon src={backIcon.src} className="!w-5" />
        </div>
        <div className="inline-block w-10 text-center">
            BACK
        </div>
    </Button></div>;
});
ButtonBack.displayName = 'ButtonBack';