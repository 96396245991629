import { type FC, memo } from 'react';
import { Image } from '@/components/structural/images';
import { Typography } from '@/components/structural';
import { getCookie } from '@/shared/tools';
import emailImage from '@/images/portfolio-quickstart-step4.png';
export interface IAuthSignupResendConfirmationSuccessProps {
  className?: string;
}
export const AuthSignupResendConfirmationSuccess: FC<IAuthSignupResendConfirmationSuccessProps> = memo(props => {
  return <>
        <div className={`${props.className}`}>
            <div className="grid place-items-center -mt-4 mx-auto">
                <Image src={emailImage.src} height={200} width={200} alt="Digital Email Envelope" />
            </div>
            <div className="grid place-items-center w-full -mt-4 mb-12 mx-auto">
                <Typography.Title level={3} className="mt-4">
                    Check your inbox
                </Typography.Title>

                <Typography.Paragraph className="font-roboto">
                    A confirmation code has been resent to
                </Typography.Paragraph>

                <Typography.Paragraph className="font-roboto text-primary dark:text-primary-dark">
                    {getCookie({
            cName: 'orNewSignupEmail'
          }) || 'your chosen email address'}
                </Typography.Paragraph>
            </div>
        </div>
    </>;
});
AuthSignupResendConfirmationSuccess.displayName = 'AuthSignupResendConfirmationSuccess';