import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLoginInvited, doSignupInvited, doSignupInvitedError, doSignupInvitedSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { setCookie } from '@/shared/tools';


function* signupScript( { payload }: ReturnType<typeof doSignupInvited> )
{
    try
    {
        yield call( authService.signUpNoConfirm, payload );

        const { email, password, first_name, last_name } = payload;
        setCookie( { cName: 'orNewSignupEmail', cValue: email } );
        setCookie( { cName: 'orNewSignupFirstName', cValue: first_name } );
        setCookie( { cName: 'orNewSignupLastName', cValue: last_name } );
        setCookie( { cName: 'orNewSignupUserType', cValue: 'investor' } );

        yield put( doLoginInvited( { email, password } ) );
        yield put( doSignupInvitedSuccess( { email, first_name, last_name } ) );
    } catch ( err )
    {
        const error = handleError( err );

        // If the error is not a validation error, log it
        if ( error.code !== '400' )
        {
            processError( 'onSignupInvited saga', error );
        }

        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupInvitedError( error ) );
    }
}

export function* onSignupInvitedSaga()
{
    yield takeEvery( doSignupInvited, signupScript );
}