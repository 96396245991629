import { type FC, memo } from 'react';
import { doForgotPassword } from '@/store/auth/actions';
import { useAppDispatch } from '@/store/hooks';
import { AuthPageFormButton } from '@/features/auth/components';
import { Form, FormItem, Input } from '@/components/structural/form';
import { Typography } from '@/components/structural';
export interface IAuthForgotPasswordRequestFormProps {
  className?: string;
}
export const AuthForgotPasswordRequestForm: FC<IAuthForgotPasswordRequestFormProps> = memo(props => {
  const dispatchAction = useAppDispatch();
  const process = (values: {
    email: string;
  }) => {
    dispatchAction(doForgotPassword({
      email: values.email.toLocaleLowerCase().trim()
    }));
  };
  return <>
        <div className={props.className}>
            <Typography.Text className="my-4 block">
                Enter the email address you used to create your account and we’ll send a reset code to your inbox.
            </Typography.Text>
            <Form name="auth" layout="vertical" requiredMark={false} onFinish={process} validateTrigger="onSubmit">
                <FormItem label="Email" name="email" rules={[{
          required: true,
          message: 'Please provide your email!',
          type: 'email'
        }]}>
                    <Input />
                </FormItem>

                <FormItem wrapperCol={{
          span: 24
        }}>
                    <AuthPageFormButton>
                        SEND RESET CODE
                    </AuthPageFormButton>
                </FormItem>
            </Form>
        </div>
    </>;
});
AuthForgotPasswordRequestForm.displayName = 'AuthForgotPasswordRequestForm';