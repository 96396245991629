import { type FC, memo } from 'react';
import { UserTypeSelection } from '@/features/account/components';
import type { ICompanyOnboardData } from './company-onboard-data.interface';
export interface IStep1Props {
  className?: string;
  onChange?: (value: {
    user_type: ICompanyOnboardData['user_type'];
  }) => void;
}
export const Step1: FC<IStep1Props> = memo(props => {
  return <>
        <div className={`${props.className}`}>
            <UserTypeSelection onNext={props.onChange} hideNextButton />
        </div>
    </>;
});
Step1.displayName = 'CompanyOnboardStep1';