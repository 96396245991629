import { createAction } from '@reduxjs/toolkit';

import type { IAWSUser } from '@/features/auth/models';
import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doLogin = createAction<{ email: string, password: string }>( `${ authSliceKey }/doLogin` );
export const doLoginSuccess = createAction<IAWSUser>( `${ authSliceKey }/doLoginSuccess` );
export const doLoginError = createAction<IRequestError>( `${ authSliceKey }/doLoginError` );

export const doLoginInvited = createAction<{ email: string, password: string }>( `${ authSliceKey }/doLoginInvited` );
export const doLoginInvitedSuccess = createAction<IAWSUser>( `${ authSliceKey }/doLoginInvitedSuccess` );
export const doLoginInvitedError = createAction<IRequestError>( `${ authSliceKey }/doLoginInvitedError` );

export const doLoginNoPassword = createAction<{ email: string }>( `${ authSliceKey }/doLoginNoPassword` );
export const doLoginInvitedNoPassword = createAction<{ email: string }>( `${ authSliceKey }/doLoginInvitedNoPassword` );