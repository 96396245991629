import { type FC, type HTMLAttributes, memo } from 'react';
import tw from 'tailwind-styled-components';
export const Hamburger: FC<HTMLAttributes<HTMLDivElement>> = memo(props => <Wrapper {...props}>
          <div className="tham tham-e-squeeze tham-w-6">
              <div className="tham-box">
                  <div className="tham-inner bg-primary dark:bg-primary-dark" />
              </div>
          </div>
      </Wrapper>);
Hamburger.displayName = 'Hamburger';
const Wrapper = tw.div`
    flex
    place-items-center
    h-12
    cursor-pointer
`;