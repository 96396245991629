import { type FC, memo, useCallback, useMemo } from 'react';
import { useCurrentCompany } from '@/store/company/hooks';
import { Card, DataFormat, Loader, WidgetViewAllLink } from '@/components/structural';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { Table } from '@/components/tables/Table';
import { getArray } from '@/shared/tools';
interface IOfficersWidgetProps {
  maxToShow?: number;
}
export const OfficersWidget: FC<IOfficersWidgetProps> = memo(({
  maxToShow = 5
}) => {
  const {
    chOfficers,
    isFetching
  } = useCurrentCompany();
  const data = useMemo(() => getArray<typeof chOfficers.items[number]>(chOfficers?.items).filter(officer => !officer.resigned_on).map((officer, index) => ({
    ...officer,
    key: index.toString()
  }) as typeof chOfficers.items[0] & {
    key: string;
  }).slice(0, maxToShow), [chOfficers]);
  type DataType = typeof data[number];
  const getColumns = useCallback(() => [prepareColumnItem<DataType, 'name'>({
    title: 'Name',
    key: 'name',
    render: text => <DataFormat raw={text} maxCharsCutOff={30} />
  }), prepareColumnItem<DataType, 'appointed_on'>({
    title: 'Appointed On',
    key: 'appointed_on',
    render: date => <DataFormat raw={date} type="date" />
  })], []);
  if (isFetching) return <Loader skeleton />;
  return <>
        <Card title="Officers" styles={{
      body: {
        paddingBottom: '3.5rem'
      }
    }}>
            <Table<DataType> parsedData={data} getColumns={getColumns} disablePagination size="small" disableColumnsSelector />
            <WidgetViewAllLink url="/my-company/officers" />
        </Card>
    </>;
});
OfficersWidget.displayName = 'OfficersWidget';