import { type ConfigProviderProps } from 'antd';

import twConfig from '@/shared/tailwindConfig';


export const themeConfig: ConfigProviderProps['theme'] = ( {
    token: {
        colorPrimary: twConfig.theme.colors.primary.DEFAULT,
        colorPrimaryHover: twConfig.theme.colors.primary.hover,
        colorPrimaryActive: twConfig.theme.colors.primary.DEFAULT,
        colorError: twConfig.theme.colors.danger.DEFAULT,
        colorWarning: twConfig.theme.colors.warning.DEFAULT,
        colorSuccess: twConfig.theme.colors.success.DEFAULT,
        colorInfo: twConfig.theme.colors.info.DEFAULT,
        colorText: twConfig.theme.colors.default.DEFAULT,
        colorTextHeading: twConfig.theme.colors.primary.DEFAULT,
        fontFamily: twConfig.theme.fontFamily.poppins,
        motionDurationSlow: '0.3s',
        motionDurationMid: '0.3s',
        fontWeightStrong: twConfig.theme.fontWeight.medium,
        zIndexPopupBase: 2050,
        zIndexBase: 2050,
    },
    components: {
        Menu: {
            fontFamily: twConfig.theme.fontFamily.poppins,
            subMenuItemBorderRadius: 0,
            itemActiveBg: twConfig.theme.colors.background[ 'item-selected' ],
            itemSelectedBg: twConfig.theme.colors.background[ 'item-selected' ],
            itemMarginBlock: 0,
            itemMarginInline: 0,
            itemSelectedColor: twConfig.theme.colors.primary.DEFAULT,
            horizontalItemSelectedColor: twConfig.theme.colors.success.DEFAULT,
            horizontalItemHoverColor: twConfig.theme.colors.primary.DEFAULT,
            itemBorderRadius: 0,
            itemHoverColor: twConfig.theme.colors.primary.DEFAULT,
            padding: 16,
            controlHeightLG: 28,
        },
        Dropdown: {},
        Select: {
            optionSelectedBg: twConfig.theme.colors.background[ 'item-selected' ],
            optionSelectedFontWeight: twConfig.theme.fontWeight.normal,
            paddingXXS: 0,
            multipleItemHeight: 24,
            multipleItemHeightLG: 24,
        },
        Button: {},
        Card: {
            colorTextHeading: twConfig.theme.colors.primary.DEFAULT,
            lineHeight: twConfig.theme.lineHeight[ 'leading-5' ],
        },
        Table: {
            fontWeightStrong: twConfig.theme.fontWeight.normal,
            stickyScrollBarBg: '',
            stickyScrollBarBorderRadius: 20,
            headerBg: twConfig.theme.colors.primary.DEFAULT,
            headerColor: twConfig.theme.colors.white,
            headerSortActiveBg: twConfig.theme.colors.primary.DEFAULT,
            headerSortHoverBg: twConfig.theme.colors.primary.DEFAULT,
            bodySortBg: twConfig.theme.colors.white,
            rowSelectedBg: twConfig.theme.colors.background[ 'item-selected' ],
            rowSelectedHoverBg: twConfig.theme.colors.background[ 'item-selected' ],
        },
        Breadcrumb: {
            colorBgTextHover: 'transparent',
        },
        Input: {
            activeShadow: 'none',
        },
        InputNumber: {
            activeShadow: 'none',
        },
        Switch: {
            colorPrimary: twConfig.theme.colors.success.DEFAULT,
            colorPrimaryHover: twConfig.theme.colors.success.hover,
        },
        Form: {
            labelColor: twConfig.theme.colors.primary.DEFAULT,
        },
        Tooltip: {
            colorText: twConfig.theme.colors.default.alt,
            colorBgSpotlight: twConfig.theme.colors.default[ 'light-gray' ],
            colorTextLightSolid: '',
            fontSize: twConfig.theme.fontSize.xs,
            lineHeight: 1.25,
        },
        DatePicker: {
            activeShadow: 'none',
        },
        Tabs: {
            colorBorder: 'transparent',
        },
        Timeline: {
            dotBg: 'transparent',
        }
    },
    cssVar: true,
} );