import { type FC, memo, type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import Divider from 'antd/lib/divider';
import Dropdown from 'antd/lib/dropdown';
import FormItem from 'antd/lib/form/FormItem';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import StepsAntD, { type StepsProps } from 'antd/lib/steps';
import TypographyTitle from 'antd/lib/typography/Title';
import { Button } from '@/components/structural';
import { Image, SVGIcon } from '@/components/structural/images';
import { FORM_FOOTER_ANIMATION_TIME } from '@/shared/constants';
import logoImage from '@/images/logo-OR-full.png';
import saveDropdownCaret from '@/icons/caret-down.svg';
import twConfig from '@/shared/tailwindConfig';
export interface IFormWithStepsProps {
  title?: string;
  currentStep: number;
  currentStepStatus: StepsProps['status'];
  currentStepIsValid: boolean;
  steps: IFormWithStepsStep[];
  next: () => void;
  nextText?: ReactNode;
  prev: () => void;
  finish: () => void;
  finishText?: ReactNode;
  finishedText?: ReactNode;
  extraEndContentNextToCloseButton?: ReactNode;
  placeExtraEndContentNextToCloseButtonAtTheEnd?: boolean;
  centreCloseButtonAtTheEnd?: boolean;
  saveDraft?: () => void;
  cancel?: () => void;
  isLoading?: boolean;
  forceBack?: boolean;
  hideSteps?: boolean;
  hideNavigation?: boolean;
  showORLogo?: boolean;
  onButtonAnimationComplete?: () => void;
  disableAnimation?: boolean;
  actionStatus?: QueryStatus[];
}
export interface IFormWithStepsStep {
  title?: string;
  content: ReactNode;
  nextText?: ReactNode;
  className?: string;
}
export const FormWithSteps: FC<IFormWithStepsProps> = memo(({
  title = '',
  currentStep,
  currentStepStatus,
  currentStepIsValid,
  steps,
  prev,
  next,
  nextText = 'NEXT',
  finish,
  finishText = 'SUBMIT',
  finishedText = 'SUBMITTED',
  extraEndContentNextToCloseButton,
  placeExtraEndContentNextToCloseButtonAtTheEnd,
  centreCloseButtonAtTheEnd,
  saveDraft,
  cancel = Modal.destroyAll,
  isLoading = false,
  forceBack = false,
  hideSteps = false,
  hideNavigation = false,
  showORLogo = false,
  onButtonAnimationComplete = null,
  disableAnimation = false,
  actionStatus = []
}) => {
  const allSteps = useMemo(() => [...steps.filter(Boolean)], [steps]);
  const allStepsButLastOne = useMemo(() => [...allSteps.slice(0, -1)], [allSteps]);
  const stepsHeaderItems = useMemo(() => allStepsButLastOne.map((item, index) => ({
    key: item.title + index,
    title: item.title
  })), [allStepsButLastOne]);
  const [saveDropdownVisible, setSaveDropdownVisible] = useState(false);
  const handleSaveDropdownClick = (e: {
    key: string;
  }) => {
    switch (e.key) {
      case 'save_draft':
        saveDraft && saveDraft();
        break;
    }
    setSaveDropdownVisible(false);
  };
  const toggleDropdownFn = useCallback(() => setSaveDropdownVisible(v => !v), []);
  useEffect(() => {
    setSaveDropdownVisible(false);
  }, [currentStepIsValid]);
  const [animationComplete, setAnimationComplete] = useState<boolean>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  useUpdateEffect(() => {
    if (actionStatus.includes(QueryStatus.fulfilled)) {
      setAnimationComplete(false);
      timeoutRef.current = setTimeout(() => {
        setAnimationComplete(true);
        timeoutRef.current = null;
      }, disableAnimation ? 0 : FORM_FOOTER_ANIMATION_TIME);
    } else if (actionStatus.includes(QueryStatus.rejected)) {
      setAnimationComplete(null);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    return () => clearTimeout(timeoutRef.current);
  }, [actionStatus]);
  useUpdateEffect(() => {
    if (animationComplete === true) {
      setAnimationComplete(null);
      timeoutRef.current = null;
      onButtonAnimationComplete && onButtonAnimationComplete();
    }
  }, [animationComplete]);
  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  }, []);
  const CloseButton = <Button onClick={cancel} type="default" htmlType="button" className="w-full h-12">
        CLOSE
    </Button>;
  return <>
        {showORLogo && <ORLogo src={logoImage.src} height={38} width={200} alt="logo" className="mb-4" />}

        {title && <div className="grid place-items-center py-4 -mt-10 mb-4">
            <TypographyTitle level={4} className="!font-medium !font-poppins">{title}</TypographyTitle>
        </div>}

        {!hideSteps && <Steps current={currentStep} status={currentStepStatus} responsive={false} items={stepsHeaderItems} />}

        <Divider className={`w-[112%] ml-[-6%] ${hideSteps ? '-mt-2' : ''}`} />

        <ContentStyled className="steps-content">
            {currentStep < allSteps.length && allSteps[currentStep].content}
        </ContentStyled>

        {!hideNavigation && <FormItem wrapperCol={{
      span: 24
    }}>
            <div className={`grid grid-cols-3 gap-4 ${currentStep === 0 && !forceBack ? '!grid-cols-2' : currentStep === allSteps.length - 1 && extraEndContentNextToCloseButton ? '!grid-cols-1' : currentStep === allSteps.length - 1 && centreCloseButtonAtTheEnd && !extraEndContentNextToCloseButton ? 'items-center !grid-cols-1' : ''}`}>
                {currentStep === allSteps.length - 1 && !extraEndContentNextToCloseButton && !centreCloseButtonAtTheEnd && <>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </>}
                {currentStep < allSteps.length - 1 && <Button onClick={cancel} type="default" htmlType="button" className="w-full h-12">
                    CANCEL
                </Button>}
                {currentStep === allSteps.length - 1 && (extraEndContentNextToCloseButton ? <div className="flex justify-end gap-4">
                    {placeExtraEndContentNextToCloseButtonAtTheEnd ? <>
                        {CloseButton}
                        {extraEndContentNextToCloseButton}
                    </> : <>
                        {extraEndContentNextToCloseButton}
                        {CloseButton}
                    </>}
                </div> : CloseButton)}
                {/*{ currentStep === 0 && !forceBack && <>
                    <div>&nbsp;</div>
                 </> }*/}
                {(currentStep >= 1 && currentStep < allStepsButLastOne.length || forceBack && currentStep === 0) && <Button onClick={prev} type="info" className="w-full h-12">
                          BACK
                      </Button>}
                {currentStep >= 0 && currentStep < allStepsButLastOne.length - 1 && !forceBack && <Button onClick={next} type="success" htmlType="submit" className="w-full h-12" disabled={!currentStepIsValid}>
                          {allSteps[currentStep].nextText ?? nextText}
                      </Button>}
                {(currentStep === allStepsButLastOne.length - 1 || forceBack && currentStep !== allStepsButLastOne.length) && <>
                    {saveDraft && <Dropdown trigger={['click']} open={saveDropdownVisible} disabled={forceBack && !currentStepIsValid} dropdownRender={() => <Menu onClick={handleSaveDropdownClick} className="full-width-item !shadow" items={[{
            label: 'SAVE AS DRAFT',
            key: 'save_draft'
          }]} />}>
                        <Button type="success" htmlType="submit" className="w-full h-12" loading={isLoading}>
                            <div onClick={e => {
                if ((e.target as HTMLDivElement).closest('svg')) {
                  return;
                }
                currentStep === allStepsButLastOne.length - 1 ? timeoutRef.current === null ? finish() : null : next();
              }} className="text-center w-full">
                                {forceBack && currentStep < allStepsButLastOne.length - 1 ? allSteps[currentStep].nextText ?? nextText : animationComplete === false ? finishedText : finishText}
                                <SVGIcon onClick={toggleDropdownFn} src={saveDropdownCaret.src} wrapperClassName="absolute right-2 top-2.5" className="border-l border-0 border-solid border-white dark:border-default !w-7 !h-7 flex-shrink -mb-2 -mr-2 !pl-2" />
                            </div>
                        </Button>
                    </Dropdown>}
                    {!saveDraft && <Button type="success" htmlType="submit" className="w-full h-12 flex justify-between items-center" loading={isLoading}>
                            <span onClick={() => currentStep === allStepsButLastOne.length - 1 ? timeoutRef.current === null ? finish() : null : next()} className="inline-block flex-grow">
                                {forceBack && currentStep < allStepsButLastOne.length - 1 ? allSteps[currentStep].nextText ?? nextText : animationComplete === false ? finishedText : finishText}
                            </span>
                    </Button>}
                </>}
            </div>
        </FormItem>}
    </>;
});
FormWithSteps.displayName = 'FormWithSteps';
export const Steps = styled(StepsAntD)`
    & {
        .ant-steps-item-title::after {
            top: 1.5rem;
            height: 0.2rem;
            border-top: ${twConfig.theme.colors.default.DEFAULT} dashed 0.1rem;
            background-color: transparent !important;
        }

        .ant-steps-item-icon {
            margin: 0.4rem;
            border: none;
        }

        .ant-steps-icon {
            top: -0.1rem;
            left: 0.05rem;

            .anticon-check {
                display: inline;
            }
        }

        .anticon {
            display: contents;
        }

        .ant-steps-item-finish {
            .ant-steps-item-icon {
                background-color: ${twConfig.theme.colors.success.DEFAULT};
                box-shadow: 0 0.28rem 0.43rem -0.07rem rgb(0 0 0 / 0.1), 0 0.14rem 0.28rem -0.14rem rgb(0 0 0 / 0.1);

                .ant-steps-icon {
                    color: #fff;
                    top: 0;
                }
            }
        }

        .ant-steps-item-active {
            .ant-steps-item-icon {
                background-color: #fff;
                box-shadow: 0 0 0.43rem 0 rgb(0 0 0 / 0.1), 0 0.14rem 0.28rem -0.14rem rgb(0 0 0 / 0.1);

                .ant-steps-icon {
                    color: ${twConfig.theme.colors.success.DEFAULT}
                }
            }
        }

        .ant-steps-item-wait {
            .ant-steps-item-icon {
                background-color: ${twConfig.theme.colors.LighterGray};

                .ant-steps-icon {
                    color: ${twConfig.theme.colors.default.DEFAULT}
                }
            }
        }
    }
`;
const ORLogo = tw(Image)`
    relative
    w-full
    text-center
`;
const ContentStyled = styled.div`
    & {
        .ant-form-item:not([class*="simple-label"]) {
            label:not([class*="ant-checkbox-wrapper"]):not([class*="ant-radio-wrapper"]) {
                font-weight: 500;
            }
        }

        .ant-form-item.gray-label {
            label {
                color: ${twConfig.theme.colors.default.DEFAULT};
            }
        }

        .ant-form-item-has-error .ant-input {
            border-color: ${twConfig.theme.colors.danger.DEFAULT} !important;
        }

        .ant-form-item-has-error .ant-input:focus {
            box-shadow: 0 0 0 0.14286rem rgba(122, 122, 122, 0.2) !important;
        }
    }
`;
export const {
  Step
} = StepsAntD;