import { type FC, memo, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';
import { useCurrentUser } from '@/store/user/hooks';
import { Card } from '@/components/structural';
import { DataFormat, type IDataFormatProps } from './';
interface IWidgetSimpleProps {
  value: IDataFormatProps['raw'];
  type: IDataFormatProps['type'];
  variant?: 'lighter' | 'light' | 'regular' | 'success';
  maxDecimals?: IDataFormatProps['maxDecimals'];
  description?: string;
  onClick?: () => void;
  className?: string;
  valueSuffix?: ReactNode;
  valuePrefix?: ReactNode;
}
export const WidgetSimpleValueOnly: FC<IWidgetSimpleProps> = memo(props => {
  const {
    settings
  } = useCurrentUser();
  const isDarkMode = settings?.dark_mode || false;
  return <>
        <CardWrapper $variant={props.variant || 'regular'} styles={{
      header: {
        color: isDarkMode ? null : '#fff'
      },
      body: {
        padding: 0,
        height: '100%',
        width: '100%',
        display: 'grid',
        justifyItems: 'end',
        alignItems: 'center'
      }
    }} className={`${props.onClick ? 'hover:cursor-pointer' : ''} ${props.className || ''} shadow`} onClick={props.onClick}>
            <div className="grid place-items-center justify-items-end p-8 gap-4">
                <div className="flex content-baseline items-baseline">
                    {props.valuePrefix && <div className="text-3xl font-normal !text-white dark:!text-default-dark">
                              {props.valuePrefix}
                          </div>}
                    <DataFormat className="!text-3xl font-normal !text-white dark:!text-default-dark text-right leading-10" raw={props.value} type={props.type} keepDecimals={!!props.maxDecimals} maxDecimals={props.maxDecimals || 2} minDecimals={props.maxDecimals || 2} hidePercentageBar />
                    {props.valueSuffix && <div className="text-3xl font-normal !text-white dark:!text-default-dark">
                              {props.valueSuffix}
                          </div>}
                </div>
                <div className="text-white dark:text-default-dark text-right">
                    {props.description}
                </div>
            </div>
        </CardWrapper>
    </>;
});
WidgetSimpleValueOnly.displayName = 'WidgetSimpleValueOnly';
const CardWrapper = tw(Card)<{
  $variant: IWidgetSimpleProps['variant'];
}>`
    ${props => props.$variant === 'lighter' ? `
    bg-primary-lighter
        dark:bg-primary-lighter-dark
    ` : ''}
    ${props => props.$variant === 'light' ? `
    bg-primary-light
        dark:bg-primary-light-dark
    ` : ''}
    ${props => props.$variant === 'regular' ? `
    bg-primary
        dark:bg-primary-dark
    ` : ''}
    ${props => props.$variant === 'success' ? `
    bg-success
        dark:bg-success-dark
    ` : ''}
`;