import { type ClipboardEvent, type FC, type KeyboardEvent, memo, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import useReactive from 'ahooks/lib/useReactive';
import { doSignupConfirmAccountWithCode } from '@/store/auth/actions';
import { useAppDispatch } from '@/store/hooks';
import { AuthPageFormButton } from '@/features/auth/components';
import { Form, FormItem, Input, type InputRef, InputTypeNumberNoArrows, useForm } from '@/components/structural/form';
import { Space } from '@/components/structural';
import { handleInputBoxCodePaste, setInputBoxCodeDigit } from '@/features/auth/tools';
import { getCookie } from '@/shared/tools';
export interface IAuthSignupConfirmFormProps {
  className?: string;
}
export const AuthSignupConfirmForm: FC<IAuthSignupConfirmFormProps> = memo(props => {
  const dispatchAction = useAppDispatch();
  const {
    query
  } = useRouter();
  const [form] = useForm();
  const submitted = useRef(false);
  const refs = useRef<InputRef[]>([]);
  const codeState = useReactive({
    '0': '',
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': ''
  });
  const getCode = (state: typeof codeState) => Object.values(state).join('');
  const process = (values: {
    email: string;
    password: string;
  }) => {
    dispatchAction(doSignupConfirmAccountWithCode({
      email: values.email.toLocaleLowerCase().trim(),
      code: getCode(codeState)
    }));
  };
  useEffect(() => {
    const {
      code,
      username
    } = query;
    if (!submitted.current && code?.length === 6 && username?.length) {
      const email = (username as string).replace(' ', '+');
      dispatchAction(doSignupConfirmAccountWithCode({
        email,
        code: code as string
      }));
      submitted.current = true;
    } else {
      form.setFieldValue('email', getCookie({
        cName: 'orNewSignupEmail'
      }));
    }
  }, []);
  const lastStateDigit = codeState['5'];
  useEffect(() => {
    if (getCode(codeState).length === 6 && form.isFieldValidating('email')) {
      form.submit();
    }
  }, [lastStateDigit, form]);
  return <>
        <Form form={form} name="auth" onFinish={process} className={props.className}>
            <FormItem label="Email" name="email" rules={[{
        required: true,
        message: 'Please provide your email!',
        type: 'email'
      }]}>
                <Input />
            </FormItem>

            <FormItem label="Code">
                <Space size={18} className="justify-between w-full">
                    {Array.from(Array(6).keys()).map(index => <InputTypeNumberNoArrows ref={(el: InputRef) => refs.current[index] = el} key={index} type="text" placeholder="" wholeNumbers min={0} max={9} value={codeState[index]} onPaste={(e: ClipboardEvent) => handleInputBoxCodePaste(e.clipboardData.getData('text/plain'), codeState, refs.current)} onKeyUp={(e: KeyboardEvent) => setInputBoxCodeDigit(e.key, index, codeState, refs.current, e.code)} className="w-16" />)}
                </Space>
            </FormItem>

            <FormItem wrapperCol={{
        span: 24
      }}>
                <AuthPageFormButton>
                    CONFIRM ACCOUNT
                </AuthPageFormButton>
            </FormItem>
        </Form>
    </>;
});
AuthSignupConfirmForm.displayName = 'AuthSignupConfirmForm';