import { type AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC, Suspense, useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { PersistorStore, store } from '@/store';
import { App, ConfigProvider, LoaderFullscreen } from '@/components/structural';
import { AppLayoutAuthenticated, AppLayoutGuest } from '@/features/layout';
import { usePageLoaded, usePageTitle } from '@/features/layout/hooks';
import { SentryErrorBoundary } from '@/components/SentryErrorBoundary';
import configureLibs from '@/_libs-config/configureLibs';
import setupPolyfills from '@/_libs-config/setupPolyfills';
// import setupAxiosInterceptors from '@/_libs-config/setupAxiosInterceptors';
import { themeConfig } from '@/styles/themeConfig';
import '@/styles/globals.css';
if (typeof window !== 'undefined') {
  setupPolyfills();
  if (process.env.NODE_ENV === 'development') {
    const reportWebVitals = require('@/_libs-config/reportWebVitals');
    reportWebVitals.default((message: string, metric: string) => {
      console.warn(message, metric);
    }, false);
  }
}

// Assume defaultLandingPage is exported as a string, e.g. '/landing'
const defaultLandingPage = require('../shared/defaultLandingPage.js');
const appLayoutPropsRouteMap = {
  '/my-company/onboard': {
    hideBreadcrumbs: true,
    hideHeader: true,
    hideTopViewToggle: true
  },
  '/portfolio/projections': {
    showPageViewSelector: true
  },
  '/auth/invited-signup': {
    fullScreen: true,
    hideLogo: true
  }
};
const ORApp: FC<AppProps> = ({
  Component,
  pageProps
}) => {
  const router = useRouter();
  const {
    pageTitleWithDash
  } = usePageTitle();
  const {
    pageLoaded,
    setPageLoaded
  } = usePageLoaded();
  const [showLoader, setShowLoader] = useState(true);

  // Initialise auth state from the store.
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!store.getState().auth?.awsUser);

  // Run one-time configuration.
  useEffect(() => {
    configureLibs();
    // setupAxiosInterceptors( store.dispatch );
  }, []);

  // Subscribe to the store to update auth state.
  useEffect(() => {
    return store.subscribe(() => {
      const authState = !!store.getState().auth?.awsUser;
      setIsAuthenticated(authState);
    });
  }, []);

  // Navigate based on authentication state.
  useEffect(() => {
    if (isAuthenticated && router.pathname.startsWith('/auth/invited-signup')) {
      return;
    } else if (!isAuthenticated && !router.pathname.startsWith('/auth')) {
      router.push('/auth/login');
    } else if (isAuthenticated && router.pathname.startsWith('/auth')) {
      router.push(defaultLandingPage);
    }
  }, [isAuthenticated, router.pathname, router]);

  // Use router events to control loader and pageLoaded.
  useEffect(() => {
    const handleRouteChangeStart = () => {
      setShowLoader(true);
      setPageLoaded(false);
    };
    const handleRouteChangeComplete = () => {
      setPageLoaded(true);
      // Small delay before hiding the loader.
      setTimeout(() => setShowLoader(false), 500);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    handleRouteChangeComplete();
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events, setPageLoaded]);

  // Optionally, delay rendering until the page is fully loaded.
  if (!pageLoaded) {
    return <LoaderFullscreen isVisible={true} />;
  }
  const currentPagePropsMap = appLayoutPropsRouteMap[router.pathname] || {};
  const renderContent = () => {
    if (router.pathname === '/landing') {
      return <Component {...pageProps} />;
    }
    return isAuthenticated && !router.pathname.startsWith('/auth/invited-signup') ? <AppLayoutAuthenticated hideBreadcrumbs={currentPagePropsMap.hideBreadcrumbs} showPageViewSelector={currentPagePropsMap.showPageViewSelector} hideHeader={currentPagePropsMap.hideHeader} hideTopViewToggle={currentPagePropsMap.hideTopViewToggle} data-sentry-element="AppLayoutAuthenticated" data-sentry-component="renderContent" data-sentry-source-file="_app.tsx">
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
              </AppLayoutAuthenticated> : <AppLayoutGuest fullScreen={currentPagePropsMap.fullScreen} hideLogo={currentPagePropsMap.hideLogo} data-sentry-element="AppLayoutGuest" data-sentry-component="renderContent" data-sentry-source-file="_app.tsx">
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
              </AppLayoutGuest>;
  };
  return <>
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <title>{pageTitleWithDash}</title>
        </Head>
        <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
            <PersistGate persistor={(store as PersistorStore).__persistor} data-sentry-element="PersistGate" data-sentry-source-file="_app.tsx">
                <ConfigProvider theme={themeConfig} data-sentry-element="ConfigProvider" data-sentry-source-file="_app.tsx">
                    <App data-sentry-element="App" data-sentry-source-file="_app.tsx">
                        <SentryErrorBoundary data-sentry-element="SentryErrorBoundary" data-sentry-source-file="_app.tsx">
                            <Suspense fallback={<LoaderFullscreen isVisible={true} />} data-sentry-element="Suspense" data-sentry-source-file="_app.tsx">
                                {renderContent()}
                            </Suspense>
                            <LoaderFullscreen isVisible={showLoader} data-sentry-element="LoaderFullscreen" data-sentry-source-file="_app.tsx" />
                        </SentryErrorBoundary>
                    </App>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </>;
};
export default ORApp;