import { type FC, memo, type MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { useDeleteTagMutation, usePostTagMutation } from '@/store/company';
import { useLastAction } from '@/store/_global/actions-store/actions.hooks';
import { useTags } from '@/store/company/hooks';
import { type RefSelectProps, Select } from '@/components/structural/form';
import { searchFilterOption, searchFilterSort } from '@/components/tables';
import { Spin, Tag } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';
import type { IORCompanyTag } from '@/features/company/shared/models';
import trashIcon from '@/icons/trash.svg';
export interface ITagSelectorProps {
  className?: string;
  onSelect?: (allSelectedTags: IORCompanyTag[], tag: IORCompanyTag) => void;
  selectedTags?: (IORCompanyTag | string)[];
}
type SelectItemType = {
  tag: IORCompanyTag;
  sortByValue: string;
  filterByValue: string;
};
type SelectValueType = string;
export const TagSelector: FC<ITagSelectorProps> = memo(props => {
  const [selectedTags, setSelectedTags] = useState<IORCompanyTag[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [postTag, {
    isLoading: postTagIsLoading
  }] = usePostTagMutation();
  const [deleteTag, {
    isLoading: deleteTagIsLoading
  }] = useDeleteTagMutation();
  const {
    lastAction: lastPostAction
  } = useLastAction(la => la.isApi && la.endpoint === 'postTag' && la.type === QueryStatus.fulfilled);
  const {
    lastAction: lastDeleteAction
  } = useLastAction(la => la.isApi && la.endpoint === 'deleteTag' && la.type === QueryStatus.fulfilled);
  const {
    tags,
    isFetching: tagsAreFetching,
    status
  } = useTags();
  const initialSelectedTagsSetRef = useRef(false);
  useEffect(() => {
    if (status === QueryStatus.fulfilled && props.selectedTags?.length && !initialSelectedTagsSetRef.current) {
      setSelectedTags(props.selectedTags.map(tag => typeof tag === 'string' ? tags.find(t => t.uuid === tag) : tag).filter(Boolean) as IORCompanyTag[]);
      initialSelectedTagsSetRef.current = true;
    }
  }, [status, tags, props.selectedTags]);
  const ref = useRef<RefSelectProps>();
  const handleCreateTag = (value: IORCompanyTag['text']) => {
    postTag({
      text: value,
      is_important: false,
      is_private: false
    });
  };
  const handleDeleteTag = (uuid: SelectValueType) => {
    deleteTag(uuid);
  };
  const handleSelect = (value: SelectValueType, {
    tag
  }: SelectItemType) => {
    setSearchValue('');
    const selectedTag = tag || tags.find(t => t.text === value || t.uuid === value);
    if (!selectedTag) return handleCreateTag(value);
    setSelectedTags(prev => {
      const updatedSelectedTags = [...prev, selectedTag];
      props.onSelect?.(updatedSelectedTags, selectedTag);
      return updatedSelectedTags;
    });
  };
  const handleDeselect = (uuid: SelectValueType) => {
    setSelectedTags(prev => {
      const updatedSelectedTags = prev.filter(tag => tag.uuid !== uuid);
      const deselectedTag = tags.find(t => t.uuid === uuid);
      props.onSelect?.(updatedSelectedTags, deselectedTag);
      return updatedSelectedTags;
    });
  };
  useUpdateEffect(() => {
    if (!lastPostAction) return;
    handleSelect(null, {
      tag: lastPostAction.payload as unknown as IORCompanyTag
    } as SelectItemType);
  }, [lastPostAction]);
  useUpdateEffect(() => {
    if (!lastDeleteAction) return;
    setSelectedTags(prev => {
      const deletedUUID = lastDeleteAction.isApi && lastDeleteAction.originalArgs as string;
      const deletedTag = prev.find(t => t.uuid === deletedUUID);
      const updatedTags = prev.filter(t => t.uuid !== deletedUUID);
      props.onSelect?.(updatedTags, deletedTag);
      return updatedTags;
    });
  }, [lastDeleteAction]);
  const filteredTags = useMemo(() => tags.filter(tag => !selectedTags.find(t => t.uuid === tag.uuid)).map(tag => ({
    tag,
    sortByValue: tag.text,
    filterByValue: tag.text,
    label: tag.text,
    value: tag.uuid,
    key: tag.uuid
  })), [tags]);
  return <>
              <Select<SelectValueType[], SelectItemType> ref={ref} mode="tags" showSearch placeholder="Select or create tags" value={selectedTags.map(tag => tag.uuid)} notFoundContent={tagsAreFetching || postTagIsLoading || deleteTagIsLoading ? <Spin size="small" /> : null} onSearch={setSearchValue} open={searchValue.length > 0} onSelect={handleSelect} onDeselect={handleDeselect} filterOption={searchFilterOption} filterSort={searchFilterSort} tagRender={tagOptions => {
      if (!tagOptions.value) return null;
      const {
        value: uuid,
        closable,
        onClose
      } = tagOptions;
      const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      const tag = selectedTags.find(t => t.uuid === uuid);
      return <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{
        marginInlineEnd: 4
      }}>
                                  {tag.text}
                              </Tag>;
    }} options={filteredTags} optionRender={option => <div className="flex items-center content-center" key={option.value}>
                              {option.label}
                              {option.data.tag && <SVGIcon src={trashIcon.src} onClick={(e: MouseEvent) => {
        handleDeleteTag(option.data.tag.uuid);
        e.stopPropagation();
      }} className="text-danger dark:text-danger-dark !w-3 !h-3 ml-1" />}
                          </div>} />
          </>;
});
TagSelector.displayName = 'TagSelector';