import { type FC, memo, useMemo } from 'react';
import { useCompaniesMinimal } from '@/store/company/hooks';
import { AlertOR, AlertsContainer } from '@/features/notifications';
import { CompanyAddForm } from '@/features/company/my-company/components';
import { SVGIcon } from '@/components/structural/images';
import errorIcon from '@/icons/exclamation-triangle-fill.svg';
import type { ICompanyOnboardData } from './company-onboard-data.interface';
export interface IStep2Props {
  className?: string;
  onChange: (company: ICompanyOnboardData['company']) => void;
  data?: {
    company?: ICompanyOnboardData['company'];
  };
}
export const Step2: FC<IStep2Props> = memo(props => {
  const {
    companies
  } = useCompaniesMinimal();
  const companyExists = useMemo(() => {
    if (!companies || !props.data?.company) return false;
    return companies.find(c => c.company_number === props.data.company.company_number);
  }, [props.data?.company, companies]);
  return <>
        <div className={`${props.className}`}>
            {companyExists && <AlertsContainer $slimline><AlertOR className="mb-4" type="error" message={<span className="text-default dark:text-default-dark">The selected company has already been added to your account.</span>} icon={<span className="ant-alert-icon"><SVGIcon src={errorIcon.src} /></span>} showIcon /></AlertsContainer>}
            <CompanyAddForm selectedCompany={props.data?.company} onSelect={props.onChange} guidanceText={`Using the search box below, type in the registered name or number of the company
                    that you would like to manage on OneRegistry. If you need to add more than one
                    company, you can do that later.`} hideMainButton />
        </div>
    </>;
});
Step2.displayName = 'CompanyOnboardStep2';