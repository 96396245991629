import moment from 'moment/moment';
import { CompaniesFilingFormsConfirmationStatementHelpText, CompaniesFilingLogHelpText, CompaniesOfficersHelpText, CompaniesPSCsHelpText } from '@/features/company/my-company/breadcrumb-help';
import { PortfolioInvestmentsHelpText } from '@/features/portfolio/breadcrumb-help';
import twConfig from '@/shared/tailwindConfig';

// import accountIcon from '@/icons/person.svg';
// import dataRoomIcon from '@/icons/briefcase.svg';
import homeIcon from '@/icons/house.svg';

/**
 * GENERAL
 */
export const EMAIL_VALIDATION_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
export const MAX_TEXT_CHAR_CUTOFF = 40;
export const OR_HMRC_COMPANY_EIS_LIMIT_CURRENT_YEAR = 5_000_000;
export const OR_HMRC_COMPANY_EIS_LIMIT_LIFETIME = 12_000_000;
export const OR_HMRC_COMPANY_SEIS_LIMIT = 250_000;
export const OR_HMRC_SELF_EIS_LIMIT = 2_000_000;
export const OR_HMRC_SELF_SEIS_LIMIT = 100_000;
export const BEGINNING_OF_TAX_YEAR_MOMENT = (moment().isBefore(moment().set({
  month: 3,
  date: 6
})) ? moment().subtract(1, 'year').set({
  month: 3,
  date: 6
}) : moment().set({
  month: 3,
  date: 6
})).startOf('day');
export const END_OF_TAX_YEAR_MOMENT = BEGINNING_OF_TAX_YEAR_MOMENT.clone().add(1, 'year').set({
  month: 3,
  date: 5
}).endOf('day');

/***
 * API
 */

// import { pageSizeOptions } from '@/components/tables';

export const MAX_PER_PAGE = 1_000_000;
export const DEFAULT_PER_PAGE = MAX_PER_PAGE;
export const VIEW_ALL_COMPANIES_ID = 'view-all';

/***
 * COLOURS
 */
// import myCompanyIcon from '@/icons/house.svg';
// import portfolioIcon from '@/icons/graph-up-arrow.svg';
// import shareCapitalIcon from '@/icons/pie-chart.svg';

export const COMPANY_COLOURS = [twConfig.theme.colors.primary.DEFAULT, '#506c93', twConfig.theme.colors.info.DEFAULT, '#b266ec', '#638fd3', '#74b3d8', '#b8d7f4', twConfig.theme.colors.success.DEFAULT, '#91e0d4', '#a4fcdf'];
export const COMPANY_COLOURS_DARK = [twConfig.theme.colors.info.dark, twConfig.theme.colors.info['hover-dark'], twConfig.theme.colors.warning.dark, twConfig.theme.colors.primary['hover-dark'], twConfig.theme.colors.primary['light-dark']];

/***
 * GENERAL ROUTING (used in AppLayout.tsx)
 */

export const ROUTES_TO_ALLOW_NAVIGATION_IF_VIEWING_MULTIPLE_COMPANIES = ['/my-company', '/my-company/onboard', '/account', '/account/audit', '/portfolio', '/portfolio/', '/landing', '/auth', '/auth/'];
export const routeAllowedOnMultipleCompanies = (currentCompanyUuid: string, nextUrl: string, debug = false) => {
  debug && console.log(currentCompanyUuid, nextUrl);
  const viewingAllCompanies = currentCompanyUuid === VIEW_ALL_COMPANIES_ID;
  if (viewingAllCompanies) {
    const nextUrlIsExactMatch = ROUTES_TO_ALLOW_NAVIGATION_IF_VIEWING_MULTIPLE_COMPANIES.includes(nextUrl);
    if (nextUrlIsExactMatch) {
      debug && console.log('NextURL is exact allowed match');
      return true;
    }
    const nextUrlStartsWithUrl = ROUTES_TO_ALLOW_NAVIGATION_IF_VIEWING_MULTIPLE_COMPANIES.reduce((containsUrl, url) => {
      debug && console.log(url);
      const nextUrlStartsWithUrlComparisonResult = url.at(-1) === '/' && nextUrl.startsWith(url);
      if (nextUrlStartsWithUrlComparisonResult) {
        debug && console.log('MATCH FOUND - STARTS WITH', url);
      }
      return containsUrl || nextUrlStartsWithUrlComparisonResult;
    }, false);
    if (nextUrlStartsWithUrl) {
      debug && console.log('NextURL starts with allowed url');
      return true;
    }
    return false;
  }
  debug && console.log('Not viewing All Companies');
  return true;
};

/***
 * BREADCRUMBS
 */

export const BREADCRUMBS_PAGES_TO_HIDE_ON = ['/my-company', '/my-company/quickstart', '/portfolio', '/portfolio/quickstart', '/share-capital', '/404'];
export const BREADCRUMBS_ICONS_MAP = {
  // 'account': accountIcon,
  // 'documents': dataRoomIcon,
  'home': homeIcon
  // 'my-company': myCompanyIcon,
  // 'portfolio': portfolioIcon,
  // 'share-capital': shareCapitalIcon,
  // 'stakeholders': shareCapitalIcon,
};
export const BREADCRUMBS_CUSTOM_TITLE = {
  '/my-company/filings-log': 'Companies House Filings Log',
  '/my-company/pscs': 'PSCs'
};
export const BREADCRUMBS_CUSTOM_HELP_TEXT = {
  '/my-company/filings-log': <CompaniesFilingLogHelpText />,
  '/my-company/officers': <CompaniesOfficersHelpText />,
  '/my-company/pscs': <CompaniesPSCsHelpText />,
  '/my-company/filing-form/confirmation-statement': <CompaniesFilingFormsConfirmationStatementHelpText />,
  '/portfolio/investments': <PortfolioInvestmentsHelpText />
};

/**
 * UI
 */
export const FORM_FOOTER_ANIMATION_TIME = 1_500;