import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, extractUuidFromUrl, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyShareRound, IORCompanyShareRoundMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const shareRoundAdapter = createEntityAdapter<IORCompanyShareRound, string>( {
    sortComparer: ( a, b ) => compare( a.default_issue_date, b.default_issue_date, true ),
    selectId: ( e ) => e.uuid,
} );

export const mapShareRound = ( e: IORCompanyShareRound ) => ( {
    ...e,
    uuid: extractUuidFromUrl( e.url ),
    share_class: extractUuidFromUrl( e.share_class ),
    company: extractUuidFromUrl( e.company ),
} );

export const shareRoundApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneShareRound: builder.query<IORCompanyShareRound, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/${ uuid }`, method: 'get'
            } ),
            transformResponse: mapShareRound,
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareRound' )
            ),
        } ),
        getManyShareRounds: builder.query<EntityState<IORCompanyShareRound, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareRounds', 'ShareRound' )
            ),
            transformResponse: ( data: IORCompanyShareRound[], meta: IORResponseMetaData ) => ( {
                ...shareRoundAdapter.upsertMany( shareRoundAdapter.getInitialState(), data.map( mapShareRound ) ),
                ...{ meta }
            } )
        } ),
        patchShareRound: builder.mutation<IORCompanyShareRound, { uuid: string, data: Partial<IORCompanyShareRoundMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'ShareRound' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'CompanyMinimal' ),
            ] )
        } ),
        postShareRound: builder.mutation<IORCompanyShareRound, Partial<IORCompanyShareRoundMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/`, method: 'post', data
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'ShareRounds' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'CompanyMinimal' )
            ] )
        } ),
        deleteShareRound: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareround/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'ShareRounds', 'ShareRound' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'Company' ),
                ...providesIds( [ getCookie( { cName: 'orCurrentCompanyUuid' } ) ], 'CompanyMinimal' )
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneShareRoundQuery,
    useLazyGetOneShareRoundQuery,
    useGetManyShareRoundsQuery,
    useLazyGetManyShareRoundsQuery,
    usePatchShareRoundMutation,
    usePostShareRoundMutation,
    useDeleteShareRoundMutation,
    endpoints: shareRoundApiEndpoints,
} = shareRoundApi;