import { type FC, memo } from 'react';
import { useCurrentUser } from '@/store/user/hooks';
import { PageHeader, Typography } from '@/components/structural';
export interface IDashboardCompanyMultipleHeaderProps {
  className?: string;
}
export const DashboardCompanyMultipleHeader: FC<IDashboardCompanyMultipleHeaderProps> = memo(props => {
  const {
    currentUser
  } = useCurrentUser();
  const className = `p-0 pb-4 ${props.className}`;
  const userGreeting = `Welcome${currentUser?.first_name ? ', ' + currentUser?.first_name : ''}!`;
  return <>
        <PageHeader ghost className={className} title={<Typography.Title ellipsis={{
      tooltip: userGreeting
    }} level={1} className="!font-normal !mb-0 !mt-2">
                  {userGreeting}
              </Typography.Title>} />
    </>;
});
DashboardCompanyMultipleHeader.displayName = 'DashboardCompanyMultipleHeader';