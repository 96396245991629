import type { ReactElement } from 'react';
import { type SortOrder, TitleWithIcon } from '@/components/structural';
import { Option } from '@/components/structural/form';
import customSearchIcon from '@/icons/asterisk.svg';
import sortByStringAscIcon from '@/icons/sort-alpha-down.svg';
import sortByStringDescIcon from '@/icons/sort-alpha-up.svg';
import sortByOtherAscIcon from '@/icons/sort-down.svg';
import sortByOtherDescIcon from '@/icons/sort-up.svg';
import sortByDateDescIcon from '@/icons/calendar-event.svg';
interface ISortByOptionProps<T> {
  key: keyof T;
  title: string;
  type: 'custom' | 'string' | 'date' | 'other';
  direction: SortOrder;
  icon?: string;
  hide?: boolean;
}
export const SortByOption = <T,>(props: ISortByOptionProps<T>): ReactElement => <Option value={`${props.key as string}+${props.direction}`} key={(props.key as string) + props.direction} column={props.key} direction={props.direction} className={`${props.hide ? 'hidden' : 'flex'}`} data-sentry-element="Option" data-sentry-component="SortByOption" data-sentry-source-file="SortByOption.tsx">
          <TitleWithIcon title={props.title} icon={props.icon || getIcon(props.type, props.direction)} data-sentry-element="TitleWithIcon" data-sentry-source-file="SortByOption.tsx" />
      </Option>;
export const getORSortDirection = <T,>(value: ISortByOptionProps<T>['direction']) => value ? value.includes('asc') ? 'asc' : 'desc' : 'asc';
const getIcon = <T,>(type: ISortByOptionProps<T>['type'], direction: ISortByOptionProps<T>['direction']) => {
  switch (type) {
    case 'string':
      if (direction === 'ascend') {
        return sortByStringAscIcon.src;
      }
      return sortByStringDescIcon.src;
    case 'other':
      if (direction === 'ascend') {
        return sortByOtherAscIcon.src;
      }
      return sortByOtherDescIcon.src;
    case 'date':
      return sortByDateDescIcon.src;
    case 'custom':
      return customSearchIcon.src;
  }
};