import { type FC, memo } from 'react';
import type { IFormStepProps } from '@/components/structural/form';
export const StepEnd: FC<IFormStepProps> = memo(() => {
  return <>
        <div className="grid grid-cols-1 place-items-center w-full mb-4 font-roboto">
            <div className="mb-4">
                Your request to change your registered company office address has now been submitted to Companies House.
            </div>
            <div className="mb-4">
                Any change does not become effective until the form is received and accepted as valid by the Registrar or Companies.
            </div>
            <div>
                Documents delivered to the old address within 14 days of the date of change are still validly served on the company.
            </div>
        </div>
    </>;
});
StepEnd.displayName = 'CompanyChangeRegisteredAddressStepEnd';