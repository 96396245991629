import { forwardRef } from 'react';
import { Link } from '@/components/structural/Link';
export interface IWidgetViewAllLink {
  url: string;
  title?: string;
  className?: string;
}
export const WidgetViewAllLink = forwardRef<HTMLAnchorElement, IWidgetViewAllLink>(({
  url,
  title = 'VIEW ALL',
  className
}, ref) => {
  return <Link ref={ref} to={url} className={`text-info dark:text-info-dark absolute right-7 bottom-4 ${className || ''}`}>
        {title}
    </Link>;
});
WidgetViewAllLink.displayName = 'WidgetViewAllLink';