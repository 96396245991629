import { type FC, memo } from 'react';
import { SVGIcon } from '@/components/structural/images';
import { Typography } from '@/components/structural';
import checkImage from '@/icons/check-circle-fill.svg';
export interface IAuthSignupConfirmSuccessProps {
  className?: string;
}
export const AuthSignupConfirmSuccess: FC<IAuthSignupConfirmSuccessProps> = memo(props => {
  return <>
        <div className={`${props.className}`}>
            <div className="grid place-items-center w-full mt-4 mb-12">
                <SVGIcon src={checkImage.src} className="h-40 w-40 text-success dark:text-success-dark" />

                <Typography.Title level={3} className="mt-4">
                    Success!
                </Typography.Title>

                <Typography.Paragraph className="font-roboto">
                    Your account has been verified
                </Typography.Paragraph>
            </div>
        </div>
    </>;
});
AuthSignupConfirmSuccess.displayName = 'AuthSignupConfirmSuccess';