import { type CSSProperties, type FC, memo, type PropsWithChildren, type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCompaniesMinimal } from '@/store/company/hooks';
import { useCurrentUser } from '@/store/user/hooks';
import { Divider, openModal, routerNavigate, TitleWithIcon } from '@/components/structural';
import { Option, type RefSelectProps, Select } from '@/components/structural/form';
import { searchFilterOption, searchFilterSort } from '@/components/tables';
import { SVGIcon } from '@/components/structural/images';
import { CompanySettingsForm } from '@/features/company/my-company/components';
import type { IORCompanyMinimal } from '@/features/company/shared/models';
import { COMPANY_COLOURS, COMPANY_COLOURS_DARK, VIEW_ALL_COMPANIES_ID } from '@/shared/constants';
import { getArray } from '@/shared/tools';
import twConfig from '@/shared/tailwindConfig';
import addCompanyIcon from '@/icons/plus-circle-fill.svg';
import companySettingsModalIcon from '@/icons/house.svg';
interface ICompanySelectorDropdownProps {
  className?: string;
}
export const CompanySelectorDropdown: FC<PropsWithChildren<ICompanySelectorDropdownProps>> = memo(props => {
  const dispatch = useAppDispatch();
  const {
    settings
  } = useCurrentUser();
  const {
    companies,
    isFetching
  } = useCompaniesMinimal();
  const ref = useRef<RefSelectProps>();
  const [searchValue, setSearchValue] = useState<string>();
  const [dynamicStyle, setDynamicStyle] = useState<CSSProperties>(null);
  const getOptions = (companies: IORCompanyMinimal[], searchValue: string) => {
    const companiesWithNull: IORCompanyMinimal[] = companies.length > 1 ? [...companies, settings?.currentCompanyUuid === VIEW_ALL_COMPANIES_ID && {
      uuid: VIEW_ALL_COMPANIES_ID,
      company_name: 'View All',
      company_number: null,
      next_confirmation_due: null,
      next_accounts_due: null,
      tags: []
    }] : companies;
    return companiesWithNull.filter(Boolean).map((company, i) => {
      if (company.uuid === VIEW_ALL_COMPANIES_ID) return <Option value={VIEW_ALL_COMPANIES_ID} key={VIEW_ALL_COMPANIES_ID} filterByValue={company.company_name} sortByValue={company.company_name} className="!h-0 !min-h-0 !p-0 !m-0 !leading-[0] !overflow-hidden">
                          <TitleWithIcon title={company.company_name} iconBgColour={twConfig.theme.colors.info.DEFAULT} iconBgColourDark={twConfig.theme.colors.info.dark} customIconText="ALL" disableEllipsis />
                      </Option>;
      const colourIndex = i;
      return <Option value={company.uuid} key={company.uuid} filterByValue={company.company_name} sortByValue={company.company_name}>
                            <TitleWithIcon title={company.company_name} highlight={searchValue} iconBgColour={COMPANY_COLOURS[colourIndex % COMPANY_COLOURS.length]} iconBgColourDark={COMPANY_COLOURS_DARK[colourIndex % COMPANY_COLOURS_DARK.length]} disableEllipsis />
                        </Option>;
    }).filter(Boolean);
  };
  const updateCompanyDropdownWidth = (uuid: string) => {
    const selectedCompany = companies?.find(c => c.uuid === uuid);
    if (selectedCompany) {
      setDynamicStyle({
        width: `calc(${selectedCompany.company_name.length}ch + 4.5rem)`
      });
    } else if (uuid === VIEW_ALL_COMPANIES_ID) {
      setDynamicStyle({
        width: `calc(${VIEW_ALL_COMPANIES_ID.length}ch) + 2rem`
      });
    } else {
      setDynamicStyle(null);
    }
  };
  const actionSearch = (value: string) => {
    ref.current?.blur();
    if (value === settings?.currentCompanyUuid) return;
    dispatch(doPatchCurrentUser({
      settings: {
        currentCompanyUuid: value
      }
    }));

    /*if ( stringContainsUUID( Router.asPath ) )
        routerNavigate( '/my-company' );*/
  };
  const actionGoToAddCompany = () => {
    ref.current?.blur();
    routerNavigate('/my-company/onboard');
  };
  const actionOpenCompanySettings = () => {
    ref.current?.blur();
    setTimeout(() => openModal({
      type: 'confirm',
      title: 'Company Settings',
      icon: companySettingsModalIcon.src,
      children: <CompanySettingsForm />
    }), 150);
  };
  const options = getOptions(getArray<typeof companies[number]>(companies), searchValue);
  const maxCompanyNameLength = useMemo(() => Math.max(...getArray<typeof companies[number]>(companies).map(c => c.company_name.length)), [companies]);
  useEffect(() => {
    const updateCompanyDropdownWidth = () => {
      setTimeout(() => {
        const dropdownElement = document.getElementsByClassName('global-company-selector-dropdown')[0] as HTMLDivElement;
        if (!dropdownElement) return;
        const newSize = `calc( ${maxCompanyNameLength}ch + 2.5rem )`;
        dropdownElement.style.width = newSize;
        dropdownElement.style.minWidth = newSize;
      }, 0);
    };
    const selectElement = document.getElementsByClassName('global-company-selector')[0];
    if (!selectElement) return;
    selectElement.addEventListener('click', updateCompanyDropdownWidth);
    return () => {
      selectElement.removeEventListener('click', updateCompanyDropdownWidth);
    };
  }, [maxCompanyNameLength]);
  useEffect(() => {
    if (settings?.currentCompanyUuid) {
      updateCompanyDropdownWidth(settings.currentCompanyUuid);
    }
  }, [settings]);
  return <>
        <Select<unknown, {
      sortByValue?: string;
      filterByValue?: string;
    }> ref={ref} size="middle" showSearch className={`global-company-selector ${props.className} text-primary dark:text-default-dark transition-all`} popupClassName="global-company-selector-dropdown" style={dynamicStyle} onSearch={setSearchValue} searchValue={searchValue} onChange={actionSearch} filterOption={searchFilterOption} filterSort={searchFilterSort} value={companies?.length ? settings?.currentCompanyUuid : null} loading={isFetching} dropdownRender={(menu: ReactNode) => <div className="my-2">
                  {settings?.currentCompanyUuid !== VIEW_ALL_COMPANIES_ID && <>
                      <ExtraListItemWrapperHover>
                          <TitleWithIcon title="Company Settings" onClick={actionOpenCompanySettings} icon=" " className="text-default hover:text-success dark:hover:text-success-dark" disableEllipsis />
                      </ExtraListItemWrapperHover>
                  </>}
                  {options.length > 0 && <>
                      <Divider className="m-0 mt-1 border" />
                      <StyledMenu>{menu}</StyledMenu></>}
                  <Divider className="m-0 mt-1 border" />
                  {companies?.length > 1 && <>
                      <ExtraListItemWrapperHover className="mt-1.5">
                          <TitleWithIcon title="View All" onClick={() => actionSearch(VIEW_ALL_COMPANIES_ID)} customIconText="ALL" className="text-default hover:text-success dark:hover:text-success-dark" disableEllipsis />
                      </ExtraListItemWrapperHover>
                  </>}
                  <ExtraListItemWrapperHover className="mt-1.5">
                      <TitleWithIcon title="Add a Company" onClick={actionGoToAddCompany} icon={<ExtraListItemIcon src={addCompanyIcon.src} />} disableEllipsis />
                  </ExtraListItemWrapperHover>
              </div>}>
            {options}
        </Select>
    </>;
});
CompanySelectorDropdown.displayName = 'CompanySelectorDropdown';
const ExtraListItemWrapper = tw.div`
    flex
    px-3.5
    py-1
`;
const ExtraListItemWrapperHover = tw(ExtraListItemWrapper)`
    hover:cursor-pointer
    hover:text-success
    dark:hover:text-success-dark
`;
const ExtraListItemIcon = tw(SVGIcon)`
    text-success
    dark:text-success-dark
    inline-flex
    !w-8
    !h-6
    -ml-1
`;
const StyledMenu = styled.div`
    & {
        .ant-select-item-option-selected {
            background-color: #fff;
            color: ${twConfig.theme.colors.default.DEFAULT};
            font-weight: normal;
        }

        .ant-select-item-option-selected:hover {
            background-color: ${twConfig.theme.colors.default.lighter};
        }
    }

    .dark & {
        .ant-select-item-option-selected {
            background-color: ${twConfig.theme.colors.background.dark};
            color: ${twConfig.theme.colors.default.dark};
        }

        .ant-select-item-option-selected:hover {
            background-color: ${twConfig.theme.colors.default['hover-light']};
        }
    }
`;