import { type FC } from 'react';
import { Typography } from '@/components/structural';
export interface ISkipForNowProps {
  className?: string;
  textClassName?: string;
  onClick: () => void;
}
export const SkipForNow: FC<ISkipForNowProps> = props => {
  return <div className={`w-full flex justify-end mt-4 ${props.className}`} data-sentry-component="SkipForNow" data-sentry-source-file="SkipForNow.tsx">
        <Typography.Paragraph className={`font-roboto text-info dark:text-info-dark cursor-pointer ${props.textClassName}`} onClick={props.onClick} data-sentry-element="unknown" data-sentry-source-file="SkipForNow.tsx">
            SKIP FOR NOW
        </Typography.Paragraph>
    </div>;
};