import { put, takeEvery } from 'redux-saga/effects';

import { userApi } from '@/store/user';

import { doLogoutClearState } from '@/store/auth/actions';

import { removeCookies } from '@/shared/tools';


function* onLogoutScript()
{
    yield put( userApi.util.resetApiState() );

    removeCookies(
          'orCurrentAccountUuid',
          'orCurrentUserUuid',
          'orCurrentUserName',
          'orCurrentUserEmail',
          'orCurrentUserType',
          'orNewSignupEmail',
          'orNewSignupUserType',
          'orNewSignupFirstName',
          'orNewSignupLastName'
    );

    sessionStorage.removeItem( 'hasLoadedOnce' );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}