import { type FC, memo, type PropsWithChildren } from 'react';
import { Button, type ORButtonType } from '@/components/structural';
export interface IAuthPageFormButtonProps {
  onClick?: () => void;
  buttonType?: ORButtonType;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}
export const AuthPageFormButton: FC<PropsWithChildren<IAuthPageFormButtonProps>> = memo(props => {
  return <Button type={props.buttonType || 'success'} htmlType={props.onClick ? 'button' : 'submit'} className={`w-full h-12 ${props.className || ''}`} loading={props.loading} onClick={props.onClick} disabled={props.disabled}>
        {props.children}
    </Button>;
});
AuthPageFormButton.displayName = 'AuthPageFormButton';