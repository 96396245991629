import { forwardRef, useEffect, useImperativeHandle } from 'react';
import useCreation from 'ahooks/lib/useCreation';
import useReactive from 'ahooks/lib/useReactive';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import moment from 'moment';
import tw from 'tailwind-styled-components';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { DATE_FORMAT_DATE_PICKER, DATE_FORMAT_SERVER } from '@/shared/dateFormats';
import { DatePicker, Radio, RadioGroup } from '@/components/structural/form';
import type { FilterRefType } from '@/components/tables';
import type { ORJSON } from '@/shared/models';
import type { RadioChangeEvent } from 'antd/lib/radio/interface';
export interface IFiltersDateState {
  from?: string;
  to?: string;
  custom?: boolean;
  active?: boolean;
}
export interface IDateFilterProps {
  filtersChanged: (key: string, value: IFiltersDateState) => void;
  title: string;
  attribute: string;
  initialSelectedValues?: string[];
  className?: string;
}
export const DateFilter = forwardRef<FilterRefType<IDateFilterProps>, IDateFilterProps>(({
  filtersChanged,
  title,
  attribute,
  initialSelectedValues = [],
  className
}: IDateFilterProps, ref) => {
  const today = useCreation(() => moment().format(DATE_FORMAT_SERVER), []);
  const eonsAgo = useCreation(() => moment().subtract(365 * 25, 'days').format(DATE_FORMAT_SERVER), []);
  const oneYearAgo = useCreation(() => moment().subtract(365, 'days').format(DATE_FORMAT_SERVER), []);
  const oneMonthAgo = useCreation(() => moment().subtract(30, 'days').format(DATE_FORMAT_SERVER), []);
  const oneWeekAgo = useCreation(() => moment().subtract(7, 'days').format(DATE_FORMAT_SERVER), []);
  const freshState = useCreation(() => ({
    from: eonsAgo,
    to: today,
    custom: false,
    active: false
  }), []);
  const initialState = useCreation(() => ({
    from: initialSelectedValues[0] || eonsAgo,
    to: initialSelectedValues[1] || today,
    custom: initialSelectedValues.length > 0,
    active: initialSelectedValues.length > 0
  }), [initialSelectedValues]);
  const filtersState = useReactive<ORJSON<IFiltersDateState>>({
    [attribute]: initialState
  });
  useImperativeHandle<IDateFilterProps, FilterRefType<IDateFilterProps>>(ref, () => ({
    filtersChanged,
    title,
    attribute,
    clear: () => {
      filtersState[attribute] = freshState;
    }
  }));
  useUpdateEffect(() => {
    filtersChanged(attribute, filtersState[attribute]);
  }, [filtersState[attribute]]);
  useEffect(() => {
    if (initialState.active) {
      filtersState[attribute] = initialState;
    }
  }, [initialState]);
  return <>
        <div className={className}>
            <Text className="mb-2 font-medium">
                {title}
            </Text>
            <div className="flex font-roboto">
                <RadioGroup className="mr-4" onChange={(e: RadioChangeEvent) => filtersState[attribute] = {
          from: e.target.value,
          to: today,
          custom: false,
          active: true
        }} value={!filtersState[attribute]?.custom && filtersState[attribute]?.from}>
                    <Space direction="vertical">
                        <Radio value={oneWeekAgo} className="text-inherit">Last 7 Days</Radio>
                        <Radio value={oneMonthAgo} className="text-inherit">Last Month</Radio>
                        <Radio value={oneYearAgo} className="text-inherit">Last Year</Radio>
                        <Radio value={eonsAgo} className="text-inherit">All</Radio>
                    </Space>
                </RadioGroup>
                <Space direction="vertical">
                    <Radio className="text-inherit" onChange={() => filtersState[attribute] = {
            ...filtersState[attribute],
            custom: true,
            active: true
          }} checked={filtersState[attribute]?.custom}>
                        Custom
                    </Radio>
                    <Text className="text-inherit">Date From:</Text>
                    <DatePicker className="h-[2rem]" onChange={(_, value) => filtersState[attribute] = {
            ...filtersState[attribute],
            from: moment(value, DATE_FORMAT_DATE_PICKER).format(DATE_FORMAT_SERVER),
            active: true
          }} value={filtersState[attribute]?.from && moment(filtersState[attribute].from, DATE_FORMAT_SERVER)} disabled={!filtersState[attribute]?.custom} allowClear={false} />
                    <Text className="text-inherit">Date To:</Text>
                    <DatePicker className="h-[2rem]" onChange={(_, value) => filtersState[attribute] = {
            ...filtersState[attribute],
            to: moment(value, DATE_FORMAT_DATE_PICKER).format(DATE_FORMAT_SERVER),
            active: true
          }} value={filtersState[attribute]?.to && moment(filtersState[attribute].to, DATE_FORMAT_SERVER)} disabled={!filtersState[attribute]?.custom} allowClear={false} />
                </Space>
            </div>
        </div>
    </>;
});
DateFilter.displayName = 'DateFilter';
const Text = tw(Typography.Text)`
    block
    text-primary
        dark:text-primary-dark
`;