import { type FC, memo } from 'react';
import tw from 'tailwind-styled-components';
import { useCurrentCompany } from '@/store/company/hooks';
import { Card, Col, DataLabel, DataValue, Loader, Row, WidgetViewAllLink } from '@/components/structural';
export const CompanyInformationWidget: FC = memo(() => {
  const {
    chProfile,
    isFetching
  } = useCurrentCompany();
  if (isFetching) return <Loader skeleton />;
  return <>
        <Card title="Company Information">
            <Row gutter={12}>
                <Col xs={24} sm={12}>
                    <Label>Registered Address</Label>
                    <Value raw={chProfile?.registered_office_address.address_line_1} />
                    <Value raw={chProfile?.registered_office_address.address_line_2} />
                    <Value raw={chProfile?.registered_office_address.locality} />
                    <Value raw={chProfile?.registered_office_address.postal_code} />
                </Col>
                <Col xs={24} sm={12}>
                    <Label>Incorporation Date</Label>
                    <Value raw={chProfile?.date_of_creation} type="date" />
                    <Label>Company Number</Label>
                    <Value raw={chProfile?.company_number} />
                </Col>
            </Row>
            <WidgetViewAllLink url="/my-company/company-information" title="VIEW" />
        </Card>
    </>;
});
CompanyInformationWidget.displayName = 'CompanyInformationWidget';
const Value = tw(DataValue)`
    !mb-1
    block
` as typeof DataValue;
const Label = tw(DataLabel)`
    block
` as typeof DataLabel;