import { type FC, memo, useCallback, useState } from 'react';
import useMount from 'ahooks/lib/useMount';
import { Form, FormItem, type IFormStepProps, Input, useForm } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';
import { AddressLookup } from '@/components/structural';
export interface IStep1Data extends ORJSON<string> {
  address_line_1: string;
  address_line_2: string;
  locality: string;
  region: string;
  postal_code: string;
}
export const Step1: FC<IFormStepProps<IStep1Data>> = memo(({
  initialData,
  isStepValid,
  setData,
  setIsValid
}) => {
  const [formStep] = useForm();
  const [defaults] = useState<IStep1Data>(initialData || {
    address_line_1: '',
    address_line_2: '',
    locality: '',
    region: '',
    postal_code: ''
  });
  const updateFormData = useCallback(() => {
    setData(formStep.getFieldsValue());
    isStepValid(formStep).then(setIsValid);
  }, [setData, formStep, setIsValid, isStepValid]);
  useMount(() => {
    formStep.setFieldsValue(defaults);
    isStepValid(formStep).then(setIsValid);
  });
  const onAddressSearchSelected = (address: FetchifyAddressLookupResult) => {
    formStep.setFieldsValue({
      address_line_1: address.line_1,
      address_line_2: address.line_2,
      city: address.locality,
      country: address.province_name,
      post_code: address.postal_code
    });
    updateFormData();
  };
  return <>
        <Form form={formStep} className="font-roboto" layout="vertical" requiredMark={false} onValuesChange={updateFormData}>
            <FormItem label="Address Lookup">
                <AddressLookup onSelect={onAddressSearchSelected} />
            </FormItem>

            <FormItem label="Building Name/Number:" name="address_line_1" rules={[{
        required: false,
        message: <></>,
        type: 'string',
        min: 0
      }]}>
                <Input />
            </FormItem>

            <FormItem label="Street:" name="address_line_2" rules={[{
        required: false,
        message: <></>,
        type: 'string',
        min: 0
      }]}>
                <Input />
            </FormItem>

            <FormItem label="Post Town:" name="locality" rules={[{
        required: false,
        message: <></>,
        type: 'string',
        min: 0
      }]}>
                <Input />
            </FormItem>

            <FormItem label="County/Region:" name="region" rules={[{
        required: false,
        message: <></>,
        type: 'string',
        min: 0
      }]}>
                <Input />
            </FormItem>

            <FormItem label="Postcode:" name="postal_code" rules={[{
        required: false,
        message: <></>,
        type: 'string',
        min: 0
      }]}>
                <Input />
            </FormItem>
        </Form>
    </>;
});
Step1.displayName = 'CompanyAddSAILAddressStep1';