import { type FC, memo, type PropsWithChildren } from 'react';
import { Card } from '@/components/structural/Card';
import { ModalTitle } from '@/components/structural/ModalTitle';
export interface ICardContainerProps {
  title: string;
  icon?: string;
}
export const CardContainer: FC<PropsWithChildren<ICardContainerProps>> = memo(({
  title,
  icon,
  children
}) => <div className="grid place-items-center">
          <Card className="p-8 !max-w-[400px] md:!max-w-[500px] lg:!max-w-[650px]">
              <ModalTitle className="mb-4" title={title} icon={icon} />
              {children}
          </Card>
      </div>);
CardContainer.displayName = 'CardContainer';