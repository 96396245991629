import { useEffect, useRef } from 'react';


export const useDeepCompareEffect = ( callback: () => void, deps: unknown[] ) =>
{
    const prevDepsRef = useRef<string | null>( null );

    useEffect( () =>
    {
        // Serialize dependencies for deep comparison
        const serializedDeps = JSON.stringify( deps );

        if ( prevDepsRef.current !== serializedDeps )
        {
            prevDepsRef.current = serializedDeps;
            callback();
        }
    }, [ deps ] );
};