import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { createNotification } from '@/store/notifications/actions';
import { doLoginNoPassword, doSignupConfirmAccountWithCode, doSignupConfirmAccountWithCodeError, doSignupConfirmAccountWithCodeSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural';


function* signupConfirmAccountWithCodeScript( { payload }: ReturnType<typeof doSignupConfirmAccountWithCode> )
{
    try
    {
        const result = yield call( authService.confirmSignup, payload );
        if ( result )
        {
            yield put( doLoginNoPassword( payload ) );
            yield put( doSignupConfirmAccountWithCodeSuccess( { email: payload.email } ) );
        } else
        {
            yield put( createNotification( {
                title: 'Could not log you in automatically. Please use your email and password to log in.',
                type: 'warning'
            } ) );
            yield routerNavigate( '/auth/login' );
        }
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignupConfirmAccountWithCode saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupConfirmAccountWithCodeError( error ) );
    }
}

export function* onSignupConfirmAccountWithCodeSaga()
{
    yield takeEvery( doSignupConfirmAccountWithCode, signupConfirmAccountWithCodeScript );
}