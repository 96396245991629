import { memo } from 'react';
import { Breadcrumb, Typography } from '@/components/structural';
export const CompaniesFilingFormsConfirmationStatementHelpText = memo(() => <>
    <Breadcrumb separator={<span>&gt;</span>} className="mb-1 font-poppins">
        <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>
    </Breadcrumb>
    <div className="font-roboto">
        <Typography.Title level={5} className="font-poppins mt-0">Confirmation Statement</Typography.Title>
        <Typography.Paragraph className="mb-4">
            Every company, including dormant and non trading companies, must file a confirmation statement.
            It confirms that the information Companies House holds about your company is up to date.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            You must file a statement at least once a year, but you may choose to file more often.
        </Typography.Paragraph>
        <Typography.Paragraph className="break-words">
            More information relating to the confirmation statement can be found on the Companies House website&nbsp;
            <a href="https://www.gov.uk/guidance/confirmation-statement-guidance" target="_blank" rel="noreferrer">here</a>&nbsp;
            <span className="break-all">(<a href="https://www.gov.uk/guidance/confirmation-statement-guidance" target="_blank" rel="noreferrer">
                https://www.gov.uk/guidance/being-a-company-director</a>)</span>
        </Typography.Paragraph>
    </div>
</>);
CompaniesFilingFormsConfirmationStatementHelpText.displayName = 'CompaniesFilingFormsConfirmationStatementHelpText';