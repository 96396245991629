import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLogin, doLoginError, doLoginSuccess, setAWSUser, } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural';

import type { IAWSUser } from '@/features/auth/models';


function* loginScript( { payload }: ReturnType<typeof doLogin> )
{
    try
    {
        const user: IAWSUser = yield call( authService.signIn, payload );
        yield commonLoginProcessSuccessScript( user );
    } catch ( error )
    {
        yield commonLoginProcessFailureScript( error );
    }
}

export function* onLoginSaga()
{
    yield takeEvery( doLogin, loginScript );
}


export function* commonLoginProcessSuccessScript( user: IAWSUser )
{
    yield put( setAWSUser( user ) );
    yield put( doLoginSuccess( user ) );
    yield routerNavigate( '/landing' );
}

export function* commonLoginProcessFailureScript( err: unknown )
{
    const error = handleError( err );

    yield put( HTTPErrorOccurred( error ) );
    yield put( doLoginError( error ) );

    processError( 'onLogin saga', error );
}