import { type FC, memo } from 'react';
import { FormFooter } from '@/components/structural/form';
import { Modal, openModal } from '@/components/structural';
export interface ICompanyOnboardModeErrorModalProps {
  className?: string;
}
export const CompanyOnboardModeErrorModal: FC<ICompanyOnboardModeErrorModalProps> = memo(props => {
  return <>
        <div className={`${props.className} text-center mb-4`}>
            Companies House submissions are disabled in onboarding mode - contact&nbsp;
            <a href="mailto:support@oneregistry.co.uk?subject=Enable%20Company%20Live%20Mode">support@oneregistry.co.uk</a>
            &nbsp;to enable live mode
        </div>
        <FormFooter className="!gap-2" onCancel={() => Modal.destroyAll()} action="view" cancelText="CLOSE" />
    </>;
});
export const openCompanyOnboardModeErrorModal = (props: ICompanyOnboardModeErrorModalProps = {}) => {
  openModal({
    type: 'error',
    title: 'Cannot Make Changes',
    centerTitle: true,
    centered: true,
    children: <CompanyOnboardModeErrorModal {...props} />
  });
};
CompanyOnboardModeErrorModal.displayName = 'CompanyOnboardModeErrorModal';