import { type FC, memo } from 'react';
import styled from 'styled-components';
import MenuAntD, { type MenuProps } from 'antd/lib/menu';
import type { ItemType } from 'antd/lib/menu/interface';
import twConfig from '@/shared/tailwindConfig';
export const Menu: FC<MenuProps> = memo(props => {
  return <StyledMenu {...props} />;
});
Menu.displayName = 'Menu';
export type MenuItemType = ItemType;
const StyledMenu = styled(MenuAntD)`
    & {
        &.ant-menu {
            font-size: ${twConfig.theme.fontSize.md};
            height: 100%;
        }

        @media (min-width: ${twConfig.theme.screens.md}) {
            [role="menuitem"], .ant-menu-submenu {
                height: 100%;
            }
        }

        .ant-menu-item.ant-menu-item-active, .ant-menu-submenu.ant-menu-submenu-selected,
        .ant-menu-submenu.ant-menu-submenu-active, .ant-menu-item.ant-menu-item-selected {
            color: ${twConfig.theme.colors.primary.DEFAULT} !important;

            &:hover [role="menuitem"] {
                color: ${twConfig.theme.colors.primary.DEFAULT} !important;
            }
        }

        .ant-menu-item, .ant-menu-submenu-title {
            display: flex !important;
            align-items: center !important;
            font-weight: ${twConfig.theme.fontWeight.normal};
        }

        .ant-menu-horizontal .ant-menu-submenu {
            display: flex !important;
        }

        .ant-menu-inline {
            span[role="img"] {
                display: inline-flex;
                transform: translateY(-0.1rem);
            }
        }

        .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > .ant-menu-submenu::after {
            bottom: 1px;
        }

        .ant-menu-item .ant-menu-item-icon + span,
        .ant-menu-submenu-title .ant-menu-item-icon + span,
        .ant-menu-item .anticon + span,
        .ant-menu-submenu-title .anticon + span {
            margin-left: 0.6rem;
        }

        .ant-menu-root.full-width-item .ant-menu-title-content {
            max-width: initial;
        }
    }
` as typeof MenuAntD;