import { type FC, memo, type PropsWithChildren } from 'react';
import tw from 'tailwind-styled-components';
import { FullScreenOverlay, FullScreenOverlayInner } from '@/components/structural';
import { Image } from '@/components/structural/images';
import { Messages, Notifications } from '@/features/notifications';
import logoImage from '@/images/logo-OR-full.png';
export interface IAppLayoutGuestProps {
  fullScreen?: boolean;
  hideLogo?: boolean;
}
export const AppLayoutGuest: FC<PropsWithChildren<IAppLayoutGuestProps>> = memo(props => {
  if (props.fullScreen) return <>
        <FullScreenOverlay>
            {props.hideLogo ? null : <ORLogo src={logoImage.src} height={38} width={200} alt="logo" />}
            {props.children}
        </FullScreenOverlay>
        <Messages />
        <Notifications />
    </>;
  return <>
        <FullScreenOverlay>
            <FullScreenOverlayInner>
                {props.hideLogo ? null : <ORLogo src={logoImage.src} height={38} width={200} alt="logo" />}
                {props.children}
            </FullScreenOverlayInner>
        </FullScreenOverlay>
        <Messages />
        <Notifications />
    </>;
});
AppLayoutGuest.displayName = 'AppLayoutGuest';
const ORLogo = tw(Image)`
    relative
    w-full
    text-center
`;