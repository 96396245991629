import { type FC, memo, PropsWithChildren } from 'react';
import FormAntD, { type FormProps } from 'antd/lib/form';
import { useForm as useFormAntD } from 'antd/lib/form/Form';
export const useForm = useFormAntD;
export const Form: FC<PropsWithChildren<FormProps>> = memo(props => {
  const {
    layout = 'vertical',
    requiredMark = false,
    validateTrigger = 'onSubmit',
    children,
    ...rest
  } = props;
  return <FormAntD {...rest} layout={layout} requiredMark={requiredMark} validateTrigger={validateTrigger}>
        {children}
    </FormAntD>;
});
Form.displayName = 'Form';