import { call, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLoginNoPassword } from '@/store/auth/actions';

import { commonLoginProcessFailureScript, commonLoginProcessSuccessScript } from './onLogin.saga';

import type { IAWSUser } from '@/features/auth/models';


function* loginNoPasswordScript( { payload }: ReturnType<typeof doLoginNoPassword> )
{
    try
    {
        const user: IAWSUser = yield call( authService.signInNoPassword, payload );
        yield commonLoginProcessSuccessScript( user );
    } catch ( error )
    {
        yield commonLoginProcessFailureScript( error );
    }
}

export function* onLoginNoPasswordSaga()
{
    yield takeEvery( doLoginNoPassword, loginNoPasswordScript );
}