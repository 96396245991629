import { type FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import Skeleton from 'antd/lib/skeleton';
const OuterWrapper = tw.div`
    relative
    w-full
    h-full
    flex
    place-items-center
    place-content-center
`;
const InnerWrapper = tw.div`
    loader
    p-5
    rounded-full
    flex
    space-x-3
`;
const InnerWrapperStyled = styled(InnerWrapper)`
    animation-duration: 1s
`;
const Dot = tw.div`
    w-3
    h-3
    bg-primary
        dark:bg-primary-dark
    rounded-full
    animate-bounce
`;
const Dot1 = styled(Dot)`
    animation-delay: 0.1s
`;
const Dot2 = styled(Dot)`
    animation-delay: 0.3s
`;
const Dot3 = styled(Dot)`
    animation-delay: 0.6s
`;
const FullscreenWrapper = tw.div`
    fixed
    h-screen
    w-screen
    inset-0
    z-50
    bg-white
        dark:bg-default-dark
    flex
    justify-items-center
    items-center
    place-items-center
    transition-opacity
    duration-500
    ease-in-out
`;
export const Loader: FC<{
  skeleton?: boolean;
}> = ({
  skeleton
}) => {
  if (skeleton) return <Skeleton active className="overflow-hidden flex h-full" />;
  return <OuterWrapper data-sentry-element="OuterWrapper" data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
        <InnerWrapperStyled data-sentry-element="InnerWrapperStyled" data-sentry-source-file="Loader.tsx">
            <Dot1 data-sentry-element="Dot1" data-sentry-source-file="Loader.tsx" />
            <Dot2 data-sentry-element="Dot2" data-sentry-source-file="Loader.tsx" />
            <Dot3 data-sentry-element="Dot3" data-sentry-source-file="Loader.tsx" />
        </InnerWrapperStyled>
    </OuterWrapper>;
};
export const LoaderFullscreen: FC<{
  isVisible?: boolean;
}> = ({
  isVisible = true
}) => {
  const [show, setShow] = useState(isVisible);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!isVisible) {
      // Start fade out animation
      timeoutId = setTimeout(() => setShow(false), 500); // Match duration-500 from Tailwind CSS
    } else {
      setShow(true);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible]);
  return show ? <FullscreenWrapper className={`${isVisible ? 'opacity-100' : 'opacity-0'}`} data-sentry-element="FullscreenWrapper" data-sentry-component="LoaderFullscreen" data-sentry-source-file="Loader.tsx">
              <Loader data-sentry-element="Loader" data-sentry-source-file="Loader.tsx" />
          </FullscreenWrapper> : null;
};