import { type FC, memo } from 'react';
import tw from 'tailwind-styled-components';
import { Button, type IORButtonProps } from './Button';
import { SVGIcon } from '@/components/structural/images';
interface IORButtonIconProps extends IORButtonProps {
  src: string;
  iconClasses: string;
}
export const ButtonIcon: FC<IORButtonIconProps> = memo(({
  src,
  iconClasses,
  ...props
}) => {
  return <CustomButton {...props}>
        <SVGIcon src={src} className={iconClasses} />
    </CustomButton>;
});
ButtonIcon.displayName = 'ButtonIcon';
const CustomButton = tw(Button)`    
    px-2
`;