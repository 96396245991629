import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { mapCompany } from '@/features/company/shared/tools';
import { processError } from '@/shared/processError';
import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApiKey } from './service.key';
import { companiesGetSuccess, companiesMinimalGetSuccess } from './actions';
import { doPatchCurrentUser } from '@/store/user/actions';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompany, IORCompanyFromBackend, IORCompanyMainAttributes, IORCompanyMinimal, IORCompanyPostOptions } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const companyAdapter = createEntityAdapter<IORCompany, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

export const companyApi = createApi( {
    reducerPath: companyApiKey,
    tagTypes: [
        'Company',
        'Companies',
        'CompanyMinimal',
        'CompaniesMinimal',
        'ConvertibleLoanNote',
        'ConvertibleLoanNotes',
        'FilingCompanyRegisteredAddress',
        'FilingsCompanyRegisteredAddress',
        'FilingCompanyRegisteredName',
        'FilingsCompanyRegisteredName',
        'Filing',
        'Filings',
        'Fundamentals',
        'OptionIssue',
        'OptionIssues',
        'OptionRound',
        'OptionRounds',
        'ShareClass',
        'ShareClasses',
        'ShareClassShareHistory',
        'ShareClassesShareHistory',
        'ShareClassRedesignation',
        'ShareClassRedesignations',
        'ShareDilution',
        'ShareDilutions',
        'ShareIssue',
        'ShareIssues',
        'ShareRound',
        'ShareRounds',
        'ShareTransfer',
        'ShareTransfers',
        'Shareholding',
        'Shareholdings',
        'ShareholdingsSummary',
        'Stakeholder',
        'Stakeholders',
        'Transaction',
        'Transactions',
        'Note',
        'Notes',
        'ResponsibleParty',
        'ResponsibleParties',
        'Tag',
        'Tags',
        'TagItem',
        'TagItems'
    ],
    baseQuery: baseQuery( {
        baseUrl: '/company', options: {}
    } ),
    refetchOnFocus: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_WINDOW_FOCUS ),
    refetchOnReconnect: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_RECONNECT ),
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getOneCompany: builder.query<IORCompany, string>( {
            query: ( uuid ) => ( {
                url: `/${ uuid }/`, method: 'get', queryParams: {
                    show_rest: true
                }
            } ),
            transformResponse: mapCompany,
            providesTags: ( normalisedResult ) => (
                  providesIds( [ normalisedResult?.uuid ], 'Company' )
            ),
        } ),
        getManyCompanies: builder.query<EntityState<IORCompany, string> & IORResponseMeta, IORGetParameters & { search_only?: boolean }>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: '/', method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Companies', 'Company' )
            ),
            transformResponse: ( data: IORCompanyFromBackend[], meta: IORResponseMetaData ) => ( {
                ...companyAdapter.upsertMany( companyAdapter.getInitialState(), data?.map( mapCompany ) || [] ),
                ...{ meta }
            } ),
            onQueryStarted: async ( arg, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    if ( !arg.query ) api.dispatch( companiesGetSuccess( data ) );

                    if ( data.ids.length === 0 ) return;

                    if ( arg.search_only ) return;

                    /*let currentCompanyUuid = getCookie( { cName: 'orCurrentCompanyUuid' } );
                    const isActiveCompany = !!( currentCompanyUuid && data.ids.find( uuid => uuid === currentCompanyUuid ) );

                    if ( !isActiveCompany && currentCompanyUuid !== VIEW_ALL_COMPANIES_ID )
                    {
                        currentCompanyUuid = data.ids[ 0 ] as string;
                        setCookie( { cName: 'orCurrentCompanyUuid', cValue: currentCompanyUuid } );

                        api.dispatch( doPatchCurrentUser( {
                            settings: {
                                currentCompanyUuid
                            }
                        } ) );
                    }*/
                } catch ( error )
                {
                    processError( 'companyApi Service', error );
                }
            }
        } ),
        getManyCompaniesMinimal: builder.query<IORCompanyMinimal[], IORGetParameters & { search_only?: boolean }>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: '/allcompany/', overrideUrl: true, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( data ) => (
                  providesList( data?.map( c => c.uuid ) || [], 'CompaniesMinimal', 'CompanyMinimal' )
            ),
            onQueryStarted: async ( arg, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    if ( !arg.query ) api.dispatch( companiesMinimalGetSuccess( data ) );

                    if ( data.length === 0 ) return;

                    if ( arg.search_only ) return;

                    /*let currentCompanyUuid = getCookie( { cName: 'orCurrentCompanyUuid' } );
                    const isActiveCompany = !!( currentCompanyUuid && data.ids.find( uuid => uuid === currentCompanyUuid ) );

                    if ( !isActiveCompany && currentCompanyUuid !== VIEW_ALL_COMPANIES_ID )
                    {
                        currentCompanyUuid = data.ids[ 0 ] as string;
                        setCookie( { cName: 'orCurrentCompanyUuid', cValue: currentCompanyUuid } );

                        api.dispatch( doPatchCurrentUser( {
                            settings: {
                                currentCompanyUuid
                            }
                        } ) );
                    }*/
                } catch ( error )
                {
                    processError( 'companyApi Service', error );
                }
            }
        } ),
        patchCompany: builder.mutation<IORCompany, { uuid: string, data: Partial<IORCompanyMainAttributes> }>( {
            queryFn: async ( { uuid, data = {} }, queryApi, _extraOptions, baseQuery ) =>
            {
                const companySelector = companyApi.endpoints.getOneCompany.select( uuid );
                // @ts-expect-error
                const companyData = companySelector( queryApi.getState() ).data;

                if ( companyData && data.json_settings )
                {
                    data = {
                        ...data,
                        json_settings: {
                            ...companyData.json_settings,
                            ...data.json_settings,
                        }
                    };
                }

                const result = await baseQuery( {
                    url: `/${ uuid }/`, method: 'patch', data, extraHeaders: {
                        'Content-Type': 'multipart/form-data'
                    }
                } );

                if ( !result || result.error )
                {
                    return {
                        error: result?.error
                    };
                }

                return {
                    data: result.data as IORCompany,
                    meta: result.meta
                };
            },
            invalidatesTags: ( _, __, attrs ) => ( [
                ...providesIds( [ attrs.uuid ], 'Company' ),
                ...providesIds( [ attrs.uuid ], 'CompanyMinimal' ),
            ] )
        } ),
        postCompany: builder.mutation<IORCompany, Partial<IORCompanyPostOptions>>( {
            query: ( attrs ) => ( {
                url: '/', method: 'post', data: attrs, extraHeaders: {
                    'Content-Type': 'multipart/form-data'
                }, queryParams: {
                    show_rest: true
                }
            } ),
            transformResponse: mapCompany,
            invalidatesTags: () => ( [
                ...providesList( [], 'Companies' ),
                ...providesList( [], 'CompaniesMinimal' ),
            ] ),
            onQueryStarted: async ( arg, api ) =>
            {
                try
                {
                    const { data } = await api.queryFulfilled;

                    api.dispatch( doPatchCurrentUser( {
                        settings: {
                            currentCompanyUuid: data.uuid
                        }
                    } ) );
                } catch ( error )
                {
                    processError( 'companyApi Service', error );
                }
            }
        } ),
        triggerCompanySync: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ uuid }/refresh/`, method: 'post'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesIds( [ uuid ], 'Company' ),
                ...providesIds( [ uuid ], 'CompanyMinimal' ),
            ] )
        } ),
        deleteCompany: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ uuid }/`, method: 'delete'
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'Companies' ),
                ...providesList( [], 'CompaniesMinimal' ),
            ] )
        } ),
    } ),
} );

export const companyApiReducer = companyApi.reducer;
// export const companyApiReducer = configureReduxPersist<ReturnType<typeof companyApi.reducer>>( companyApi.reducerPath, companyApi.reducer );

export const {
    useGetOneCompanyQuery,
    useLazyGetOneCompanyQuery,
    useGetManyCompaniesQuery,
    useLazyGetManyCompaniesQuery,
    useGetManyCompaniesMinimalQuery,
    useLazyGetManyCompaniesMinimalQuery,
    usePatchCompanyMutation,
    usePostCompanyMutation,
    useTriggerCompanySyncMutation,
    useDeleteCompanyMutation,
    usePrefetch,
    endpoints: companyApiEndpoints,
} = companyApi;
