import { memo } from 'react';
import { Col, Row } from '@/components/structural';

// import { createAlert } from '@/store/notifications/actions';
import { useAlerts } from '@/store/notifications/hooks';
// import { useAppDispatch } from '@/store/hooks';
import { Alerts } from '@/features/notifications';
import { AccountsWidget, CompanyInformationWidget, ConfirmationStatementWidget, FilingHistoryWidget, OfficersWidget, PSCsWidget, StatutoryRegistersWidget } from '@/features/company/my-company/widgets';
export const DashboardCompany = memo(() => {
  // const dispatchAction = useAppDispatch();
  const {
    alerts
  } = useAlerts('company-dashboard');

  /*useEffect( () =>
  {
      if ( alerts.length > 0 )
      { return; }
       dispatchAction( createAlert( {
          type: 'success',
          title: 'Success',
          content: 'Your registered address change has been accepted',
          channel: 'company-dashboard',
          onClickNavigateTo: '/my-company/filings-log'
      } ) );
      dispatchAction( createAlert( {
          type: 'warning',
          title: 'Draft',
          content: 'You have a transaction in draft',
          channel: 'company-dashboard',
          onClickNavigateTo: '/my-company/filings-log'
      } ) );
      dispatchAction( createAlert( {
          type: 'error',
          title: 'Rejected',
          content: 'Your change company name request has been rejected',
          channel: 'company-dashboard',
          onClickNavigateTo: '/my-company/filings-log'
      } ) );
      dispatchAction( createAlert( {
          type: 'info',
          title: 'Pending',
          content: 'Your changes to Director, Anthony Jenking are pending',
          channel: 'company-dashboard',
          onClickNavigateTo: '/my-company/filings-log'
      } ) );
  }, [ dispatchAction ] );*/

  return <>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    {alerts.length > 0 && <Col span={24} xxl={12}>
                              <Row gutter={[16, 16]} className="mb-4">
                                  <Col span={24}>
                                      <Alerts channel="company-dashboard" max={5} />
                                  </Col>
                              </Row>
                          </Col>}
                    <Col span={24} xxl={alerts.length > 0 ? 12 : 24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24} sm={12}>
                                <ConfirmationStatementWidget />
                            </Col>
                            <Col span={24} sm={12}>
                                <StatutoryRegistersWidget />
                            </Col>
                            <Col span={24} sm={12}>
                                <AccountsWidget />
                            </Col>
                            <Col span={24} sm={12}>
                                <CompanyInformationWidget />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col span={24} lg={12} xxl={8}>
                        <PSCsWidget />
                    </Col>
                    <Col span={24} lg={12} xxl={8}>
                        <OfficersWidget />
                    </Col>
                    <Col span={24} lg={12} xxl={8}>
                        <FilingHistoryWidget maxToShow={5} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;
});
DashboardCompany.displayName = 'DashboardCompany';