import { type FC, memo, useEffect, useMemo, useRef } from 'react';
import { Input } from '@/components/structural/form';
import { processError } from '@/shared/processError';
import twConfig from '@/shared/tailwindConfig';
export interface IAddressLookupProps {
  className?: string;
  onSelect: (address: FetchifyAddressLookupResult) => void;
  defaultCountry?: string;
}

/**
 * Fetchify Test PostCodes:
 * EC1A 1BB: London
 * M1 1AE: Manchester
 * B33 8TH: Birmingham
 * CR2 6XH: Croydon
 * DN55 1PT: Doncaster
 */

export const AddressLookup: FC<IAddressLookupProps> = memo(props => {
  const fetchifyRef = useRef(null);
  const initialised = useRef(false);
  const domElId = useMemo(() => 'address_search', []);
  useEffect(() => {
    if (initialised.current) return;
    try {
      fetchifyRef.current = new clickToAddress({
        accessToken: process.env.NEXT_PUBLIC_FETCHIFY_KEY,
        dom: {
          search: domElId
        },
        domMode: 'id',
        defaultCountry: 'gbr',
        disableAutoSearch: false,
        transliterate: true,
        showLogo: false,
        gfxMode: 1,
        placeholders: true,
        onResultSelected: (_c2a, _elements, address) => {
          props.onSelect?.(address);
          setTimeout(() => {
            (document.getElementById(domElId) as HTMLInputElement).value = '';
          }, 100);
        },
        style: {
          ambient: 'light',
          accent: twConfig.theme.colors.primary.DEFAULT
        }
      });
      initialised.current = true;
    } catch (e) {
      processError('AddressLookup Component', e);
    }
    const timeout = setTimeout(() => {
      try {
        fetchifyRef.current?.selectCountry('gbr');
      } catch (e) {
        processError('AddressLookup Component', e);
      }
    }, 1500);
    return () => {
      clearTimeout(timeout);
      document.getElementById('cc_c2a')?.remove();
    };
  }, []);
  useEffect(() => {
    if (props.defaultCountry) {
      try {
        fetchifyRef.current?.selectCountry(props.defaultCountry);
      } catch (e) {
        processError('AddressLookup Component', e);
      }
    }
  }, [props.defaultCountry]);
  return <Input id={domElId} className={props.className} />;
});
AddressLookup.displayName = 'AddressLookup';