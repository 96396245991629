import { createApi } from '@reduxjs/toolkit/query/react';
import { type EntityState } from '@reduxjs/toolkit';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { extractUuidFromUrl } from '@/shared/tools';
import { providesIds, providesList } from '@/store/_rtk-query';

import { DEFAULT_PER_PAGE } from '@/shared/constants';
import { accountAdapter, accountAuditEntryAdapter } from './adapters';
import { accountApiKey } from './service.key';

import { mapUser } from '@/store/user';

import type { IORAccount, IORAccountAuditEntry, IORAccountMainAttributes } from '@/features/account/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const mapAccount = ( e: IORAccount ) => ( {
    ...e,
    owner: extractUuidFromUrl( e.owner ),
    users: e.users.map( mapUser )
} );

export const accountApi = createApi( {
    reducerPath: accountApiKey,
    tagTypes: [
        'Account',
        'Accounts',
        'AccountAuditEntry',
        'AccountAuditEntries',
    ],
    baseQuery: baseQuery( {
        baseUrl: '/account',
    } ),
    refetchOnFocus: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_WINDOW_FOCUS ),
    refetchOnReconnect: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_RECONNECT ),
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getOneAccount: builder.query<IORAccount, string>( {
            query: ( uuid ) => ( {
                url: `/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Account' )
            ),
            transformResponse: mapAccount,
        } ),
        getManyAccounts: builder.query<EntityState<IORAccount, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: '/', method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( result ) => (
                  providesList( result?.ids, 'Accounts', 'Account' )
            ),
            transformResponse: ( data: IORAccount[], meta: IORResponseMetaData ) => ( {
                ...accountAdapter.upsertMany( accountAdapter.getInitialState(), data.map( mapAccount ) ),
                ...{ meta }
            } ),
        } ),
        patchAccount: builder.mutation<IORAccount, { uuid: string, data: Partial<IORAccountMainAttributes> }>( {
            query: ( attrs ) => ( {
                url: `/${ attrs.uuid }`, method: 'patch', data: attrs.data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesList( [ uuid ], 'Accounts', 'Account' )
            )
        } ),
        getManyAccountAuditEntries: builder.query<EntityState<IORAccountAuditEntry, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined } = {} ) => ( {
                url: `/audits/requests/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                }
            } ),
            providesTags: ( result ) => (
                  providesList( result?.ids, 'AccountAuditEntries', 'AccountAuditEntry' )
            ),
            transformResponse: ( data: IORAccountAuditEntry[], meta: IORResponseMetaData ) => ( {
                ...accountAuditEntryAdapter.upsertMany( accountAuditEntryAdapter.getInitialState(), data ),
                ...{ meta }
            } ),
        } ),
    } ),
} );

export const accountApiReducer = accountApi.reducer;
// export const userApiReducer = configureReduxPersist<ReturnType<typeof userApi.reducer>>( userApi.reducerPath, userApi.reducer );

export const {
    useGetOneAccountQuery,
    useGetManyAccountsQuery,
    usePatchAccountMutation,
    useGetManyAccountAuditEntriesQuery,
    useLazyGetManyAccountAuditEntriesQuery,
    usePrefetch
} = accountApi;
