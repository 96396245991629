import { type FC, type FocusEvent, memo, useCallback, useEffect, useState } from 'react';
import moment, { type Moment } from 'moment';
import type { DatePickerProps } from 'antd/lib/date-picker';
import DatePickerAntd from 'antd/lib/date-picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { isDate } from '@/shared/tools';
import { DATE_FORMAT_DATE_PICKER } from '@/shared/dateFormats';
const MomentDatePickerAntD = DatePickerAntd.generatePicker<Moment>(momentGenerateConfig);
export type DatePickerCustomProps = ReturnType<typeof MomentDatePickerAntD>['props'];
export const DatePicker: FC<DatePickerCustomProps> = memo(props => {
  const {
    onBlur,
    format = DATE_FORMAT_DATE_PICKER as DatePickerProps['format'],
    onChange,
    className = '',
    size = 'large',
    ...rest
  } = props;
  const internalClassName = `${className.includes('w-') ? '' : 'w-full '}${className}`;
  const [value, setValue] = useState(props.value);
  const onChangeLocal = useCallback((newMomentValue: Moment, dateString: string) => {
    setValue(newMomentValue);
    onChange?.(newMomentValue, dateString);
  }, [onChange]);
  const onBlurLocal = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue) return;
    const newMomentValue = moment(newValue, format as string);
    if (isDate(newMomentValue)) {
      setValue(newMomentValue);
    }
    onBlur?.(e);
    onChangeLocal(newMomentValue, newValue);
  }, [onBlur, onChangeLocal]);
  useEffect(() => {
    if (props.value) {
      let newValue: Moment | undefined;
      // Check if props.value is already a Moment object
      if (moment.isMoment(props.value)) {
        newValue = props.value;
      } else {
        // Attempt to create a Moment object without specifying a format
        newValue = moment(props.value);
        // If the above attempt is not valid and a specific format is provided, try with the format
        if (!newValue.isValid() && format) {
          newValue = moment(props.value, format as string);
        }
        // Check if the created Moment object is valid
        if (!newValue.isValid()) {
          // Handle invalid date, e.g., by setting to undefined or logging an error
          newValue = undefined;
        }
      }
      setValue(newValue);
    } else {
      setValue(undefined);
    }
  }, [props.value, format]);
  return <DatePickerAntd {...rest} className={internalClassName} format={format} onBlur={onBlurLocal} onChange={onChangeLocal} value={value} size={size} />;
});
DatePicker.displayName = 'DatePicker';