import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { mapPortfolioResponse } from '@/features/portfolio/tools/mapPortfolioResponse';
import { providesIds } from '@/store/_rtk-query';

import { portfolioApiKey } from './service.key';

import type { IORCompanyPortfolioResponseData } from '@/features/portfolio/models';


export const portfolioApi = createApi( {
    reducerPath: portfolioApiKey,
    tagTypes: [
        'PortfolioEntity',
    ],
    baseQuery: baseQuery( {
        baseUrl: '/portfolio', options: {}
    } ),
    refetchOnFocus: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_WINDOW_FOCUS ),
    refetchOnReconnect: !!Number( process.env.NEXT_PUBLIC_REFETCH_DATA_ON_RECONNECT ),
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getOnePortfolioEntity: builder.query<IORCompanyPortfolioResponseData, void>( {
            query: () => ( {
                url: '/', method: 'get'
            } ),
            transformResponse: mapPortfolioResponse,
            providesTags: ( normalisedResult ) => (
                  providesIds( [ normalisedResult?.all_company_portfolio_data?.uuid ].filter( Boolean ), 'PortfolioEntity' )
            ),
        } ),
    } ),
} );

export const portfolioApiReducer = portfolioApi.reducer;
// export const portfolioApiReducer = configureReduxPersist<ReturnType<typeof portfolioApi.reducer>>( portfolioApi.reducerPath, portfolioApi.reducer );

export const {
    useGetOnePortfolioEntityQuery,
    useLazyGetOnePortfolioEntityQuery,
    usePrefetch,
    endpoints: portfolioApiEndpoints,
} = portfolioApi;
