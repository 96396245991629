import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { createNotification } from '@/store/notifications/actions';
import { doLoginNoPassword, doSignupWithoutConfirmationEmail, doSignupWithoutConfirmationEmailError, doSignupWithoutConfirmationEmailSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { SignUpOutput } from '@aws-amplify/auth/dist/esm/providers/cognito/types';


function* signupWithoutConfirmationEmailScript( { payload }: ReturnType<typeof doSignupWithoutConfirmationEmail> )
{
    try
    {
        const result: SignUpOutput = yield call( authService.signUpNoConfirm, payload );
        const resultStr = JSON.stringify( result ).toLowerCase();

        if ( resultStr.includes( 'exception' ) && !resultStr.includes( 'lambda' ) )
        {
            yield put( createNotification( {
                title: 'This account already exists. Please login or reset your password instead.',
                type: 'error'
            } ) );

            return;
        }

        yield put( doLoginNoPassword( payload ) );
        yield put( doSignupWithoutConfirmationEmailSuccess( payload ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignupWithoutConfirmationEmail saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupWithoutConfirmationEmailError( error ) );
    }
}

export function* onSignupWithoutConfirmationEmailSaga()
{
    yield takeEvery( doSignupWithoutConfirmationEmail, signupWithoutConfirmationEmailScript );
}