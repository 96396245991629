import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useAuth } from '@/store/auth/hooks';

import { accountAdapter } from '@/store/account/adapters';
import { accountSelectors } from '@/store/account/selectors';
import { useGetManyAccountsQuery, useGetOneAccountQuery } from '@/store/account';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useAccounts = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useAuth();
    const { data, isFetching, isLoading, error, status } = useGetManyAccountsQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const accounts = accountSelectors.selectAll(
          data ||
          accountAdapter.getInitialState()
    );

    accounts.sort( ( a, b ) => compare( a.created_at, b.created_at ) );

    const currentAccountUuid = getCookie( { cName: 'orCurrentAccountUuid' } );
    const currentAccount = accounts.find( account => account.uuid === currentAccountUuid );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        accounts,
        currentAccount,
        meta,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};

export const useAccount = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useAuth();
    const { data: account, isFetching, isLoading, error, status } = useGetOneAccountQuery( uuid, {
        skip: skip || !awsUser || !uuid
    } );

    return useMemo( () => ( {
        account,
        isLoading,
        isFetching,
        error,
        status
    } ), [ account, isFetching, isLoading, error, status ] );
};