import NextLink from 'next/link';
import Router, { type NextRouter, type SingletonRouter } from 'next/router';
import { type AnchorHTMLAttributes, forwardRef } from 'react';
import { type UrlObject } from 'url';
import { processError } from '@/shared/processError';
interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to?: string;
  uuid?: string;
  newPage?: boolean;
}
export const Link = forwardRef<HTMLAnchorElement, IProps>(({
  to,
  uuid,
  newPage,
  ...props
}, ref) => {
  const target = newPage ? '_blank' : undefined;
  const href = uuid ? `${to}/${uuid}` : to;
  return <NextLink ref={ref} href={href} target={target} {...props} />;
});
Link.displayName = 'Link';
export const routerNavigate = (link: string | UrlObject, {
  replace = false,
  useWindowLocation = false,
  router = Router
}: {
  replace?: boolean;
  useWindowLocation?: boolean;
  router?: SingletonRouter | NextRouter;
} = {}) => {
  if (useWindowLocation) {
    return new Promise<boolean>((resolve, reject) => {
      if (typeof link === 'string') {
        if (replace) {
          window.location.replace(link);
        } else {
          window.location.assign(link);
        }
        resolve(true);
      } else {
        const e = new Error('Link must be string when using window.location');
        reject(e);
        processError('Navigation Error: ', e);
      }
    });
  } else {
    if (replace) {
      return router.replace(link).catch(e => processError('Navigation Error: ', e));
    } else {
      return router.push(link).catch(e => processError('Navigation Error: ', e));
    }
  }
};