import { type FC, forwardRef, memo, type Ref } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import InputAntd, { type InputProps, type InputRef as InputRefAntD, type PasswordProps, type TextAreaProps } from 'antd/lib/input';
import InputNumberAntd, { type InputNumberProps } from 'antd/lib/input-number';
import { inputNumberFormatter, inputNumberParser, inputWholeNumberFormatter } from '@/components/structural/form/inputNumberTools';
export const InputNumber: FC<InputNumberProps & {
  wholeNumbers?: boolean;
} & {
  ref?: Ref<InputRefAntD>;
}> = memo(forwardRef((props, ref) => {
  const {
    wholeNumbers = false,
    placeholder = wholeNumbers ? '0' : '0.00',
    size = 'large',
    parser = inputNumberParser,
    formatter = wholeNumbers ? inputWholeNumberFormatter : inputNumberFormatter,
    controls = false,
    className = '',
    ...rest
  } = props;
  const internalClassName = `${className.includes('w-') ? '' : 'w-full '}${className}`;
  return <InputNumberAntd {...rest}
  // @ts-expect-error
  ref={ref} className={internalClassName} placeholder={placeholder} size={size} controls={controls} parser={parser} formatter={formatter} />;
}));
InputNumber.displayName = 'InputNumber';
const InputTypeNumberNoArrowsStyle = tw(InputNumber)`
    text-center
`;
export const InputTypeNumberNoArrows = styled(InputTypeNumberNoArrowsStyle)`
    & {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
`;
export const Input: FC<InputProps> = memo(props => {
  const {
    size = 'large',
    className = '',
    placeholder = '...',
    ...rest
  } = props;
  const internalClassName = `${className.includes('w-') ? '' : 'w-full '}${className}`;
  return <StyledInput {...rest} className={internalClassName} size={size} placeholder={placeholder} />;
});
Input.displayName = 'Input';
const StyledInput = styled(InputAntd)`
`;
export type InputRef = InputRefAntD;
export const InputPassword: FC<PasswordProps> = memo(props => {
  const {
    size = 'large',
    placeholder = '...',
    ...rest
  } = props;
  return <InputAntd.Password {...rest} size={size} placeholder={placeholder} />;
});
InputPassword.displayName = 'InputPassword';
export const InputTextArea: FC<TextAreaProps> = memo(props => {
  const {
    size = 'large',
    placeholder = '...',
    ...rest
  } = props;
  return <InputAntd.TextArea {...rest} size={size} placeholder={placeholder} />;
});
InputTextArea.displayName = 'InputTextArea';