import { handleInputBoxCodePaste } from './handleInputBoxCodePaste';

import { isNumeric } from '@/shared/tools';
import type { InputRef } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';


export const setInputBoxCodeDigit = ( digit: string, position: number, codeState: ORJSON<string>, refs: InputRef[], keyCode = '' ) =>
{
    if ( !isNumeric( digit ) )
    {
        if ( [ 'Backspace' ].includes( keyCode ) )
        {
            codeState[ position ] = '';
        }

        if ( [ 'Backspace', 'ArrowLeft' ].includes( keyCode ) )
        {
            if ( position !== 0 )
            {
                refs[ position - 1 ].focus();
            }
        }

        if ( [ 'ArrowRight' ].includes( keyCode ) )
        {
            if ( position !== refs.length - 1 )
            {
                refs[ position + 1 ].focus();
            }
        }

        return;
    }

    if ( digit.length === 2 )
    {
        const digits = digit.split( '' );

        setInputBoxCodeDigit( digits[ 0 ], position, codeState, refs );

        if ( position !== Object.keys( codeState ).length - 1 )
        {
            setInputBoxCodeDigit( digits[ 1 ], position + 1, codeState, refs );
        }

        return;
    }

    if ( digit.length > 1 )
    {
        handleInputBoxCodePaste( digit, codeState, refs );
        return;
    }

    codeState[ position ] = digit;

    if ( position !== Object.values( codeState ).length - 1 )
    {
        refs[ position + 1 ].focus();
    } else
    {
        refs[ position ].blur();
    }
};