import { type FC, memo } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { doLogin, doLoginInvited, doLoginInvitedError } from '@/store/auth/actions';
import { AuthPageFormButton } from '@/features/auth/components';
import { Form, FormItem, Input, InputPassword, useForm } from '@/components/structural/form';
import { SVGIcon } from '@/components/structural/images';
import { useDeepCompareEffect } from '@/shared/hooks';
import lockIcon from '@/icons/lock-fill.svg';
export interface IAuthLoginFormProps {
  className?: string;
  invited?: boolean;
  initialData?: {
    email?: string;
    invite_code_valid: boolean;
  };
}
export const AuthLoginForm: FC<IAuthLoginFormProps> = memo(props => {
  const dispatchAction = useAppDispatch();
  const [form] = useForm();
  const process = (values: {
    email: string;
    password: string;
    invite_code?: string;
  }) => {
    if (props.invited && !props.initialData?.invite_code_valid) {
      dispatchAction(doLoginInvitedError({
        code: '555',
        message: 'The invite code is either missing or invalid.',
        name: 'Invite Code Invalid'
      }));
      return;
    }
    const data = {
      email: values.email.toLocaleLowerCase().trim(),
      password: values.password
    };
    if (props.invited) {
      dispatchAction(doLoginInvited(data));
    } else {
      dispatchAction(doLogin(data));
    }
  };
  useDeepCompareEffect(() => {
    form.setFieldsValue(props.initialData);
  }, [props.initialData]);
  return <>
        <Form name="auth" form={form} onFinish={process} className={props.className}>
            <FormItem label="Email" name="email" rules={[{
        required: true,
        message: 'Please provide your email!',
        type: 'email'
      }]}>
                <Input />
            </FormItem>

            <FormItem label="Password" name="password" rules={[{
        required: true,
        message: 'Please provide your password!'
      }]}>
                <InputPassword prefix={<SVGIcon {...lockIcon} />} />
            </FormItem>

            <FormItem wrapperCol={{
        span: 24
      }}>
                <AuthPageFormButton>
                    LOG IN
                </AuthPageFormButton>
            </FormItem>
        </Form>
    </>;
});
AuthLoginForm.displayName = 'AuthLoginForm';