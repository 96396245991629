import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doLoginInvited, doLoginInvitedError, doLoginInvitedSuccess, setAWSUser, } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { setCookie } from '@/shared/tools';

import type { IAWSUser } from '@/features/auth/models';


function* loginScript( { payload }: ReturnType<typeof doLoginInvited> )
{
    try
    {
        const user: IAWSUser = yield call( authService.signIn, payload );
        setCookie( { cName: 'orCurrentUserType', cValue: 'investor' } );
        yield commonLoginProcessSuccessScript( user );
    } catch ( error )
    {
        yield commonLoginProcessFailureScript( error );
    }
}

export function* onLoginInvitedSaga()
{
    yield takeEvery( doLoginInvited, loginScript );
}


export function* commonLoginProcessSuccessScript( user: IAWSUser )
{
    yield put( setAWSUser( user ) );
    yield put( doLoginInvitedSuccess( user ) );
}

export function* commonLoginProcessFailureScript( err: unknown )
{
    const error = handleError( err );

    // If the error is not a validation error, log it
    if ( error.code !== '400' )
    {
        processError( 'onLoginInvited saga', error );
    }

    yield put( HTTPErrorOccurred( error ) );
    yield put( doLoginInvitedError( error ) );
}