import { type FC, memo, type ReactElement, type ReactNode } from 'react';
import he from 'he';
import type { Moment, MomentInput } from 'moment';
import Progress from 'antd/lib/progress';
import { SVGIcon } from '@/components/structural/images';
import { TextWithCutoff } from '@/components/structural/TextWithCutoff';
import { addNumberThousandsSeparators, normaliseDate } from '@/shared/tools';
import { DATE_FORMAT, DATE_FORMAT_NO_DAY, DATE_TIME_FORMAT, DATE_TIME_FORMAT_SERVER, TIME_FORMAT } from '@/shared/dateFormats';
import yesIcon from '@/icons/check.svg';
import noIcon from '@/icons/x.svg';
import twConfig from '@/shared/tailwindConfig';
export type DataFormatType = 'string' | 'number' | 'boolean' | 'boolean-text' | 'percentage' | 'currency' | 'email' | 'date' | 'dateTime' | 'time' | 'dateNoDay' | 'company' | 'json' | 'element' | 'pill';
export interface IDataFormatProps {
  raw: string | number | boolean | Moment | MomentInput | ReactNode | unknown;
  type?: DataFormatType;
  hidePercentageBar?: boolean;
  barColour?: string;
  currency?: string;
  keepDecimals?: boolean;
  minDecimals?: number;
  maxDecimals?: number;
  inline?: boolean;
  link?: boolean;
  className?: string;
  breakTextOn?: 'words' | 'all' | 'none';
  booleanIcons?: {
    true: ReactNode;
    false: ReactNode;
  };
  onClick?: () => void;
  disableEllipsis?: boolean;
  maxCharsCutOff?: number;
}
export const DataFormat: FC<IDataFormatProps> = memo(({
  raw,
  type = 'string',
  hidePercentageBar = false,
  barColour = twConfig.theme.colors.success.DEFAULT,
  currency = `&pound;`,
  keepDecimals = false,
  minDecimals = 2,
  maxDecimals = 2,
  inline = false,
  link = false,
  className = 'text-default',
  breakTextOn = 'none',
  booleanIcons,
  onClick,
  disableEllipsis,
  maxCharsCutOff
}) => {
  if (raw === undefined || raw === null) return null;
  let data: IDataFormatProps['raw'] | ReactElement = raw;
  let cName: 'string' | 'boolean' | 'number' = 'string';
  let extraClassNames = '';
  let isLink = link;
  const breakClassName = breakTextOn === 'words' ? 'break-words' : breakTextOn === 'all' ? 'break-all' : breakTextOn === 'none' ? 'break-normal whitespace-nowrap' : 'break-words whitespace-normal';
  if (type === 'pill') {
    return <>
            <div className={`table-cell-data string ${inline ? '!inline' : ''}`} onClick={onClick}>
                <div className={`${breakClassName} px-4 py-2 rounded-lg text-white dark:text-default bg-background-light dark:bg-background-dark flex place-items-center place-content-center text-center ${className}`}>
                    <TextWithCutoff disableEllipsis={disableEllipsis} maxCharsCutOff={maxCharsCutOff}>
                        {data as ReactNode}
                    </TextWithCutoff>
                </div>
            </div>
        </>;
  }
  if (['number', 'percentage', 'currency'].includes(type)) {
    data = !isNaN(Number(data)) ? addNumberThousandsSeparators({
      num: raw,
      keepDecimals,
      minDecimals,
      maxDecimals
    }) : 0;
    cName = 'number';
  } else if (type === 'date') {
    data = data ? normaliseDate(data as MomentInput, DATE_FORMAT) : '';
  } else if (type === 'dateTime') {
    data = data ? normaliseDate(data as MomentInput, DATE_TIME_FORMAT, DATE_TIME_FORMAT_SERVER) : '';
  } else if (type === 'time') {
    data = data ? normaliseDate(data as MomentInput, TIME_FORMAT, DATE_TIME_FORMAT_SERVER) : '';
  } else if (type === 'dateNoDay') {
    data = data ? normaliseDate(data as MomentInput, DATE_FORMAT_NO_DAY) : '';
  } else if (type === 'boolean') {
    if (data) {
      if (booleanIcons) {
        data = booleanIcons.true;
      } else {
        data = <SVGIcon src={yesIcon.src} className="!h-6 !w-6 text-success dark:text-success-dark" />;
      }
    } else {
      if (booleanIcons) {
        data = booleanIcons.false;
      } else {
        data = <SVGIcon src={noIcon.src} className="!h-6 !w-6 text-danger dark:text-danger-dark" />;
      }
    }
    cName = 'boolean';
  } else if (type === 'boolean-text') {
    if (data) {
      data = 'Yes';
    } else {
      data = 'No';
    }
    cName = 'boolean';
  } else if (type === 'company') {
    data = `${data['name'] || data['company_name']} (${data['companies_house_number'] || data['company_number']})`;
  } else if (type === 'json') {
    data = JSON.stringify(data, null, 2);
  } else if (type === 'email') {
    isLink = true;
    extraClassNames = ' text-success dark:text-success-dark underline';
  }
  const contents = <div className={inline === false ? `grid w-full${['currency', 'number'].includes(type) ? ' justify-end' : ''}` + `${['boolean', 'boolean-text'].includes(type) ? ' justify-center' : ''}` : '!inline'}>
        {type === 'percentage' && !hidePercentageBar && <Progress type="line" percent={data as number} showInfo={false} strokeColor={barColour} className="mr-1 shrink" />}
        {type === 'currency' && he.decode(currency)}
        {['string', 'company', 'json', 'email', 'date', 'dateTime'].includes(type) ? disableEllipsis ? data as ReactNode : <TextWithCutoff disableEllipsis={disableEllipsis} maxCharsCutOff={maxCharsCutOff}>
                        {/* @ts-expect-error */}
                        {data}
                    </TextWithCutoff> : data as ReactNode}
        {type === 'percentage' && '%'}
    </div>;
  return <>
        <div className={`table-cell-data ${cName}${inline ? ' !inline' : ''}${extraClassNames}`} onClick={onClick}>
            {isLink ? <a className={`${breakClassName} text-inherit ${className} ${inline ? '!inline' : ' !block'}`} href={type === 'email' ? 'mailto:' + data : null}>
                      {contents}
                  </a> : <div className={`${breakClassName} text-inherit ${className} ${inline ? '!inline' : ''}`}>
                      {contents}
                  </div>}
        </div>
    </>;
});
DataFormat.displayName = 'DataFormat';