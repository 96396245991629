import { type FC, memo } from 'react';
import { ButtonIcon } from '@/components/structural';
import filterIcon from '@/icons/funnel.svg';
import filterIconActive from '@/icons/funnel-fill.svg';
interface IFilterButtonProps {
  onClick: () => void;
  showFilterBar: boolean;
  filtersActive: boolean;
  className?: string;
}
export const FilterButtons: FC<IFilterButtonProps> = memo(({
  onClick,
  showFilterBar,
  filtersActive,
  className = ''
}) => <>
          {filtersActive ? <ButtonIcon {...filterIconActive} iconClasses="!w-6 !h-6" className={`
                          border-2
                          !text-info
                            dark:!text-info-dark
                          hover:!text-info-hover
                            dark:hover:!text-info-hover-dark
                          hover:!border-info
                            dark:!hover:border-info-hover-dark` + `${showFilterBar ? ` 
                          !border-info
                            dark:!border-info-dark` : ''}` + ` ${className}`} onClick={onClick} /> : <ButtonIcon {...filterIcon} iconClasses="!w-6 !h-6" onClick={onClick} className={`${showFilterBar ? `
                          border-2
                          !text-info
                            dark:!text-info-dark
                          !border-info
                            dark:!border-info-dark
                          hover:!text-info-hover
                            dark:hover:!text-info-hover-dark
                          hover:!border-info
                            dark:!hover:border-info-hover-dark` : ''}` + ` ${className}`} />}
      </>);
FilterButtons.displayName = 'FilterButtons';