import { useRouter } from 'next/router';
import { type FC, memo, useCallback, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import type { IInternalState } from '@/store/internal';
import { doPatchCurrentUser } from '@/store/user/actions';
import { selectAppViewState } from '@/store/internal/selectors';
import { setAppViewState } from '@/store/internal/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useCompaniesMinimal } from '@/store/company/hooks';
import { useCurrentUser } from '@/store/user/hooks';
import { processError } from '@/shared/processError';
import { routerNavigate, Typography } from '@/components/structural';
import { VIEW_ALL_COMPANIES_ID } from '@/shared/constants';
export interface IAppViewToggleProps {
  className?: string;
}
export const AppViewToggle: FC<IAppViewToggleProps> = memo(props => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {
    settings
  } = useCurrentUser();
  const {
    companies
  } = useCompaniesMinimal();
  const appViewState = useAppSelector(selectAppViewState);
  const activeStateIconClasses = 'border-b-2 text-primary dark:text-primary-dark';
  const inactiveStateIconClasses = 'hover:cursor-pointer hover:text-success dark:hover:text-success-dark bg-background-disabled text-default-disabled';
  const switchState = useCallback((value: IInternalState['app']['view'], companiesLength: number, currentCompanyUuid: string) => () => {
    dispatch(setAppViewState(value));
    switch (value) {
      case 'entity-management':
        if (router.pathname === '/my-company') return;
        if (companiesLength > 1 && currentCompanyUuid !== VIEW_ALL_COMPANIES_ID) {
          dispatch(doPatchCurrentUser({
            settings: {
              currentCompanyUuid: VIEW_ALL_COMPANIES_ID
            }
          }));
        }
        routerNavigate('/my-company');
        break;
      case 'portfolio-management':
        if (router.pathname === '/portfolio') return;
        routerNavigate('/portfolio');
        break;
      default:
        processError('AppViewToggle', `Switch value "${value}" not supported.`);
    }
  }, []);
  const checkCurrentPageRouterPathAndUpdateAppViewState = () => {
    if (router.pathname.startsWith('/portfolio') && appViewState !== 'portfolio-management') {
      dispatch(setAppViewState('portfolio-management'));
    }
    if (router.pathname.startsWith('/my-company') && appViewState !== 'entity-management') {
      dispatch(setAppViewState('entity-management'));
    }
  };
  useEffect(() => {
    checkCurrentPageRouterPathAndUpdateAppViewState();
  }, []);
  return <>
        <div className={'fixed z-20 w-screen overflow-hidden grid grid-flow-col bg-white dark:bg-background-dark ' + props.className}>
            <ToggleWrapper className={appViewState === 'entity-management' ? activeStateIconClasses : inactiveStateIconClasses} onClick={appViewState === 'entity-management' ? null : switchState('entity-management', companies.length, settings?.currentCompanyUuid)}>
                <Typography.Text className="!text-inherit">
                    Entity Management
                </Typography.Text>
            </ToggleWrapper>
            <ToggleWrapper className={appViewState === 'portfolio-management' ? activeStateIconClasses : inactiveStateIconClasses} onClick={appViewState === 'portfolio-management' ? null : switchState('portfolio-management', companies.length, settings?.currentCompanyUuid)}>
                <Typography.Text className="!text-inherit">
                    Portfolio Management
                </Typography.Text>
            </ToggleWrapper>
        </div>
    </>;
});
AppViewToggle.displayName = 'AppViewToggle';
const ToggleWrapper = tw.div`
    h-10
    border-0
    border-b-primary
    border-solid
    grid
    place-items-center
`;