import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import FormItemAntD from 'antd/lib/form/FormItem';
export const FormItem = FormItemAntD;
export const BaseFormItem = tw(FormItem)`
    items-center
    items-spread-around
    !mb-4
`;
export const FormItemFlex = tw(BaseFormItem)`
    !flex-row
`;
export const FormItemGrid = tw(BaseFormItem)`
    grid-cols-2
`;
export const HiddenFormItem = styled(FormItemGrid)`
    & .ant-form-item-control-input {
        width: 100%;
        text-align: end;

        .ant-input-number {
            width: 100%;
        }
    }
`;
export const HiddenFormItemFullWidth = styled(HiddenFormItem)`
    grid-template-columns: repeat(1, minmax(0, 1fr));

    & .ant-row.ant-form-item-row {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;

        .ant-col.ant-form-item-label {
            justify-self: baseline;
        }
    }
`;
export const VisibleFormItem = styled(FormItemFlex)`
    & .ant-form-item-label {
        padding-bottom: 0 !important;
    }

    & .ant-col.ant-form-item-control {
        width: initial;
        display: contents;
    }

    & .ant-row.ant-form-item-row {
        align-items: center;
        justify-content: space-between;
        flex-direction: row !important;
    }
`;
export const VisibleFormItemFullWidth = styled(VisibleFormItem)`
    & .ant-row.ant-form-item-row {
        display: inherit;

        .ant-col.ant-form-item-control {
            align-items: initial;
        }
    }
`;
export const WrappedLabelFormItem = styled(FormItem)`
    & {
        @media (max-width: 575px) {
            .ant-row {
                flex-direction: column;
            }
        }

        .ant-form-item-label {
            text-align: left;
            max-width: 60%;

            label {
                line-height: 1.2rem;
                white-space: normal;
                height: fit-content;
            }
        }
    }
`;