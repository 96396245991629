import { type FC, memo, useEffect } from 'react';
import { clearNotifications } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useNotifications } from '@/store/notifications/hooks';
import { App, Notification, routerNavigate } from '@/components/structural';
Notification.config({
  placement: 'topRight',
  duration: 4.5,
  top: 44
});
export const Notifications: FC = memo(() => {
  const {
    notification
  } = App.useApp();
  const dispatchAction = useAppDispatch();
  const {
    notifications
  } = useNotifications();
  useEffect(() => {
    if (!Array.isArray(notifications) || !notifications.length) return;
    notifications.forEach(msg => {
      notification.open({
        message: msg.title,
        description: msg.content,
        duration: 4.5,
        type: msg.type,
        onClose: msg.onClose,
        onClick: () => msg.onClickNavigateTo && routerNavigate(msg.onClickNavigateTo)
      });
    });
    dispatchAction(clearNotifications());
  }, [notifications, dispatchAction]);
  return null;
});
Notifications.displayName = 'Notifications';