import { createAction, type EntityState } from '@reduxjs/toolkit';

import type { IORCompany, IORCompanyMinimal } from '@/features/company/shared/models';
import type { IORResponseMeta } from '@/store/_rtk-query/meta-response.interface';

import { companyApiKey } from '../service.key';
import { companySliceKey } from '../slice.key';


export const companiesGetSuccess = createAction<EntityState<IORCompany, string> & IORResponseMeta>( `${ companyApiKey }/companiesGetSuccess` );
export const companiesMinimalGetSuccess = createAction<IORCompanyMinimal[]>( `${ companyApiKey }/companiesMinimalGetSuccess` );
export const currentCompanyChanged = createAction<string>( `${ companySliceKey }/currentCompanyChanged` );