import { memo } from 'react';
import { Breadcrumb, Typography } from '@/components/structural';
export const PortfolioInvestmentsHelpText = memo(() => <>
    <Breadcrumb separator={<span>&gt;</span>} className="mb-1 font-poppins">
        <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>
    </Breadcrumb>
    <div className="font-roboto">
        <Typography.Title level={5} className="font-poppins mt-0">Shareholdings</Typography.Title>
        <Typography.Paragraph className="mb-4">
            The table provided on this page gives an overview of investments you have in Companies who are also using OneRegistry.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            From here you can add new investments, visualise the data and drill down in to more information about the company or your investment.
        </Typography.Paragraph>
    </div>
</>);
PortfolioInvestmentsHelpText.displayName = 'PortfolioInvestmentsHelpText';