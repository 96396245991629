// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"41ed37ea9ab9db37edd070d06a7351f6e58e5561"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import commonConfig from './sentry.common.config';

Sentry.init(Object.assign({}, commonConfig, {
    integrations: [Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
    })/*, Sentry.feedbackIntegration({
        colorScheme: "system",
        isNameRequired: true,
        isEmailRequired: true,
    })*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
}));
