import tw from 'tailwind-styled-components';
export const AuthPageTopLinks = tw.div`
    flex
    justify-end
    text-primary
`;
export const AuthPageTopActiveLink = tw.span`
    ml-4
    border-0
    border-b-2
    border-solid
    border-success
`;