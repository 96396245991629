import styled from 'styled-components';
import Form from 'antd/lib/form/Form';
export const FormWithTabs = styled(Form)<{
  $numberOfTabs?: number;
}>`
    .ant-tabs-content-holder {
        background-color: white;
    }

    .ant-modal-confirm-confirm & {
        .ant-tabs-content-holder {
            border: none;
        }
    }

    .ant-tabs-tab {
        border-bottom-width: 2px !important;
    }

    ${({
  $numberOfTabs = 2
}) => $numberOfTabs === 2 ? `
    .ant-tabs-nav-wrap {
        display: grid !important;
    }
    
    .ant-tabs-tab:nth-of-type(1) {
        flex-grow: 1 !important;
    }

    .ant-tabs-tab {
        flex-shrink: 1 !important;
    }

    .ant-tabs-nav-operations-hidden {
        display: none !important;
    }
    
    .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane {
        border-top-right-radius: 0 !important;
    }
    ` : ''}
`;