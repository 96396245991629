import { type FC, memo } from 'react';
import { SVGIcon } from '@/components/structural/images';
import AntDAvatar from 'antd/lib/avatar';
import defaultAvatarIcon from '@/icons/person-fill.svg';
import editIcon from '@/icons/camera.svg';
export interface IAvatarProps {
  icon?: string;
  editMode?: boolean;
  className?: string;
}
export const Avatar: FC<IAvatarProps> = memo(props => {
  const {
    editMode = false
  } = props;
  return <>
        <div className={`inline-block ${props.className || ''}`}>
            <AntDAvatar size={55} gap={2} icon={<SVGIcon className="!h-full !w-full" src={props.icon || defaultAvatarIcon.src} />} />
            {editMode && <AntDAvatar size={32} gap={0} className="bg-success bg:text-success-dark relative -translate-x-7 -translate-y-5" icon={<SVGIcon className="h-7 w-7 text-white dark:text-default-dark m-auto relative top-0.5" src={editIcon.src} />} />}
        </div>
    </>;
});
Avatar.displayName = 'Avatar';