import { memo } from 'react';
import { Breadcrumb, DataFormat, Typography } from '@/components/structural';
export const CompaniesFilingLogHelpText = memo(() => <>
    <Breadcrumb separator={<span>&gt;</span>} className="mb-1 font-poppins">
        <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>
    </Breadcrumb>
    <div className="font-roboto">
        <Typography.Title level={5} className="font-poppins mt-0">Companies House Filing Log</Typography.Title>
        <Typography.Paragraph className="mb-4">The Companies House Filing Log is a read-only record of any statutory filings, changes or new information you have submitted.</Typography.Paragraph>
        <Typography.Paragraph>On this page you’ll be able to view the state of any submissions, whether or not the submission has been accepted and updated by Companies House or if you have any
            outstanding actions, for example if a submission is still in draft.</Typography.Paragraph>
        <Typography.Paragraph>Helpful colour coding gives an indication of state:</Typography.Paragraph>
        <Typography.Paragraph><strong className="text-info dark:text-info-dark">Pending</strong>: You’ve submitted the information to Companies House but it has not yet been updated or
            accepted by the Registrar of Companies.</Typography.Paragraph>
        <Typography.Paragraph><strong className="text-primary dark:text-primary-dark">Draft</strong>: A draft transaction has not yet been submitted to Companies House.
            You can open the transaction and submit when you are ready.</Typography.Paragraph>
        <Typography.Paragraph><strong className="text-danger dark:text-danger-dark">Rejected</strong>: Your submission has been rejected by Companies House. You’ll find more detail attached to the
            transaction in question</Typography.Paragraph>
        <Typography.Paragraph>Once your submission has been accepted and updated by Companies House, it will be highlighted in <strong className="text-success dark:text-success-dark">green</strong> for a
            period of 14 days after which time it will simply be listed as ACCEPTED.</Typography.Paragraph>
        <Typography.Paragraph>For further help please stakeholder <DataFormat raw="support@oneregistry.co.uk" type="email" /></Typography.Paragraph>
    </div>
</>);
CompaniesFilingLogHelpText.displayName = 'CompaniesFilingLogHelpText';