import { type FC } from 'react';
import Router from 'next/router';
import type { ErrorBoundaryProps } from '@sentry/react';
import * as Sentry from '@sentry/react';
import TypographyTitle from 'antd/lib/typography/Title';
import { Button, routerNavigate } from '@/components/structural';
import { clearAllCookiesAndCaches } from '@/shared/tools';
type FallbackBoundaryProps = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};
export const SentryErrorBoundary: FC<ErrorBoundaryProps> = props => {
  return <>
        <Sentry.ErrorBoundary fallback={(props: FallbackBoundaryProps) => SentryErrorBoundaryFallback(props)} showDialog={false} data-sentry-element="unknown" data-sentry-source-file="SentryErrorBoundary.tsx">
            {props.children}
        </Sentry.ErrorBoundary>
    </>;
};
const SentryErrorBoundaryFallback = ({
  error,
  resetError
}: FallbackBoundaryProps) => {
  const routeBack = () => Router?.back();
  const resetApp = () => {
    clearAllCookiesAndCaches();
    setTimeout(() => routerNavigate('/auth/login', {
      useWindowLocation: true
    }), 250);
  };
  const resetComponent = () => resetError();
  const reloadPage = () => window?.location.reload();
  return <>
        <div className="min-w-screen min-h-screen bg-primary dark:bg-primary-dark bg-opacity-10 flex items-center p-5 lg:p-20 overflow-hidden relative">
            <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white dark:bg-default-dark shadow-xl p-10 lg:p-20 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/2">
                    <div className="mb-10 md:mb-20">
                        <TypographyTitle level={2} className="!font-normal" data-sentry-element="TypographyTitle" data-sentry-source-file="SentryErrorBoundary.tsx">Sorry, an error has occurred...</TypographyTitle>
                        {error.message && <TypographyTitle level={3} className="!font-normal">Message: {error.message}</TypographyTitle>}
                    </div>
                    <div className="grid grid-rows-4 place-items-start mb-20 md:mb-0 gap-4">
                        <Button onClick={routeBack} type="success" data-sentry-element="Button" data-sentry-source-file="SentryErrorBoundary.tsx">
                            Go back
                        </Button>
                        <Button onClick={resetComponent} type="success" data-sentry-element="Button" data-sentry-source-file="SentryErrorBoundary.tsx">
                            Try again
                        </Button>
                        <Button onClick={reloadPage} type="success" data-sentry-element="Button" data-sentry-source-file="SentryErrorBoundary.tsx">
                            Reload Page
                        </Button>
                        <Button onClick={resetApp} type="success" data-sentry-element="Button" data-sentry-source-file="SentryErrorBoundary.tsx">
                            Reset App
                        </Button>
                    </div>
                </div>
                <div className="w-full md:w-1/2 text-center" />
            </div>
            <div className="w-64 md:w-96 h-96 md:h-full bg-primary dark:bg-primary-dark bg-opacity-25 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45" />
            <div className="w-96 h-full bg-primary dark:bg-primary-dark bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45" />
        </div>
    </>;
};