import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doSignupResendConfirmationEmail, doSignupResendConfirmationEmailError, doSignupResendConfirmationEmailSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';


function* signupResendConfirmationScript( { payload }: ReturnType<typeof doSignupResendConfirmationEmail> )
{
    try
    {
        yield call( authService.resendConfirmationEmail, payload );
        yield routerNavigate( '/auth/signup-resend-confirmation-success' );
        yield put( doSignupResendConfirmationEmailSuccess( payload ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignupResendConfirmationEmail saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupResendConfirmationEmailError( error ) );
    }
}

export function* onSignupResendConfirmationEmailSaga()
{
    yield takeEvery( doSignupResendConfirmationEmail, signupResendConfirmationScript );
}