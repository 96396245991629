import { type FC, memo, type ReactNode, useCallback, useMemo, useState } from 'react';
import moment, { type Moment } from 'moment';
import useResponsive from 'ahooks/lib/useResponsive';
import { setWidgetFilingDeadlinesSelectedCompany } from '@/store/company/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCompaniesMinimal } from '@/store/company/hooks';
import { Button, Calendar, CalendarCell, calendarHeaderRenderMonthAndYear, calendarLocale, Card, Col, Loader, Row, Tooltip } from '@/components/structural';
import { parseCompanies } from '@/features/company/my-company/widgets/filing/filing-deadlines-data';
import { getArray } from '@/shared/tools';
const currentDayOfMonth = moment().date();
const currentMonthOfYear = moment().month();
const currentYear = moment().year();
export interface IFilingDeadlineCalendarWidgetProps {
  doubleHeight?: boolean;
}
export const FilingDeadlinesCalendarWidget: FC<IFilingDeadlineCalendarWidgetProps> = memo(({
  doubleHeight
}) => {
  const dispatchAction = useAppDispatch();
  const breakpoints = useResponsive();
  const {
    companies,
    isFetching
  } = useCompaniesMinimal();
  const data = useMemo(() => parseCompanies(getArray<typeof companies[number]>(companies)), [companies]);
  const [momentCurrentMonth, setMomentCurrentMonth] = useState(moment());
  const [momentNextMonth, setMomentNextMonth] = useState(moment().add(1, 'month'));
  const [momentNextMonth2, setMomentNextMonth2] = useState(moment().add(2, 'month'));
  const [momentNextMonth3, setMomentNextMonth3] = useState(moment().add(3, 'month'));
  const nextMonth = () => {
    setMomentCurrentMonth(momentCurrentMonth.clone().add(1, 'month'));
    setMomentNextMonth(momentNextMonth.clone().add(1, 'month'));
    setMomentNextMonth2(momentNextMonth2.clone().add(1, 'month'));
    setMomentNextMonth3(momentNextMonth3.clone().add(1, 'month'));
  };
  const previousMonth = () => {
    setMomentCurrentMonth(momentCurrentMonth.clone().subtract(1, 'month'));
    setMomentNextMonth(momentNextMonth.clone().subtract(1, 'month'));
    setMomentNextMonth2(momentNextMonth2.clone().subtract(1, 'month'));
    setMomentNextMonth3(momentNextMonth3.clone().subtract(1, 'month'));
  };
  const dateFullCellRender = (date: Moment, monthInView: Moment): ReactNode => {
    if (!date.isSame(monthInView, 'month')) {
      return null;
    }
    if (currentDayOfMonth === date.date() && currentMonthOfYear === date.month() && currentYear === date.year()) {
      return <Tooltip title="Today" className="relative z-[1]">
                <div className="bg-primary dark:bg-primary-dark text-white dark:text-default rounded-full cell-today">
                    {date.date()}
                </div>
            </Tooltip>;
    }
    const filingDueOnCurrentDate = data.find(filing => moment(filing.date).isSame(date, 'day'));
    if (filingDueOnCurrentDate) {
      return <Tooltip title={filingDueOnCurrentDate.company.name} className="relative z-[1]">
                <CalendarCell className="text-white dark:text-default rounded-full cell-has-deadline" $colour={filingDueOnCurrentDate.company.colour} $colourDark={filingDueOnCurrentDate.company.colourDark}>
                    {date.date()}
                </CalendarCell>
            </Tooltip>;
    }
    return <>
            <div>
                {date.date()}
            </div>
        </>;
  };
  const handleCellClick = useCallback((date: Moment, monthInView: Moment) => {
    if (!date.isSame(monthInView, 'month')) {
      return;
    }
    const filingDueOnCurrentDate = data.find(filing => moment(filing.date).isSame(date, 'day'));
    if (filingDueOnCurrentDate) {
      dispatchAction(setWidgetFilingDeadlinesSelectedCompany(filingDueOnCurrentDate.key));
    } else {
      dispatchAction(setWidgetFilingDeadlinesSelectedCompany(null));
    }
  }, [data]);
  const handleDateChange = (newDate: Moment, monthInView: Moment) => {
    if (newDate.isSame(monthInView, 'month')) {
      return;
    }
    if (newDate.isBefore(monthInView, 'month')) {
      previousMonth();
    } else {
      nextMonth();
    }
  };
  if (isFetching) return <Loader skeleton />;
  return <>
        <Card styles={{
      body: {
        height: '100%'
      }
    }}>
            <Row gutter={[16, 16]} className="p-4 px-0 justify-between">
                <Col span={breakpoints.xl ? 2 : 3}>
                    <Button className="mt-2 border-none text-3xl" onClick={previousMonth}>
                        &lt;
                    </Button>
                </Col>
                <Col span={breakpoints['2xl'] || breakpoints.md ? 9 : 18}>
                    <Calendar fullscreen={false} locale={calendarLocale} fullCellRender={date => dateFullCellRender(date, momentCurrentMonth)} value={momentCurrentMonth} headerRender={calendarHeaderRenderMonthAndYear} onSelect={date => handleCellClick(date, momentCurrentMonth)} onChange={date => handleDateChange(date, momentCurrentMonth)} />
                </Col>
                {(breakpoints['2xl'] || breakpoints.md) && <Col span={9}>
                    <Calendar fullscreen={false} locale={calendarLocale} fullCellRender={date => dateFullCellRender(date, momentNextMonth)} value={momentNextMonth} headerRender={calendarHeaderRenderMonthAndYear} onSelect={date => handleCellClick(date, momentNextMonth)} onChange={date => handleDateChange(date, momentNextMonth)} />
                </Col>}
                <Col span={breakpoints.xl ? 2 : 3} className="flex justify-end">
                    <Button className="mt-2 border-none text-3xl justify-self-end" onClick={nextMonth}>
                        &gt;
                    </Button>
                </Col>
            </Row>
            {doubleHeight && breakpoints.lg && <Row gutter={[16, 16]} className="p-4 px-0 justify-between">
                <Col span={breakpoints.xl ? 2 : 3}>
                    &nbsp;
                </Col>
                <Col span={breakpoints['2xl'] || breakpoints.md ? 9 : 18}>
                    <Calendar fullscreen={false} locale={calendarLocale} fullCellRender={date => dateFullCellRender(date, momentNextMonth2)} value={momentNextMonth2} headerRender={calendarHeaderRenderMonthAndYear} onSelect={date => handleCellClick(date, momentNextMonth2)} onChange={date => handleDateChange(date, momentNextMonth2)} />
                </Col>
                {(breakpoints['2xl'] || breakpoints.md) && <Col span={9}>
                    <Calendar fullscreen={false} locale={calendarLocale} fullCellRender={date => dateFullCellRender(date, momentNextMonth3)} value={momentNextMonth3} headerRender={calendarHeaderRenderMonthAndYear} onSelect={date => handleCellClick(date, momentNextMonth3)} onChange={date => handleDateChange(date, momentNextMonth3)} />
                </Col>}
                <Col span={breakpoints.xl ? 2 : 3}>
                    &nbsp;
                </Col>
            </Row>}
        </Card>
    </>;
});
FilingDeadlinesCalendarWidget.displayName = 'FilingDeadlinesCalendarWidget';