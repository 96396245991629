import tw from 'tailwind-styled-components';
export type FieldContainerMultipleFieldsProps = {
  $cols: number;
  $rows: number;
  $hidden?: boolean;
};
export const FieldContainerMultipleFields = tw.div<FieldContainerMultipleFieldsProps>`
    ${(props: FieldContainerMultipleFieldsProps) => props.$hidden ? '!hidden' : ''}
    grid
    md:grid-rows-none
    grid-cols-none
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 1 ? 'md:grid-cols-1' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 2 ? 'md:grid-cols-2' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 3 ? 'md:grid-cols-3' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 4 ? 'md:grid-cols-4' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 5 ? 'md:grid-cols-5' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$cols === 6 ? 'md:grid-cols-6' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 1 ? 'grid-rows-1' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 2 ? 'grid-rows-2' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 3 ? 'grid-rows-3' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 4 ? 'grid-rows-4' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 5 ? 'grid-rows-5' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 6 ? 'grid-rows-6' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 7 ? 'grid-rows-7' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 8 ? 'grid-rows-8' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 9 ? 'grid-rows-9' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 10 ? 'grid-rows-10' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 11 ? 'grid-rows-11' : ''}
    ${(props: FieldContainerMultipleFieldsProps) => props.$rows === 12 ? 'grid-rows-12' : ''}
    gap-0
    md:gap-4
    items-start
    content-center
`;