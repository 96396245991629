import { type FC, memo } from 'react';
import { useCurrentCompany } from '@/store/company/hooks';
import { Loader, WidgetSimpleValueOnly } from '@/components/structural';
export const CompanyNumberWidget: FC = memo(() => {
  const {
    chProfile,
    isFetching
  } = useCurrentCompany();
  if (isFetching) return <Loader skeleton />;
  return <>
        <WidgetSimpleValueOnly value={chProfile?.company_number} type="string" description="Company Number" variant="light" />
    </>;
});
CompanyNumberWidget.displayName = 'CompanyNumberWidget';