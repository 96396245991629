import * as Sentry from '@sentry/nextjs';
import { showReportDialog } from '@sentry/react';

import { FAKE_ERROR_MESSAGE } from '@/shared/errors';
import { getCookie } from '@/shared/tools';


export const processError = ( ...args: unknown[] ) =>
{
    if ( process.env.NODE_ENV !== 'production' )
    {
        if ( typeof window !== 'undefined' ) console.error( args );
        else console.log( args );
    }

    const errorStr = JSON.stringify( args );

    // auth exceptions or those that have no contents are not an issue
    if ( errorStr.includes( 'NotAuthorizedException' ) ||
          errorStr.includes( '["auth Service",{}]' ) ||
          errorStr.includes( FAKE_ERROR_MESSAGE ) ||
          errorStr.includes( '528' ) ||
          errorStr.includes( '529' ) ||
          // this one is likely related to the backend not being updated to latest version yet
          errorStr.includes( '404' ) ||
          errorStr.includes( 'ERR_NETWORK' ) ||
          errorStr.includes( 'Network Error' ) ||
          errorStr.includes( 'Error fetching user data.' ) ||
          errorStr.includes( 'Invite Code Invalid' ) ||
          errorStr.includes( 'SKIP_SENTRY_REPORTING' )
    )
    {
        if ( process.env.NODE_ENV !== 'production' )
        {
            console.log( 'Error would NOT have been reported to Sentry.' );
        }
        return;
    }

    if ( process.env.NODE_ENV !== 'production' )
    {
        console.log( 'Error would HAVE been reported to Sentry.' );
        return;
    }

    // production only
    const user = {
        email: getCookie( { cName: 'orCurrentUserEmail' } ),
        username: getCookie( { cName: 'orCurrentUserName' } )
    };

    // Sentry.captureException( errorStr );
    const eventId = Sentry.captureMessage( errorStr, {
        user
    } );

    if ( errorStr.includes( 'SKIP_SENTRY_DIALOGUE' ) ) return;

    showReportDialog( {
        eventId,
        user,
        title: 'Error Report',
        subtitle: 'Please provide additional information about the error you encountered.',
        subtitle2: 'This information will help us fix the issue.',
        subtitle3: 'Thank you for your help!',
    } );
};