import { type FC, memo, useCallback } from 'react';
import tw from 'tailwind-styled-components';
import { selectPageViewSelectorState } from '@/store/internal/selectors';
import { setViewSelectorState } from '@/store/internal/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { SVGIcon } from '@/components/structural/images';
import tableViewIcon from '@/icons/list.svg';
import chartsViewIcon from '@/icons/bar-chart.svg';
export interface IViewSelectorProps {
  className?: string;
}
export const ViewSelector: FC<IViewSelectorProps> = memo(props => {
  const dispatchAction = useAppDispatch();
  const viewSelectorState = useAppSelector(selectPageViewSelectorState);
  const activeStateIconClasses = 'border-b-2 text-primary dark:text-primary-dark';
  const inactiveStateIconClasses = 'hover:cursor-pointer hover:text-success dark:hover:text-success-dark';
  const switchState = useCallback(() => {
    if (viewSelectorState === 'table') {
      dispatchAction(setViewSelectorState('charts'));
    } else {
      dispatchAction(setViewSelectorState('table'));
    }
  }, [viewSelectorState]);
  return <>
        <div className={'pt-4 grid grid-flow-col ' + props.className}>
            <IconWrapper className={viewSelectorState === 'table' ? activeStateIconClasses : inactiveStateIconClasses}>
                <SVGIcon src={tableViewIcon.src} className="!w-8 !h-8 -mt-1" onClick={viewSelectorState === 'table' ? null : switchState} />
            </IconWrapper>
            <IconWrapper className={viewSelectorState === 'charts' ? activeStateIconClasses : inactiveStateIconClasses}>
                <SVGIcon src={chartsViewIcon.src} className="!w-6 !h-6" onClick={viewSelectorState === 'charts' ? null : switchState} />
            </IconWrapper>
        </div>
    </>;
});
ViewSelector.displayName = 'ViewSelector';
const IconWrapper = tw.div`
    h-7
    border-0
    border-b-success
    border-solid
`;