import { type FC, memo } from 'react';
import { DataFormat, FieldContainerCols, FieldEditText, FieldsWrapperFlex, FieldsWrapperGrid, FieldTitleCenter, FieldValue, FieldValueDefault } from '@/components/structural';
import type { IFormStepProps } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';
import type { IStep1Data } from './Step1';
import type { IStep2Data } from './Step2';
export interface IStep3Data extends ORJSON {
  step1: IStep1Data;
  step2: IStep2Data;
}
export const Step3: FC<IFormStepProps<IStep3Data>> = memo(({
  initialData: data,
  goToStep
}) => {
  return <>
        <div className="mb-4 font-roboto">
            Please check the details below carefully before submitting change request to Companies House.
        </div>
        <FieldsWrapperFlex>
            <FieldContainerCols>
                <FieldValueDefault>
                    Registered Office Address: <FieldEditText onClick={() => goToStep(1)} className="ml-8 inline" />
                </FieldValueDefault>
            </FieldContainerCols>
        </FieldsWrapperFlex>
        <div className="grid grid-cols-1 place-items-center w-full mb-4">
            <FieldsWrapperGrid>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Building Name/Number
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={data.step2?.address_line_1} className="text-inherit font-medium" inline />
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Street
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={data.step2?.address_line_2} className="text-inherit font-medium" inline />
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Post Town
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={data.step2?.city} className="text-inherit font-medium" inline />
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        County/Region
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={data.step2?.country} className="text-inherit font-medium" inline />
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Postcode
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={data.step2?.post_code} className="text-inherit font-medium" inline />
                    </FieldValue>
                </FieldContainerCols>
            </FieldsWrapperGrid>
        </div>
        <div className="mb-4">
            <FieldContainerCols>
                <FieldTitleCenter className="!text-left">
                    Registered address is in the same country that the company was registered in:
                </FieldTitleCenter>
                <FieldValue>
                    <DataFormat raw={data.step1?.same_country} type="boolean-text" className="text-inherit font-medium" inline />
                    <FieldEditText onClick={() => goToStep(0)} />
                </FieldValue>
            </FieldContainerCols>
        </div>
    </>;
});
Step3.displayName = 'CompanyChangeRegisteredAddressStep3';